// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen-main {
  width: 100vw;
  height: 100vh;
  background: #23bee3;
  overflow: hidden;
  position: relative;
}
.screen-main .number-block {
  display: flex;
  height: 280px;
  width: 280px;
  align-items: center;
  justify-content: center;
  margin: 100px auto 0;
  border: 5px solid #fff;
  border-radius: 65px;
}
.screen-main .number-block h3 {
  margin: 0;
  font-size: 132px;
  color: #fff;
  font-weight: 400;
}
.screen-main .bottom-img {
  position: absolute;
  /* bottom: -45%; */
  bottom: -55%;
  left: 32%;
}

@media (max-width: 1750px) {
  .screen-main .bottom-img {
    left: 31%;
  }
}

@media (max-width: 1600px) {
  .screen-main .bottom-img {
    left: 30%;
  }
}

@media (max-width: 1440px) {
  .screen-main .bottom-img {
    /* bottom: -50%; */
    left: 27%;
  }
}

@media (max-width: 1300px) {
  .screen-main .bottom-img {
    /* bottom: -53%; */
    left: 25%;
  }
}

@media (max-width: 1024px) {
  .screen-main .bottom-img {
    /* bottom: -48%; */
    left: 22%;
    /* width: 65%;  */
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/splashScreen.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,SAAS;EACT,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,SAAS;IACT,iBAAiB;EACnB;AACF","sourcesContent":[".screen-main {\n  width: 100vw;\n  height: 100vh;\n  background: #23bee3;\n  overflow: hidden;\n  position: relative;\n}\n.screen-main .number-block {\n  display: flex;\n  height: 280px;\n  width: 280px;\n  align-items: center;\n  justify-content: center;\n  margin: 100px auto 0;\n  border: 5px solid #fff;\n  border-radius: 65px;\n}\n.screen-main .number-block h3 {\n  margin: 0;\n  font-size: 132px;\n  color: #fff;\n  font-weight: 400;\n}\n.screen-main .bottom-img {\n  position: absolute;\n  /* bottom: -45%; */\n  bottom: -55%;\n  left: 32%;\n}\n\n@media (max-width: 1750px) {\n  .screen-main .bottom-img {\n    left: 31%;\n  }\n}\n\n@media (max-width: 1600px) {\n  .screen-main .bottom-img {\n    left: 30%;\n  }\n}\n\n@media (max-width: 1440px) {\n  .screen-main .bottom-img {\n    /* bottom: -50%; */\n    left: 27%;\n  }\n}\n\n@media (max-width: 1300px) {\n  .screen-main .bottom-img {\n    /* bottom: -53%; */\n    left: 25%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .screen-main .bottom-img {\n    /* bottom: -48%; */\n    left: 22%;\n    /* width: 65%;  */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
