import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col, Button } from "react-bootstrap";
import Doctor from "../../assets/images/doctorInfo.png";
import Star from "../../assets/images/star.svg";
import Location from "../../assets/images/location.svg";
import Call from "../../assets/images/call.svg";
import Chat from "../../assets/images/chat.svg";
import User from "../../assets/images/user.svg";
import Verify from "../../assets/images/verify.svg";
import Rating from "../../assets/images/rating.svg";
import "../../assets/css/doctorInfo.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import moment from "moment";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DoctorDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [doctorDetails, setDoctorDetails] = useState();
  const [SlotsDetail, setSlotsDetail] = useState();
  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [nightSlots, setNightSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [BlockedList, setBlockedList] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({
    appoinmentFor: "",
    slot: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location?.state?.doctor) {
      setDoctorDetails(location?.state?.doctor);
      getSlotDetails();
    }
  }, [location?.state?.doctor]);

  useEffect(() => {
    const now = moment();
    const endOfDay = moment().endOf("day").hour(19).minute(0);
    const remaining = endOfDay.diff(now);

    const duration = moment.duration(remaining);
    const hours = duration.hours();
    const minutes = duration.minutes();
    setRemainingTime(`${hours} hours and ${minutes} minutes`);
  }, []);

  useEffect(() => {
    if (SlotsDetail?.Time && selectedDate) {
      const allSlots = [];
      const now = moment();

      SlotsDetail?.Time?.forEach((slot) => {
        const { startTime, endTime, duration } = slot;
        const startMoment = moment(startTime, "HH:mm:ss");
        const endMoment = moment(endTime, "HH:mm:ss");

        while (startMoment.add(duration, "minutes").isSameOrBefore(endMoment)) {
          allSlots.push({
            start: startMoment.clone().subtract(duration, "minutes"),
            end: startMoment.clone(),
          });
        }
      });

      const lastSlot = allSlots[allSlots.length - 1];
      const endOfLastSlot = lastSlot.end.clone();
      const remaining = endOfLastSlot.diff(now);
      const duration = moment.duration(remaining);
      const hours = duration.hours();
      const minutes = duration.minutes();
      const remainingTime = `${hours}.${minutes}`;

      setRemainingTime(remainingTime);
      setEndTime(endOfLastSlot);

      const filteredSlots = allSlots.filter((slot) => {
        const isSameOrAfterSelectedDate = moment(slot.start).isSameOrAfter(
          selectedDate,
          "day"
        );
        return isSameOrAfterSelectedDate;
      });

      const morning = [];
      const afternoon = [];
      const evening = [];
      const night = [];
      filteredSlots.forEach((slot) => {
        const startHour = slot.start.hour();
        if (startHour >= 6 && startHour < 12) {
          morning.push(slot);
        } else if (startHour >= 12 && startHour < 16) {
          afternoon.push(slot);
        } else if (startHour >= 16 && startHour < 21) {
          evening.push(slot);
        } else {
          night.push(slot);
        }
      });

      setMorningSlots(morning);
      setAfternoonSlots(afternoon);
      setEveningSlots(evening);
      setNightSlots(night);
    } else {
      setMorningSlots([]);
      setAfternoonSlots([]);
      setEveningSlots([]);
      setNightSlots([]);
      setRemainingTime("");
      setEndTime("");
    }
  }, [SlotsDetail, selectedDate]);

  async function getSlotDetails() {
    try {
      const data = await get(
        `/Slots?doctor_id=${location?.state?.doctor?._id}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setLoading(false);
        setSlotsDetail(data?.data?.msg[0]);
        setBlockedList(data?.data?.bookedList);
      }
    } catch (error) {
      setLoading(false);
      if (error?.status === 401) {
        Auth.logout();
        navigate("/login");
      }

      if (error?.response?.status === 400) {
        setSlotsDetail();
      }
    }
  }

  const handleSlotSelect = (slot) => {
    const selectedTime = slot.start.format("HH:mm:ss");
    setSelectedSlot({
      appoinmentFor: selectedSlot.appoinmentFor,
      slot: selectedTime,
    });
  };

  const isSlotDisabled = (slot) => {
    const startMoment = moment(slot.start);
    const isBooked = BlockedList.some((bookedSlot) => {
      return (
        moment(bookedSlot.date).isSame(selectedDate, "day") &&
        bookedSlot.timeSlot === startMoment.format("HH:mm:ss")
      );
    });
    const isBeforeCurrentTime = startMoment.isBefore(moment());
    return isBooked || isBeforeCurrentTime;
  };

  const availableMorningSlots = morningSlots.filter(
    (slot) => !isSlotDisabled(slot)
  );
  const availableAfternoonSlots = afternoonSlots.filter(
    (slot) => !isSlotDisabled(slot)
  );
  const availableEveningSlots = eveningSlots.filter(
    (slot) => !isSlotDisabled(slot)
  );
  const availableNightSlots = nightSlots.filter(
    (slot) => !isSlotDisabled(slot)
  );
  const totalAvailableSlots =
    availableMorningSlots.length +
    availableAfternoonSlots.length +
    availableEveningSlots.length +
    availableNightSlots.length;

  return (
    <Layout>
      <ToastContainer />
      <TopBar
        backlink={"/appointment"}
        title={doctorDetails?.name}
        tab={"Book Appointment"}
        tabChild={"Doctor Profile"}
      />
      {loading ? (
        <div className="preloader-whirlpool">
          <div className="whirlpool"></div>
        </div>
      ) : (
        <div className="main-content-part topBox">
          <div className="top-orange-box"></div>
          <div className="innerInfo">
            <Row>
              <Col lg={5} sm={12} md={12}>
                <div className="doctorDetailsCard">
                  <div className="doctorContent">
                    <div className="imnage-part">
                      <img
                        src={
                          doctorDetails?.profile_image
                            ? doctorDetails?.profile_image
                            : doctorDetails?.gender === "female"
                            ? DoctorFeMale
                            : doctorDetails?.gender === "male"
                            ? DoctorMale
                            : DoctorCommon
                        }
                        style={{ borderRadius: "50%" }}
                        height={"230px"}
                        width={"230px"}
                        alt="doctor"
                      />
                      <div className="rating">
                        <p>
                          <img src={Star} alt="star" />{" "}
                          {doctorDetails?.averageRating?.toFixed(1)} (
                          {doctorDetails?.totalRatingCount})
                        </p>
                      </div>
                    </div>
                    <div className="doctorInfo">
                      <h4 className="name d-flex justify-content-center">
                        {doctorDetails?.name}{" "}
                        {doctorDetails?.associatedEntity?.verified === true && (
                          <div className="px-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                            >
                              <circle cx="10" cy="10" r="10" fill="green" />
                              <path
                                d="M7 10 L9 12 L14 7"
                                stroke="white"
                                strokeWidth="2"
                                fill="transparent"
                              />
                            </svg>
                          </div>
                        )}
                      </h4>
                      <h5>{doctorDetails?.speciality}</h5>
                      {doctorDetails?.address?.state &&
                        doctorDetails?.address?.country && (
                          <h6>
                            <img src={Location} alt="location" />{" "}
                            {doctorDetails?.address?.street},{" "}
                            {doctorDetails?.address?.state},{" "}
                            {doctorDetails?.address?.country}
                          </h6>
                        )}
                    </div>
                    <div className="logInfo">
                      {doctorDetails?.mobile ? (
                        <CopyToClipboard
                          text={doctorDetails?.mobile}
                          onCopy={() => {
                            toast.success("Mobile number copied to clipboard!");
                          }}
                        >
                          <div
                            className="badge call"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={Call} alt="call" />
                          </div>
                        </CopyToClipboard>
                      ) : (
                        ""
                      )}
                      <div
                        className="badge email"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // navigate("/chat",{ state: { patient: doctorDetails } })
                        }}
                      >
                        <img src={Chat} alt="email" />
                      </div>
                    </div>
                    <div className="expData">
                      <div className="part">
                        <img src={User} alt="user" />
                        <h6>{doctorDetails?.patientCount}</h6>
                        <p>Patient</p>
                      </div>
                      <div className="part">
                        <img src={Verify} alt="verified" />
                        <h6>{doctorDetails?.years_of_experience} years</h6>
                        <p>Experience</p>
                      </div>
                      {remainingTime && endTime && (
                        <div className="part">
                          <img src={Rating} alt="timer" />
                          <h6>
                            {isNaN(remainingTime)
                              ? "0"
                              : Math.max(remainingTime, 0).toString()}
                          </h6>
                          <p>Till {endTime.format("hh:mm A")}</p>
                        </div>
                      )}
                    </div>
                    {doctorDetails?.brief_detail && (
                      <div className="aboutInfo mb-0 mt-0">
                        <h6>About Physiotherapist</h6>
                        <p className="mb-0">{doctorDetails?.brief_detail}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <div className="doctorDetailsCard startdata">
                  <div className="rightPartData">
                    {doctorDetails?.hospital_fees && (
                      <div className="locationTimeField filed">
                        <div className="block">
                          <h6>In Clinic </h6>
                          <h5>${doctorDetails?.hospital_fees} Fees</h5>
                        </div>
                        <div className="block">
                          <p>Walk In </p>
                          <p className="badge">Yes</p>
                        </div>
                      </div>
                    )}

                    {doctorDetails?.associatedEntity === "Individual" && (
                      <div className="locationTimeField filed">
                        <div className="block">
                          <p>{"Individual"}</p>
                          <p>
                            {doctorDetails?.address?.state},{" "}
                            {doctorDetails?.address?.country}
                          </p>
                        </div>
                      </div>
                    )}
                    {doctorDetails?.associatedEntity?.name && (
                      <div className="locationTimeField filed">
                        <div className="block">
                          <p>{doctorDetails?.associatedEntity?.name}</p>
                          <p>
                            {doctorDetails?.associatedEntity?.address?.state},{" "}
                            {doctorDetails?.associatedEntity?.address?.country}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="infoField filed">
                      <p>
                        Today, {moment(selectedDate).format("DD MMM")}
                        {totalAvailableSlots > 0 && (
                          <span> {totalAvailableSlots} Slots</span>
                        )}
                      </p>
                      <div className="radio-badge-part">
                        <form>
                          {morningSlots.length === 0 &&
                            afternoonSlots.length === 0 &&
                            eveningSlots.length === 0 &&
                            nightSlots.length === 0 && (
                              <div className="text-center">
                                Physiotherapist is not available
                              </div>
                            )}
                          {morningSlots.length > 0 && (
                            <div className="filter-box">
                              <label htmlFor="appointment-input">Morning</label>
                              <div className="radio-badge-part mt-0">
                                <form>
                                  {morningSlots?.map((slot, index) => (
                                    <label key={index}>
                                      <input
                                        type="radio"
                                        name="radio"
                                        disabled={isSlotDisabled(slot)}
                                        onClick={() => handleSlotSelect(slot)}
                                      />
                                      <span
                                        className={
                                          isSlotDisabled(slot)
                                            ? "disabled-slot"
                                            : ""
                                        }
                                      >
                                        {slot.start.format("h:mm A")}
                                      </span>
                                    </label>
                                  ))}
                                </form>
                              </div>
                            </div>
                          )}
                          {afternoonSlots.length > 0 && (
                            <div className="filter-box">
                              <label htmlFor="appointment-input">
                                Afternoon
                              </label>
                              <div className="radio-badge-part mt-0">
                                <form>
                                  {afternoonSlots?.map((slot, index) => (
                                    <label key={index}>
                                      <input
                                        type="radio"
                                        name="radio"
                                        disabled={isSlotDisabled(slot)}
                                        onClick={() => handleSlotSelect(slot)}
                                      />
                                      <span
                                        className={
                                          isSlotDisabled(slot)
                                            ? "disabled-slot"
                                            : ""
                                        }
                                      >
                                        {slot.start.format("h:mm A")}
                                      </span>
                                    </label>
                                  ))}
                                </form>
                              </div>
                            </div>
                          )}
                          {eveningSlots.length > 0 && (
                            <div className="filter-box">
                              <label htmlFor="appointment-input">Evening</label>
                              <div className="radio-badge-part mt-0">
                                <form>
                                  {eveningSlots?.map((slot, index) => (
                                    <label key={index}>
                                      <input
                                        type="radio"
                                        name="radio"
                                        disabled={isSlotDisabled(slot)}
                                        onClick={() => handleSlotSelect(slot)}
                                      />
                                      <span
                                        className={
                                          isSlotDisabled(slot)
                                            ? "disabled-slot"
                                            : ""
                                        }
                                      >
                                        {slot.start.format("h:mm A")}
                                      </span>
                                    </label>
                                  ))}
                                </form>
                              </div>
                            </div>
                          )}
                          {nightSlots.length > 0 && (
                            <div className="filter-box">
                              <label htmlFor="appointment-input">Night</label>
                              <div className="radio-badge-part mt-0">
                                <form>
                                  {nightSlots?.map((slot, index) => (
                                    <label key={index}>
                                      <input
                                        type="radio"
                                        name="radio"
                                        disabled={isSlotDisabled(slot)}
                                        onClick={() => handleSlotSelect(slot)}
                                      />
                                      <span
                                        className={
                                          isSlotDisabled(slot)
                                            ? "disabled-slot"
                                            : ""
                                        }
                                      >
                                        {slot.start.format("h:mm A")}
                                      </span>
                                    </label>
                                  ))}
                                </form>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-part">
                    {morningSlots?.length +
                      afternoonSlots?.length +
                      eveningSlots?.length +
                      nightSlots?.length >
                    0 ? (
                      <Link
                        to="/bookAppointment"
                        state={{ doctor_id: doctorDetails?._id }}
                      >
                        View all Slots
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="buttonPart mt-3 d-flex justify-content-center">
                  {morningSlots?.length +
                    afternoonSlots?.length +
                    eveningSlots?.length +
                    nightSlots?.length >
                  0 ? (
                    <Button
                      className="theme-button"
                      style={{ fontWeight: 300 }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/bookAppointment", {
                          state: {
                            doctor_id: doctorDetails?._id,
                            selectedSlot: selectedSlot?.slot,
                          },
                        });
                      }}
                    >
                      Book Appointment
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DoctorDetails;
