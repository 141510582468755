import React, { useCallback, useEffect, useRef } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import DoctorCard from "../../components/DoctorCard";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import LocationIcon from "../../assets/images/location-lcon.svg";
import { useState } from "react";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import "../../assets/css/commonComponent.css";
import Star from "../../assets/images/star.svg";
import Comment from "../../assets/images/comment-alt.svg";
import Doctor from "../../assets/images/doctor.png";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import TopBarNavigation from "../../components/TopBarNavigation";
import { Modal } from 'react-bootstrap';
import Patient from "../../assets/images/Dr-Henna-S.svg";

const ClinicPhysio = () => {
    const navigate = useNavigate()
    const [doctorList, setDoctorList] = useState({
        items: [],
        has_more: false
    })
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [showModal, setModalShow] = useState(false);
    const closeModal = () => setModalShow(false);
    useEffect(() => {
        if (location?.state?.clinic?._id) {
            getHomeData()
        }

    }, [location?.state?.clinic])
    useEffect(() => {
        if (page >= 1) {
            getHomeData()
        }
    }, [page])

    const observer = useRef();
    const lastBookElementRef = useCallback(
        (node) => {
            // if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && doctorList?.has_more) {
                    setPage((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [doctorList?.has_more]
    );
    async function getHomeData() {
        try {
            setLoading(true)
            const data = await get(`/physiotherapistsByClinic?pageNumber=${page}&clinicId=${location?.state?.clinic?._id}`, getAuthConfig());
            if (data?.status === 200) {
                setLoading(false)
                if (page > 0) {
                    setDoctorList((p) => ({ ...p, items: doctorList?.items.concat(data?.data?.physiotherapists) }));
                } else {
                    setDoctorList((p) => ({ ...p, items: data?.data?.physiotherapists }));
                }
                // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
                setDoctorList((e) => ({ ...e, has_more: data?.data?.has_more }))
            }
        } catch (error) {
            setLoading(false)
        }
    }
    return (
        <Layout>
            <TopBarNavigation title={location?.state?.clinic?.name} tab={"Clinic"} tabChild={"Physiotherapist"} />

            <div className="main-content-part appointment-page">

                <div className="content-block">
                    <div className="exercise-tab-part">

                        <Row className="g-4">
                            {loading && page === 1 && <div className="text-center">loading..</div>}
                            {!loading && doctorList?.items?.length <= 0 && <div className="text-center">No Physiotherapist Found</div>}
                            {doctorList?.items && doctorList?.items?.length > 0 && doctorList?.items.map((item, index) => {
                                return (

                                    <>

                                        <Col lg="6" className="" ref={lastBookElementRef}>
                                            <div className="doctor-card">
                                                <Link to="/doctorDetails" state={{ doctor: item }} className="linkCard">

                                                    <div className="doctor-card-content">
                                                        <div className="image-part">
                                                            {item?.profile_image ? (
                                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                    <img
                                                                        src={item?.profile_image}
                                                                        style={{ borderRadius: '55%' }}
                                                                        height={100}
                                                                        width={100}
                                                                        alt="User Profile"
                                                                    />

                                                                </div>
                                                            ) : (
                                                                <div style={{ position: 'relative', display: 'inline-block' }}>

                                                                    <img src={DoctorDummy} style={{ borderRadius: '55%' }} height={100} width={100} alt="User Profile" />

                                                                </div>
                                                            )}                                </div>
                                                        <div className="text-part">
                                                            <h5 className="name d-flex">{item?.name}      {item?.associatedEntity?.verified === true && ( // Check if the user is verified
                                                                <div
                                                                    className='px-1'
                                                                // style={{
                                                                //     position: 'absolute',
                                                                //     top: '-2px',
                                                                //     right: '-5px',
                                                                // }}
                                                                >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                        <circle cx="10" cy="10" r="10" fill="green" />
                                                                        <path d="M7 10 L9 12 L14 7" stroke="white" stroke-width="2" fill="transparent" />
                                                                    </svg>

                                                                    {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                                                </div>
                                                            )}</h5>                                  <h6>{item?.designation}</h6>
                                                            <p>{item?.years_of_experience} yrs of exp. overall</p>
                                                            {item?.address && <p>{item?.address?.state} {item?.address?.state && item?.address?.country && ","}  {item?.address?.country}</p>}
                                                            <div className="rating">
                                                                <p>
                                                                    <img src={Comment} alt="comment alt" /> {item?.patientCount} Patient Stories
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="doctor-rating-part">
                                                        <div className="rating-block">
                                                            <p>
                                                                <img src={Star} alt="star" /> 4.5 (60)
                                                            </p>
                                                        </div>
                                                        <div className="book-now">
                                                            <button>Book Now</button>
                                                        </div>
                                                    </div>
                                                </Link>


                                                {item?.associatedEntity === "Individual" &&
                                                    <div className="hospital-details">
                                                        <span className="divider"></span>
                                                        <p>{"Individual"}</p>
                                                        {item?.hospital_fees && <p>${item?.hospital_fees} Consultation Fees</p>}
                                                    </div>

                                                }
                                                {item?.associatedEntity?.name &&



                                                    <div className="hospital-details" style={{ cursor: "pointer" }} onClick={(e) => {
                                                        e.preventDefault();
                                                        setModalShow(true)
                                                    }}>
                                                        <span className="divider"></span>
                                                        <p>{item?.associatedEntity?.name}</p>
                                                        {item?.hospital_fees && <p>${item?.hospital_fees} Consultation Fees</p>}
                                                        <p>{item?.associatedEntity?.address?.state} , {item?.associatedEntity?.address?.country}</p>

                                                    </div>
                                                }
                                                {/* <div className="hospital-details">
                                <span className="divider"></span>
                                <p>{item?.hospital_name}</p>
                                {item?.hospital_fees && <p>${item?.hospital_fees} Consultation Fees</p>}
                              </div> */}
                                                {item?.fees &&
                                                    <div className="bottom-bar light-bg">
                                                        <p>${item?.fees} Consultation fees</p>
                                                    </div>}
                                            </div>
                                        </Col>
                                    </>
                                )
                            })}
                            {loading && page > 1 && <div className="text-center">loading..</div>}


                        </Row>

                    </div>
                </div>
            </div>



            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton >
                    Clinic Information
                </Modal.Header>
                <Modal.Body style={{ border: 'none' }}>

                    <div className="doctors-list" >
                        {/* Your dropdown content goes here */}
                        <div className="title-block">

                            <div className="assign-doctor-card my-2 w-100" style={{boxShadow:"none"}}>
                                <div className="doctor-card-content">

                                    <div className="text-part">
                                        <h5 className="name d-flex justify-content-center">{location?.state?.clinic?.name}      {location?.state?.clinic?.verified === true && ( // Check if the user is verified
                                            <div
                                                className='px-1'
                                            ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                    <circle cx="10" cy="10" r="10" fill="green" />
                                                    <path d="M7 10 L9 12 L14 7" stroke="white" stroke-width="2" fill="transparent" />
                                                </svg>
                                            </div>
                                        )}</h5>
                                        <p className="name">{location?.state?.clinic?.email}</p>
                                        <p className="name">{location?.state?.clinic?.phone}</p>
                                        <p className="name">{location?.state?.clinic?.address?.street} , {location?.state?.clinic?.address?.city} , {location?.state?.clinic?.address?.state} , {location?.state?.clinic?.address?.country} , {location?.state?.clinic?.address?.country}</p>
                                    </div>
                                </div>
                            </div>
                              </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
};

export default ClinicPhysio;
