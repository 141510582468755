import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../assets/css/commonComponent.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col } from "react-bootstrap";
import ProfileDoctorCard from "../../components/ProfileDoctorCard";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import DocImg from "../../assets/images/doctor.png";
import { useLocation, Link, useNavigate } from "react-router-dom";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png";
import PatientDummy from "../../assets/images/defaultpatient@3x.png";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const ProfileDoctor = () => {
  const [loading, setLoading] = useState(true);
  const [doctorList, setDoctorList] = useState({
    items: [],
    has_more: false,
  });
  const [page, setPage] = useState(0);
  UseEffectOnce(() => {
    getHomeData();
  });

  useEffect(() => {
    if (page >= 1) {
      getHomeData();
    }
  }, [page]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && doctorList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [doctorList?.has_more]
  );

  async function getHomeData() {
    setLoading(true);
    try {
      const data = await get(`/myDoctor?pageNumber=${page}`, getAuthConfig());
      if (data.status === 200) {
        if (page > 0) {
          setDoctorList((p) => ({
            ...p,
            items: doctorList?.items.concat(data?.data?.doctors),
          }));
        } else {
          setDoctorList((p) => ({ ...p, items: data?.data?.doctors }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setDoctorList((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {}
    setLoading(false);
  }

  return (
    <Layout>
      <TopBar title={"My Doctors"} tab={"Profile"} tabChild={"My Doctors"} />

      <div className="main-profile-doctor-content-part">
        {loading && page === 0 ? (
          <div className="preloader-whirlpool">
            <div className="whirlpool"></div>
          </div>
        ) : (
          <>
            <Row className="g-4">
              {doctorList?.items && doctorList?.items?.length <= 0 && (
                <div> No Doctors found</div>
              )}
              {doctorList?.items &&
                doctorList?.items?.length > 0 &&
                doctorList?.items.map((item, index) => {
                  return (
                    <>
                      <Col lg="6" ref={lastBookElementRef}>
                        {/* <ProfileDoctorCard /> */}
                        <div className="profileDoctorCard-data">
                          <Link
                            to="/doctorDetails"
                            state={{ doctor: item }}
                            className="linkCard"
                          >
                            <div className="box">
                              <div className="leftPart">
                                <img
                                  src={
                                    item?.profile_image
                                      ? item?.profile_image
                                      :  item?.gender === "female"
                                      ? DoctorFeMale
                                      : item?.gender === "male"
                                      ? DoctorMale
                                      : DoctorCommon
                                  }
                                  alt="Doctor Image"
                                  height={"230px"}
                                  width={"250px"}
                                  style={{ borderRadius: "55%" }}
                                  className="profile"
                                />
                              </div>
                              <div className="rightPart">
                                <h3 className="name">{item?.name}</h3>
                                <h3 className="profetion">
                                  {item?.designation}
                                </h3>
                                <button className="Btn">Book Now</button>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </>
                  );
                })}
            </Row>
            {loading && page > 0 && (
              <div className="preloader-whirlpool">
                <div className="whirlpool"></div>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ProfileDoctor;
