import React, { useState, useCallback, useEffect, useRef } from "react";
import "../../assets/css/commonComponent.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import DocImg from "../../assets/images/doctor.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProfileAppointmentCard from "../../components/ProfileAppointmentCard";
import ProfileAppointmentCardLight from "../../components/ProfileAppointmentCardLight";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import Auth from "../../libs/auth";
import moment from "moment";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const ProfileApointment = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("upcoming");
  const [appointmentData, setAppointmentData] = useState({
    items: [],
    has_more: false,
  });
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && appointmentData?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [appointmentData?.has_more]
  );
  const [page, setPage] = useState(0);

  UseEffectOnce(() => {
    getAppointmentData();
  }, []);

  async function getAppointmentData() {
    try {
      const data = await get(
        `/appointmentList?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        if (page > 0) {
          setAppointmentData((p) => ({
            ...p,
            items: appointmentData?.items.concat(data?.data?.data),
          }));
        } else {
          setAppointmentData((p) => ({ ...p, items: data?.data?.data }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setAppointmentData((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  const isPastAppointment = (date, timeSlot) => {
    const appointmentDateTime = moment(`${date} ${timeSlot}`, "YYYY-MM-DD HH:mm:ss");
    return moment().isAfter(appointmentDateTime);
  };
  return (
    <Layout>
      <TopBar
        title={"Appointments"}
        tab={"Profile"}
        tabChild={"Appointments"}
      />
      <div className="main-profile-doctor-content-part">
        <div className="exercise-tab-part">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="upcoming" title="Upcoming">
              <Row className="g-4">
                {appointmentData?.items &&
                  appointmentData?.items.length <= 0 && (
                    <div> No Appointment Found</div>
                  )}
                {appointmentData?.items &&
                  appointmentData?.items.length > 0 &&
                  appointmentData?.items.map((item, index) => {
                    const pastAppointment = isPastAppointment(item?.date, item?.timeSlot);

                    return (
                      <>
                        <Col lg="6" ref={lastBookElementRef}>
                          <div className="profileDoctorCard-data"                           style={{ opacity: pastAppointment ? 0.5 : 1 }}
>
                            <Link
                              to="/doctorDetails"
                              state={{ doctor: item?.doctor_id }}
                              className="linkCard"
                            >
                              <div className="box">
                                <div className="leftPart">
                                  <img
                                    src={
                                      item?.doctor_id?.profile_image
                                        ? item?.doctor_id?.profile_image
                                        : item?.doctor_id?.gender === "female"
                                        ? DoctorFeMale
                                        : item?.doctor_id?.gender === "male"
                                        ? DoctorMale
                                        : DoctorCommon
                                    }
                                    alt="Doctor Image"
                                    style={{ borderRadius: "55%" }}
                                    className="profile"
                                  />
                                </div>
                                <div className="rightPart">
                                  <h3 className="name">
                                    {item?.doctor_id?.name}
                                  </h3>
                                  <h3 className="profetion">
                                    {item?.doctor_id?.speciality}
                                  </h3>
                                  <h3 className="time">
                                    {moment(item?.timeSlot, "HH:mm:ss").format(
                                      "h:mm A"
                                    )}
                                    ,
                                    <span className="date">
                                      {" "}
                                      {moment(item?.date).format("MMM DD")}
                                    </span>
                                  </h3>
                                  <button className="Btn">Join call</button>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </Tab>
            {/* <Tab eventKey="completed" title="Completed">
                            <Row className="g-4">
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                                <Col lg="6">
                                    <ProfileAppointmentCard />
                                </Col>
                            </Row>
                        </Tab> */}
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

export default ProfileApointment;
