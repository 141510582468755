import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for routing
import '../../assets/css/splashScreen.css';
import splash from '../../assets/images/splash-banner.svg';

const SplashScreen = () => {
  const [countdown, setCountdown] = useState(3);
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    // Start the countdown
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect to /home after 3 seconds (countdown reaches 0)
    if (countdown === 0) {

      navigate("/workout",{state:{workout:location?.state?.workout,session:location?.state?.session,note:location?.state?.note}});

    }
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  const formattedCountdown = countdown.toString().padStart(2, '0');

  return (
    <div className='screen-main'>
      <div className="number-block">
        <h3>{formattedCountdown}</h3>
      </div>
      <img src={splash} alt="banner-img" className="bottom-img" />
    </div>
  );
};

export default SplashScreen;
