import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Layout from "../../layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import moment from "moment";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import checkMark from "../../assets/images/CheckWorkout.svg";
import { Modal } from "react-bootstrap";
import doneImg from "../../assets/images/Opps.svg"; // Import the image
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
function Workout() {
  const sessionClassMap = {
    Heart: "heart",
    Arm: "arm",
    Shoulder: "shoulder",
    // Add more session names and their class names as needed
  };

  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("morning");
  const [date, setDate] = useState();
  const [workoutData, setWorkdata] = useState({});
  const [value, setValue] = useState();
  const [transactionData, setTransactionData] = useState({});
  const [show, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [currentDates, setCurrentDates] = useState(() => {
    const now = new Date();
    const currentUTCDate = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    // Create new date adjusted for IST offset
    const istDate = new Date(currentUTCDate);
    // Format date as YYYY-MM-DD using the adjusted IST date
    const year = istDate.getFullYear(); // Use getFullYear() to get the year of the IST adjusted date
    const month = `${istDate.getMonth() + 1}`.padStart(2, "0"); // Months are 0-indexed, add 1 for correct month
    const day = `${istDate.getDate()}`.padStart(2, "0"); // Use getDate() to get the day of the IST adjusted date

    return `${year}-${month}-${day}`;
  });
  const handleStartWorkout = (workout, session , todayDate) => {
    console.log("priti",todayDate)
    navigate("/excersiceList", {
      state: { workout: workout, session: session, note: workoutData?.note , todayDate },
    });
  };
  // UseEffectOnce(() => {
  //   getWorkData();
  // });
  useEffect(() => {
    // if (date != undefined || date != null) {
    getWorkData();
    // }
  }, [currentDates]);
  async function getWorkData() {
    try {
      setLoading(true);
      let url = `/workout`;
      if (currentDates) {
        url = `/workout?date=${currentDates}`;
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        setLoading(false);
        setWorkdata(data?.data?.workoutdata);
        setTransactionData(data?.data);
      }
    } catch (error) {
      setLoading(false);

      if (error.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }
  function onHide() {
    setShowModal(false);
  }

  const isNotCurrentMonth = (transactionDate) => {
    const transactionMonth = new Date(transactionDate).getMonth();
    const currentMonth = new Date().getMonth();
    return transactionMonth !== currentMonth;
  };

  const getFormattedTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // JS months are 0-indexed.
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const todayDate = getFormattedTodayDate();
  

  return (
    <>
      <Layout>
        {loading ? (
          <div className="preloader-whirlpool">
            <div className="whirlpool"></div>
          </div>
        ) : (
          <div className="main-content-part h-90">
            <Row>
              <Col lg={5}>
                <div
                  className="doctorDetailsCard"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="doctorContent"
                    style={{ flexDirection: "row" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      sx={{ color: "#23BEE3", fontFamily: "Poppins" }}
                    >
                      <DateCalendar
                       sx={{
                        fontFamily: 'Poppins',
                        '& .MuiPickersDay-root': {
                          '&.Mui-selected': {
                            backgroundColor: '#23bee3', // Color for selected date
                          },
                          '&.MuiPickersDay-today': {
                            borderColor: '#23bee3', // Color for today's date outline
                          },
                        },
                      }}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue); // This will update the calendar's display

                          // Convert the `newValue` to a JavaScript Date object and then to the desired format
                          const selectedDate = newValue.toDate(); // Assuming `newValue` is a Dayjs object; convert it to Date object
                          const formattedDate = `${selectedDate.getFullYear()}-${String(
                            selectedDate.getMonth() + 1
                          ).padStart(2, "0")}-${String(
                            selectedDate.getDate()
                          ).padStart(2, "0")}`;

                          setCurrentDates(formattedDate); // Update the `currentDate` state with the newly selected date in `YYYY-MM-DD` format
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <div className="doctorDetailsCard startdata mb-3">
                  <div className="rightPartData">
                    <div className="filed pb-3">
                      <div className="exercise-tab-part">
                        <h6 className="title">Today's excercises plan</h6>

                        <div>
                          {!workoutData?.morning &&
                            !workoutData?.afternoon &&
                            !workoutData?.evening &&
                            !workoutData?.night && (
                              <div> No Exercises Assigned </div>
                            )}

                          {workoutData?.morning?.length <= 0 &&
                            workoutData?.afternoon?.length <= 0 &&
                            workoutData?.evening?.length <= 0 &&
                            workoutData?.night?.length <= 0 && (
                              <div> No Exercises Assigned </div>
                            )}
                        </div>
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3"
                        >
                          {workoutData?.morning?.length > 0 && (
                            <Tab eventKey="morning" title="Morning">
                              <div className="session-card-content">
                                {workoutData?.morning?.map((session, index) => {
                                  const morningIndex =
                                    index % Object.keys(sessionClassMap).length;
                                  const altClass =
                                    Object.keys(sessionClassMap)[morningIndex];
                                  session.image =
                                    session?.image ||
                                    "https://api.recureme.com/images/ADL.svg";
                                  const currentDate = new Date(currentDates);
                                  currentDate.setUTCHours(0, 0, 0, 0);
                                  const isAnyExerciseIncomplete =
                                    session?.workout?.some((exercise) => {
                                      // Check if the exercise has incomplete dates
                                      return (
                                        exercise?.date_Complted?.length === 0 ||
                                        !exercise.date_Complted.includes(
                                          currentDate.toISOString()
                                        )
                                      );
                                    });

                                  return (
                                    <div
                                      className={`session-card py-2 ${
                                        isAnyExerciseIncomplete ? "" : "op"
                                      } `}
                                      key={index}
                                      style={{
                                        backgroundColor: "#ebf7ff",
                                        position: "relative",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          workoutData?.transactionType ===
                                            "DirectPayment" &&
                                          (transactionData?.paymentCompleted ===
                                            "Pending" ||
                                            transactionData?.paymentCompleted ===
                                              "Failed") 
                                        ) {
                                          setShowModal(true);
                                        }
                                        
                                        else if(workoutData?.transactionType ===
                                          "DirectPayment" &&
                                        (transactionData?.paymentCompleted ===
                                          "Completed" ) &&
                                        isNotCurrentMonth(
                                          transactionData?.transactionDate
                                        ))
                                        {
                                          setShowModal(true);
                                        }
                                        else if (isAnyExerciseIncomplete) {
                                          handleStartWorkout(
                                            session,
                                            "Morning",
                                            currentDate
                                          );
                                        }
                                      }}
                                    >
                                      {session?.image && (
                                        <div
                                          className={`image-block ${
                                            isAnyExerciseIncomplete
                                              ? ""
                                              : "isAnyExerciseIncomplete"
                                          }`}
                                          style={{
                                            position: "relative", // Ensure this is here if you want the icon related to this
                                          }}
                                        >
                                          <img
                                            src={session?.image.replace(
                                              ".svg",
                                              "Blue.svg"
                                            )}
                                            alt="session-img"
                                            height={90}
                                            width={90}
                                          />
                                          {!isAnyExerciseIncomplete && (
                                            <div
                                              className="overlays"
                                              style={{
                                                position: "absolute",
                                                zIndex: 2, // Ensure it's above other content
                                              }}
                                            >
                                              <img
                                                src={checkMark}
                                                style={{
                                                  opacity: 1, // This is now positioned in a way that it's visually separate
                                                }}
                                                height={90}
                                                width={90}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className="details mt-2"
                                        style={{
                                          flexGrow: 1,
                                          gap: "0px",
                                          // Additional styles for details
                                        }}
                                      >
                                        <h5
                                          className="session-title"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {session?.name &&
                                            session?.name?.replace(
                                              "Workout Session",
                                              ""
                                            )}
                                        </h5>

                                        <div className="d-flex">
                                          <i
                                            class="fa-solid fa-hourglass"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                          <p
                                            className="workout-text"
                                            style={{ color: "#adadad" }}
                                          >
                                            {" "}
                                            &nbsp; {
                                              session?.workout?.length
                                            }{" "}
                                            Exercise{" "}
                                            {!isAnyExerciseIncomplete &&
                                              "Completed"}{" "}
                                          </p>
                                        </div>
                                      </div>

                                      {isAnyExerciseIncomplete && (
                                        <div className="text-end">
                                          <i
                                            class="fa-solid fa-chevron-right fa-2xl"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </Tab>
                          )}
                          {workoutData?.afternoon?.length > 0 && (
                            <Tab eventKey="afternoon" title="Afternoon">
                              <div className="session-card-content">
                                {workoutData?.afternoon?.map(
                                  (session, index) => {
                                    const afternoonIndex =
                                      index %
                                      Object.keys(sessionClassMap).length;
                                    const altClass =
                                      Object.keys(sessionClassMap)[
                                        afternoonIndex
                                      ];
                                    session.image =
                                      session?.image ||
                                      "https://api.recureme.com/images/ADL.svg";

                                    const currentDate = new Date(currentDates);
                                    currentDate.setUTCHours(0, 0, 0, 0);
                                    const isAnyExerciseIncomplete =
                                      session?.workout?.some((exercise) => {
                                        // Check if the exercise has incomplete dates
                                        return (
                                          exercise?.date_Complted?.length ===
                                            0 ||
                                          !exercise.date_Complted.includes(
                                            currentDate.toISOString()
                                          )
                                        );
                                      });

                                    return (
                                      <div
                                        className={`session-card py-2 ${
                                          isAnyExerciseIncomplete ? "" : "op"
                                        } `}
                                        key={index}
                                        style={{
                                          backgroundColor: "#ebf7ff",
                                          position: "relative",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (
                                            workoutData?.transactionType ===
                                              "DirectPayment" &&
                                            (transactionData?.paymentCompleted ===
                                              "Pending" ||
                                              transactionData?.paymentCompleted ===
                                                "Failed") 
                                          ) {
                                            setShowModal(true);
                                          }
                                          
                                          else if(workoutData?.transactionType ===
                                            "DirectPayment" &&
                                          (transactionData?.paymentCompleted ===
                                            "Completed" ) &&
                                          isNotCurrentMonth(
                                            transactionData?.transactionDate
                                          ))
                                          {
                                            setShowModal(true);
                                          } else if (isAnyExerciseIncomplete) {
                                            handleStartWorkout(
                                              session,
                                              "Afternoon",
                                              currentDate
                                            );
                                          }
                                        }}
                                      >
                                        {session?.image && (
                                          <div
                                            className={`image-block ${
                                              isAnyExerciseIncomplete
                                                ? ""
                                                : "isAnyExerciseIncomplete"
                                            }`}
                                            style={{
                                              position: "relative", // Ensure this is here if you want the icon related to this
                                            }}
                                          >
                                            <img
                                              src={session?.image.replace(
                                                ".svg",
                                                "Blue.svg"
                                              )}
                                              alt="session-img"
                                              height={90}
                                              width={90}
                                            />
                                            {!isAnyExerciseIncomplete && (
                                              <div
                                                className="overlays"
                                                style={{
                                                  position: "absolute",
                                                  zIndex: 2, // Ensure it's above other content
                                                }}
                                              >
                                                <img
                                                  src={checkMark}
                                                  style={{
                                                    opacity: 1, // This is now positioned in a way that it's visually separate
                                                  }}
                                                  height={90}
                                                  width={90}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        <div
                                          className="details mt-2"
                                          style={{
                                            flexGrow: 1,
                                            gap: "0px",
                                            // Additional styles for details
                                          }}
                                        >
                                          <h5
                                            className="session-title"
                                            style={{ fontWeight: "500" }}
                                          >
                                            {session?.name &&
                                              session?.name?.replace(
                                                "Workout Session",
                                                ""
                                              )}
                                          </h5>

                                          <div className="d-flex">
                                            <i
                                              class="fa-solid fa-hourglass"
                                              style={{ color: "#23bee3" }}
                                            ></i>
                                            <p
                                              className="workout-text"
                                              style={{ color: "#adadad" }}
                                            >
                                              {" "}
                                              &nbsp; {
                                                session?.workout?.length
                                              }{" "}
                                              Exercise{" "}
                                              {!isAnyExerciseIncomplete &&
                                                "Completed"}{" "}
                                            </p>
                                          </div>
                                        </div>

                                        {isAnyExerciseIncomplete && (
                                          <div className="text-end">
                                            <i
                                              class="fa-solid fa-chevron-right fa-2xl"
                                              style={{ color: "#23bee3" }}
                                            ></i>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Tab>
                          )}

                          {workoutData?.evening?.length > 0 && (
                            <Tab eventKey="evening" title="Evening">
                              <div className="session-card-content">
                                {workoutData?.evening?.map((session, index) => {
                                  session.image =
                                    session?.image ||
                                    "https://api.recureme.com/images/ADL.svg";
                                  const eveningIndex =
                                    index % Object.keys(sessionClassMap).length;
                                  const altClass =
                                    Object.keys(sessionClassMap)[eveningIndex];
                                  const currentDate = new Date(currentDates);
                                  currentDate.setUTCHours(0, 0, 0, 0);
                                  const isAnyExerciseIncomplete =
                                    session?.workout?.some((exercise) => {
                                      // Check if the exercise has incomplete dates
                                      return (
                                        exercise?.date_Complted?.length === 0 ||
                                        !exercise.date_Complted.includes(
                                          currentDate.toISOString()
                                        )
                                      );
                                    });

                                  return (
                                    <div
                                      className={`session-card py-2 ${
                                        isAnyExerciseIncomplete ? "" : "op"
                                      } `}
                                      key={index}
                                      style={{
                                        backgroundColor: "#ebf7ff",
                                        position: "relative",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          workoutData?.transactionType ===
                                            "DirectPayment" &&
                                          (transactionData?.paymentCompleted ===
                                            "Pending" ||
                                            transactionData?.paymentCompleted ===
                                              "Failed") 
                                        ) {
                                          setShowModal(true);
                                        }
                                        
                                        else if(workoutData?.transactionType ===
                                          "DirectPayment" &&
                                        (transactionData?.paymentCompleted ===
                                          "Completed" ) &&
                                        isNotCurrentMonth(
                                          transactionData?.transactionDate
                                        ))
                                        {
                                          setShowModal(true);
                                        }
                                        else if (isAnyExerciseIncomplete) {
                                          handleStartWorkout(
                                            session,
                                            "Evening",
                                            currentDate
                                          );
                                        }
                                      }}
                                    >
                                      {session?.image && (
                                        <div
                                          className={`image-block ${
                                            isAnyExerciseIncomplete
                                              ? ""
                                              : "isAnyExerciseIncomplete"
                                          }`}
                                          style={{
                                            position: "relative", // Ensure this is here if you want the icon related to this
                                          }}
                                        >
                                          <img
                                            src={session?.image.replace(
                                              ".svg",
                                              "Blue.svg"
                                            )}
                                            alt="session-img"
                                            height={90}
                                            width={90}
                                          />
                                          {!isAnyExerciseIncomplete && (
                                            <div
                                              className="overlays"
                                              style={{
                                                position: "absolute",
                                                zIndex: 2, // Ensure it's above other content
                                              }}
                                            >
                                              <img
                                                src={checkMark}
                                                style={{
                                                  opacity: 1, // This is now positioned in a way that it's visually separate
                                                }}
                                                height={90}
                                                width={90}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className="details mt-2"
                                        style={{
                                          flexGrow: 1,
                                          gap: "0px",
                                          // Additional styles for details
                                        }}
                                      >
                                        <h5
                                          className="session-title"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {session?.name &&
                                            session?.name?.replace(
                                              "Workout Session",
                                              ""
                                            )}
                                        </h5>

                                        <div className="d-flex">
                                          <i
                                            class="fa-solid fa-hourglass"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                          <p
                                            className="workout-text"
                                            style={{ color: "#adadad" }}
                                          >
                                            {" "}
                                            &nbsp; {
                                              session?.workout?.length
                                            }{" "}
                                            Exercise{" "}
                                            {!isAnyExerciseIncomplete &&
                                              "Completed"}{" "}
                                          </p>
                                        </div>
                                      </div>

                                      {isAnyExerciseIncomplete && (
                                        <div className="text-end">
                                          <i
                                            class="fa-solid fa-chevron-right fa-2xl"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </Tab>
                          )}

                          {workoutData?.night?.length > 0 && (
                            <Tab eventKey="night" title="Night">
                              <div className="session-card-content">
                                {workoutData?.night?.map((session, index) => {
                                  session.image =
                                    session?.image ||
                                    "https://api.recureme.com/images/ADL.svg";
                                  const nightiNDE =
                                    index % Object.keys(sessionClassMap).length;
                                  const altClass =
                                    Object.keys(sessionClassMap)[nightiNDE];
                                  const currentDate = new Date(currentDates);
                                  currentDate.setUTCHours(0, 0, 0, 0);
                                  const isAnyExerciseIncomplete =
                                    session?.workout?.some((exercise) => {
                                      // Check if the exercise has incomplete dates
                                      return (
                                        exercise?.date_Complted?.length === 0 ||
                                        !exercise.date_Complted.includes(
                                          currentDate.toISOString()
                                        )
                                      );
                                    });

                                  return (
                                    <div
                                      className={`session-card py-2 ${
                                        isAnyExerciseIncomplete ? "" : "op"
                                      } `}
                                      key={index}
                                      style={{
                                        backgroundColor: "#ebf7ff",
                                        position: "relative",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          workoutData?.transactionType ===
                                            "DirectPayment" &&
                                          (transactionData?.paymentCompleted ===
                                            "Pending" ||
                                            transactionData?.paymentCompleted ===
                                              "Failed") 
                                        ) {
                                          setShowModal(true);
                                        }
                                        
                                        else if(workoutData?.transactionType ===
                                          "DirectPayment" &&
                                        (transactionData?.paymentCompleted ===
                                          "Completed" ) &&
                                        isNotCurrentMonth(
                                          transactionData?.transactionDate
                                        ))
                                        {
                                          setShowModal(true);
                                        } else if (isAnyExerciseIncomplete) {
                                          handleStartWorkout(session, "Night",currentDate);
                                        }
                                      }}
                                    >
                                      {session?.image && (
                                        <div
                                          className={`image-block ${
                                            isAnyExerciseIncomplete
                                              ? ""
                                              : "isAnyExerciseIncomplete"
                                          }`}
                                          style={{
                                            position: "relative", // Ensure this is here if you want the icon related to this
                                          }}
                                        >
                                          <img
                                            src={session?.image.replace(
                                              ".svg",
                                              "Blue.svg"
                                            )}
                                            alt="session-img"
                                            height={90}
                                            width={90}
                                          />
                                          {!isAnyExerciseIncomplete && (
                                            <div
                                              className="overlays"
                                              style={{
                                                position: "absolute",
                                                zIndex: 2, // Ensure it's above other content
                                              }}
                                            >
                                              <img
                                                src={checkMark}
                                                style={{
                                                  opacity: 1, // This is now positioned in a way that it's visually separate
                                                }}
                                                height={90}
                                                width={90}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className="details mt-2"
                                        style={{
                                          flexGrow: 1,
                                          gap: "0px",
                                          // Additional styles for details
                                        }}
                                      >
                                        <h5
                                          className="session-title"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {session?.name &&
                                            session?.name?.replace(
                                              "Workout Session",
                                              ""
                                            )}
                                        </h5>

                                        <div className="d-flex">
                                          <i
                                            class="fa-solid fa-hourglass"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                          <p
                                            className="workout-text"
                                            style={{ color: "#adadad" }}
                                          >
                                            {" "}
                                            &nbsp; {
                                              session?.workout?.length
                                            }{" "}
                                            Exercise{" "}
                                            {!isAnyExerciseIncomplete &&
                                              "Completed"}{" "}
                                          </p>
                                        </div>
                                      </div>

                                      {isAnyExerciseIncomplete && (
                                        <div className="text-end">
                                          <i
                                            class="fa-solid fa-chevron-right fa-2xl"
                                            style={{ color: "#23bee3" }}
                                          ></i>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}

        <Modal
          show={show}
          onHide={onHide}
          size="md"
          className="complete-modal"
          centered
        >
          <Modal.Header>
            <div className="top-heading w-100 d-flex justify-content-end text-end">
              <button className="btn btn-transpatent" onClick={onHide}>
                X
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="content-block text-center">
              <img src={"https://api.recureme.com/images/ADLBlue.svg"} height={90} width={90} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: "25px",
                }}
              >
                <p style={{ fontSize: "16px", lineHeight: "20px" }}>
                  Unlock your potential with personalized physiotherapy sessions
                  and guided workouts
                </p>
                <span className="text-secondary">By :</span>
                <span className="my-1"></span>
                <div className="assign-doctor-card w-100">
                  <div className="doctor-card-content">
                    <div className="image-part">
                      {workoutData?.doctor_id?.profile_image ? (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={workoutData?.doctor_id?.profile_image}
                            style={{ borderRadius: "55%" }}
                            height={100}
                            width={100}
                            alt="User Profile"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={
                              workoutData?.doctor_id?.gender === "female"
                                ? DoctorFeMale
                                : workoutData?.doctor_id?.gender === "male"
                                ? DoctorMale
                                : DoctorCommon
                            }
                            style={{ borderRadius: "55%" }}
                            height={100}
                            width={100}
                            alt="User Profile"
                          />
                        </div>
                      )}
                      {/* <img src={item?.profile_image ? item?.profile_image  : Patient}  height={100} width={100} style={{borderRadius:"55%"}} alt="Patient" /> */}
                    </div>
                    <div className="text-part">
                      <h5 className="name d-flex justify-content-center">
                        {workoutData?.doctor_id?.name}
                        {workoutData?.doctor_id?.clinicVerified === true && ( // Check if the user is verified
                          <div className="px-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                            >
                              <circle cx="10" cy="10" r="10" fill="green" />
                              <path
                                d="M7 10 L9 12 L14 7"
                                stroke="white"
                                stroke-width="2"
                                fill="transparent"
                              />
                            </svg>

                            {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                          </div>
                        )}
                      </h5>
                      <p className="name">
                        {workoutData?.doctor_id?.speciality}
                      </p>
                    </div>
                  </div>
                </div>
                <span className="text-secondary" style={{ fontSize: "12px" }}>
                  Commit to a healthier you for only{" "}
                </span>
                <h4 className="my-1">₹ 499/month</h4>
                <button
                  className="theme-button btn btn-primary mt-3"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/paymentForm");
                  }}
                >
                  Continue
                </button>
                <p
                  className="info-text mt-2"
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#adadad",
                    textDecoration: "none",
                  }}
                >
                  By signing up you are agree to our{" "}
                  <a
                    href="https://recureme.com/terms/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      "text-decoration": "none",
                      color: "#23BEE3",
                      "font-weight": "500",
                    }}
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://recureme.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      "text-decoration": "none",
                      color: "#23BEE3",
                      "font-weight": "500",
                    }}
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
}
export default Workout;
