import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { Button, Modal } from "react-bootstrap";
import TopBar from "../../components/TopBarNavigation";
import "../../assets/css/workout.css";
import videoBanner from "../../assets/images/workout-video.svg";
import clockIcon from "../../assets/images/clock-five.svg";
import pauseBtn from "../../assets/images/pause-btn.svg";
import playBtn from "../../assets/images/play.svg";
import Ex1 from "../../assets/images/ex-1.png";
import modalVideo from "../../assets/images/modal-video.svg";
import doneImg from "../../assets/images/done-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import Info from '../../assets/images/info.png'
import Zoom from '../../assets/images/zoom.png'
import RestTimeScreen from "../Exe/RestTimeScreen";
import { getAuthConfig, post } from "../../libs/http-hydrate";
const WorkoutScreen = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(0);
  const [workoutModel, setWorkoutModel] = useState(false);
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [reportmodal, setReportmodal] = useState(false);
  const [workoutData, setWorkoutData] = useState(location?.state?.workout || []);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  
  workoutData.workout.forEach(exercise => {
    if (exercise.duration === undefined) {
      exercise.duration = 0; // Initialize duration if not already present
    }
  });
  const [paused, setPaused] = useState(false);
  const [showRestTimeScreen, setShowRestTimeScreen] = useState(false)
  const today = new Date().toISOString().slice(0, 10);

  const [isZoomed, setIsZoomed] = useState(false);
  const nextExercise = workoutData?.workout?.find((exercise) => {
    const completedDates = exercise?.exercise_id?.date_Complted;
    return !completedDates || completedDates.length === 0 || !completedDates.includes(today);
  });
  // const incompleteExercises = workoutData?.workout?.filter((exercise) => {
  //   const completedDates = exercise.exercise_id.date_Complted;
  //   return !completedDates || completedDates.length === 0 || !completedDates.includes(today);
  // });
  // const currentExercise = workoutData.workout[0];
  const [incompleteExercises, setIncompleteExercises] = useState(workoutData.workout.slice(1));
  const [completedExercises, setCompletedExercises] = useState([]);
  const [currentExercise, setCurrentExercise] = useState(workoutData.workout[0]);
  const countdownIntervalRef = useRef();
  const videoRef = useRef(null);
  const [currentSet, setCurrentSet] = useState(1);

  const videoreference = useRef(null)
  const description = currentExercise?.description ? currentExercise?.description :  currentExercise?.exercise_id?.description;
  // Check if the description is valid and not empty
  const sentences = description ? description.split('.').filter(sentence => sentence.trim() !== '') : [];


  // This useEffect will handle the countdown logic
  useEffect(() => {
    if (!paused) {
      countdownIntervalRef.current = setInterval(() => {
        setCountdown(prev => prev + 1);
        setWorkoutData(currentData => ({
          ...currentData,
          workout: currentData.workout.map((exercise, index) => {
            if (index === currentExerciseIndex) {
              return { ...exercise, duration: exercise.duration + 1 };
            }
            return exercise;
          })
        }));
      }, 1000);
    }

    return () => clearInterval(countdownIntervalRef.current);
  }, [ countdown, paused]);

  console.log(workoutData,"workoutDataworkoutDataworkoutData")
  const handleNextExercise = () => {

    if (currentSet < currentExercise?.set) {
      setShowRestTimeScreen(true);
    } 
    else {
    if (incompleteExercises.length > 0) {
      // Show the rest time screen and countdown for 1 minute before showing the next exercise
      setShowRestTimeScreen(true);
      //setCountdown(0); // Reset the countdown for the new exercise
      setCurrentExerciseIndex(currentExerciseIndex + 1);

    } else {
      // If there are no more incomplete exercises, the workout session is complete.
      // Show the "Workout Complete" modal
      setCompleteWorkout(true);
    }
  }


  };


  const handleNextClick = () => {
    // setWorkoutModel(true);
    if (currentSet < (Number(currentExercise?.set))) {
      setCurrentSet(currentSet + 1);
      // Reset countdown here if needed
      // setCountdown(0);
    } else {
    if (incompleteExercises.length > 0) {
      setCompletedExercises((prev) => [...prev, currentExercise]);
      setCurrentExercise(incompleteExercises[0]);
      setIncompleteExercises((prev) => prev.slice(1));
      setCurrentSet(1)
      setCountdown(0)
    } else {
      // If there are no more incomplete exercises, the workout session is complete.
    }
  }
  };

  const handleCompleteWorkout = () => {
    setCompleteWorkout(true);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handlePauseClick = () => {
    setPaused((prevPaused) => !prevPaused);
    if (videoreference.current) {
      if (!paused) {
        videoreference.current.getInternalPlayer().pause();
      } else {
        videoreference.current.getInternalPlayer().play();
      }
    }
  };

  const handleZoomClick = () => {

    if (videoRef.current) {
      const videoContainer = videoRef.current.parentElement;

      if (isZoomed) {
        // If already in full-screen, exit full-screen mode
        if (document.exitFullscreen) {
          document.exitFullscreen()
            .then(() => setIsZoomed(false))
            .catch((err) => console.error("Error exiting full-screen:", err));
        }
      } else {
        // If not in full-screen, enter full-screen mode
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen()
            .then(() => setIsZoomed(true))
            .catch((err) => console.error("Error entering full-screen:", err));
        }
      }
    }

  };

  async function ReportExercise() {
    const formdata = new FormData();
    formdata.append("timeOfDay",location?.state?.session.toLowerCase())
    formdata.append("exerciseId",currentExercise?.exercise_id?._id)
    try {
      const data = await post("/reportExercise", formdata, getAuthConfig());
      if (data?.status === 200) {
        // navigate("/exercises")
        setWorkoutModel(false)
        setReportmodal(true)
      }
    }
    catch (err) {
    }
  }

  return (


    showRestTimeScreen ? (
      <RestTimeScreen
        nextExercise={currentSet < currentExercise?.set ? currentExercise : incompleteExercises[0]}
        onNextClick={() => {
          setShowRestTimeScreen(false); // Hide the rest time screen
          handleNextClick(); // Show the next exercise
        }}
      />
    ) :
      <Layout>

        <TopBar title={currentExercise?.exercise_id?.title} tab={location?.state?.session} tabChild={location?.state?.workout?.name} />
        <div className="main-content-part main-container">
          <div className="top-video-block">


            <div className="top-video-block" ref={videoRef}
            >

              <ReactPlayer
              ref={videoreference}
                url={currentExercise?.exercise_id?.videoLink}
                playing={true} // Autoplay the video
                loop={true} // Loop the video
                controls={false} // Show video controls
                width="100%" // Set video width

              />


              <div className="overImageIcon">
                <button className="icon-btn">
                  <img src={Info} alt="info" onClick={(e) => {
                    setWorkoutModel(true)
                  }} />
                </button>
                <button className="icon-btn">
                  <img src={Zoom} alt="zoom" className="zoom" onClick={handleZoomClick} />
                </button>
              </div>
            </div>



          </div>
          <div className="workout-details">
            <div className="type">
              <h5 className="title">{currentExercise?.exercise_id?.title}</h5>
              {/* <div className="timing">
              <img src={clockIcon} alt="clock" />
              <span>03 min</span>
            </div> */}
            </div>
            <div className="control">
              <span className="reps">{Number(currentExercise?.count)} Reps x {Number(currentExercise?.set)} Set</span>
              <p className="timmer">{formatTime(countdown)}</p>

              <span className="current-set my-3">Set {currentSet} of {currentExercise?.set}</span>

              <Button variant="transparent" className="p-4" style={{
                  "border": "1px solid #21bee3",
                  "outline-style": "solid",
                  "outline-color": "#21bee3", background: "white", borderRadius: "14px"
                }} onClick={handlePauseClick}>


                {paused ? <i class="fa-solid fa-play fa-xl"></i> :<i class="fa-solid fa-pause fa-xl"></i>}


              </Button>
            </div>
            {incompleteExercises.length > 0 ?
            <div className="next-workout-card">
              <div className="exercise-card">
              <div className="img-text-part">
                  <div className="img-block">
                  <img src={(currentSet >= currentExercise?.set && incompleteExercises.length > 0) ? incompleteExercises[0]?.exercise_id?.exerciseImage : currentExercise?.exercise_id?.exerciseImage} height={70} alt="next-exercise" />
                  </div>
                  <div className="text-part ps-3">
                    <h6>Rest Time {}</h6>
                    <p>{formatTime(currentExercise?.restTime)} Min</p>
                  </div>
                </div>
          

                <div className="right">

                  {incompleteExercises.length > 0 ?
                    <button
                      className="outline-primary-btn"
                      onClick={() => {
                        handleNextExercise()
                      }}
                    >
                      Next
                    </button>

                    : <button
                      className="outline-primary-btn"
                      onClick={() => {
                        setCompleteWorkout(true)
                      }}
                    >
                      Finish
                    </button>}
                </div>
              </div>
            </div> :
            
            <div className="next-workout-card">
              <div className="exercise-card">
              <div className="img-text-part">
                  <div className="img-block">
                    <img src={incompleteExercises?.length > 0 ? incompleteExercises[0]?.exercise_id?.exerciseImage : currentExercise?.exercise_id?.exerciseImage} height={70} alt="next-exercise" />
                  </div>
                  <div className="text-part ps-3">
                    <h6>{incompleteExercises?.length > 0 ? incompleteExercises[0]?.exercise_id?.title : currentExercise?.exercise_id?.title}</h6>
                    <p>{incompleteExercises?.length > 0 ? incompleteExercises[0]?.count : currentExercise?.count} Reps</p>
                  </div>
                </div>

                <div className="right">
                {currentSet < currentExercise?.set || incompleteExercises.length > 0 ? (
        <button className="outline-primary-btn" onClick={handleNextExercise}>
          Next
        </button>
      ) : (
        <button className="outline-primary-btn" onClick={() => setCompleteWorkout(true)}>
          Finish
        </button>
      )}
                </div>
              </div>
            </div> }
          </div>
        </div>

        {/* Show the RestTimeScreen component if `showRestTimeScreen` is true */}

        <Modal
          show={workoutModel}
          onHide={() => setWorkoutModel(false)}
          size="lg"
          className="main-container"
        >
          <Modal.Body>
            <div className="top-heading">
              <p>{currentExercise?.exercise_id?.title}</p>
              <button
                className="btn btn-transpatent"
                onClick={() => setWorkoutModel(false)}
              >
                X
              </button>
            </div>
            <div className="content-block">
              <ReactPlayer
                url={currentExercise?.exercise_id?.videoLink}
                playing={false} // Autoplay the video
                loop={true} // Loop the video
                controls={false} // Show video controls
                width="100%" // Set video width

              />

            </div>
            <div className="bottom-block">
                
               

              {sentences.map((sentence, index) => (
                <div className="review" key={index}>
                  <span className="no-block">{index + 1}</span>
                  <span className="rv-text">{sentence.trim()}</span>
                </div>
              ))}
                {location?.state?.note && 
                <div className="review">
                <span className="">Special Notes:</span>
                <span className="rv-text">{location?.state?.note}</span>
              </div>}

                {currentExercise?.notes && 
                <div className="review">
                  <span className="">Special Notes:</span>
                  <span className="rv-text">{currentExercise?.notes}</span>
                </div>}
              
              <div className="workout-desc">
                <button className="primary-btn" onClick={(e) =>{
                  e.preventDefault();
                  ReportExercise()
                }}>Report to Doctor</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* workout complete modal */}
        <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block">
              <img src={doneImg} alt="modal-video" />
            </div>
            <div className="bottom-block">
              <div className="workout-desc">
                <span className="workout-reps">Well Done!</span>
                <p>Congratulations On finishing </p>
                <span className="modal-text">
                Kudos on completing your session! Your efforts are building a stronger, healthier you.
                </span>
                <button className="primary-btn" onClick={(e) => {
                  e.preventDefault();
                  navigate("/difficult", { state: { workout: workoutData, session: location?.state?.session } })
                }}>Continue</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>



        <Modal
          show={reportmodal}
          onHide={() => setReportmodal(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading">
              <button
                className="btn btn-transpatent"
                onClick={() => setReportmodal(false)}
              >
                X
              </button>
            </div>
            <div className="content-block">
              <img src={doneImg} alt="modal-video" />
            </div>
            <div className="bottom-block">
              <div className="workout-desc">
                {/* <span className="workout-reps">Well Done!</span> */}
                <p>Your Exercise is reported to the concern Doctor </p>
                {/* <span className="modal-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry.
                </span> */}
                <button className="primary-btn" onClick={() => setReportmodal(false)} >Continue</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>

  );
};

export default WorkoutScreen;
