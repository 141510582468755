import React from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import LeftIcon from '../../assets/images/left.svg'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import DoctorImg from '../../assets/images/doctor.png'
import Calendar from '../../assets/images/calendar.svg'
import Calendar2 from '../../assets/images/calendar-2.svg'
import ChatIcon from '../../assets/images/chatIcon.svg'
import Gym from '../../assets/images/gym.svg'
import User from '../../assets/images/userIcon.svg'
import History from '../../assets/images/history.svg'
import Setting from '../../assets/images/setting.svg';
import Doctor from '../../assets/images/doctor.svg';
import Stetho from '../../assets/images/stethoscope.svg';
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import Auth from '../../libs/auth'
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import Profile from '../../assets/images/profile.svg'

const DoctorDetailsForm = () => {

  const user = Auth.getCurrentUser();
  return (
    <Layout>
      <div className='main-content-part doctorDetailsForm-part  '>
        <div className='doctorName'>
          <div className='image-part mb-2'>
            <img src={user?.profile ?   user?.profile : user?.gender === "female" ? PatientFemale : user?.gender === "male" ? PatientMale : PatientCommon} height={150} width={150} alt='doctor' />
          </div>
          <p>{user?.name}</p>
          <p className='email'>{user?.email ? user?.email : user?.mobile_number}</p>
        </div>
        <div className='common-link-card-part'>
        <Link to="/profileUpdate" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Profile} alt='icon' />
              </div>
              <p className='text'> Profile</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/profileApointment" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Calendar} alt='icon' />
              </div>
              <p className='text'> Appointments</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          {/* <Link to="/chat" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={ChatIcon} alt='icon' />
              </div>
              <p className='text'>Chat</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link> */}
          <Link to="/MyDoctor" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Doctor} alt='icon' />
              </div>
              <p className='text'>My Doctors</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/exercises" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Gym} alt='icon' />
              </div>
              <p className='text'>My Exercises</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
         
          <Link to="/history" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={History} alt='icon' />
              </div>
              <p className='text'>History</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/settings" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Setting} alt='icon' />
              </div>
              <p className='text'>Settings</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>

          <Link to="/settings" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Stetho} alt='icon' />
              </div>
              <p className='text'>Are you a doctor?</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default DoctorDetailsForm
