import React, { useEffect, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import "../../assets/css/doctorInfo.css";
import Ex1 from "../../assets/images/ex-1.png";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import Select from 'react-select'
import { customStyle } from "../../components/Style";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthConfig, post } from "../../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
const Difficult = () => {

  const options = [
      { value: '', label: 'Select Diffculty Level'},
    { value: 'Very Easy', label: 'Very Easy' },
    { value: 'Easy', label: 'Easy' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Hard', label: 'Hard' },
    { value: 'Very Hard', label: 'Very Hard' },

  ]

  const location = useLocation();
  const navigate = useNavigate()
  const [workoutData, setWorkdata] = useState();
  const [addModal, setAddModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null); // To store the exercise data for which difficulty level is being set
  const [selectedDifficulty, setSelectedDifficulty] = useState(options[0]); // To store the selected difficulty level
  const [completedCount, setCompletedCount] = useState(0); // State to keep track of completed exercises count


  useEffect(() => {
    if (location?.state?.workout) {
      setWorkdata(location?.state?.workout)
    }

  }, [])


  useEffect(() => {
    // Redirect to "/home" when completedCount reaches 5
    if (completedCount === workoutData?.workout?.length) {
      navigate("/exercises")
    }
  }, [completedCount]);

  async function WorkoutComplete(workoutData) {
    const formdata = new FormData();
    formdata.append("workoutId", workoutData?._id);
    workoutData.workout.forEach((exercise, index) => {
      formdata.append(`exerciseData[${index}][_id]`, exercise?._id);
      if(exercise?.difficultyLevel){
      formdata.append(`exerciseData[${index}][difficultyLevel]`, exercise?.difficultyLevel);
      }
      if(exercise?.duration){
      formdata.append(`exerciseData[${index}][duration]`, exercise.duration);  // Ensure duration is included
      }
      // Other fields you might want to append to the formdata for each exercise
      // formdata.append(`count[${index}]`, exercise.count);
      // formdata.append(`set[${index}]`, exercise.set);
      // formdata.append(`restTime[${index}]`, exercise.restTime);
    });



    // formdata.append("Id", exercise?._id); // Assuming you have an ID field in the exercise data (e.g., exercise_id)
    // formdata.append("type",location?.state?.session?.toLowerCase()); // Assuming you have a 'type' field in the exercise data
    // formdata.append("difficultyLevel", exercise?.difficultyLevel); // Assuming you have a 'difficultyLevel' field in the exercise data
    try {
      const data = await post("/workoutUpdate", formdata, getAuthConfig());
      if (data?.status === 200) {
        navigate("/exercises")
      }
    }
    catch (err) {
        toast.error("Something went wrong")
    }
  }


  const handleSetDifficulty = (exercise) => {
    setSelectedExercise(exercise);
 // Find the current difficulty of the selected exercise, if set
 const currentDifficulty = options.find(option => option.value === exercise.difficultyLevel);

 // If a difficulty is set, update the selectedDifficulty state, otherwise default to the first option
 setSelectedDifficulty(currentDifficulty || options[0]);
   setAddModal(true);
  };

  const handleDifficultyDone = () => {
    // Assuming you have an ID field in the exercise data (e.g., exercise_id), you can use it as the key to update the difficultyLevel.
    if (selectedDifficulty.value != '') {

    setWorkdata((prevData) => {
      const updatedWorkoutData = prevData.workout.map((item) => {
        if (item.exercise_id === selectedExercise.exercise_id) {
          return {
            ...item,
            difficultyLevel: selectedDifficulty.value,
          };
        }
        return item;
      });
      return { ...prevData, workout: updatedWorkoutData };
    });
  }

    setAddModal(false);
  };

  return (


    <>
    <ToastContainer />
    <Layout>

      <TopBar title={"How’s Difficult?"} tab={"Exercise"} tabChild={"How’s Difficult?"} />
      <div className="main-content-part excersiceList">
        <div className="exercise-content">

          {workoutData?.workout && workoutData?.workout.length > 0 &&
            workoutData?.workout.map((item, index) => {

              const isDifficultySet = item.difficultyLevel !== undefined;

              return (
                <>

                  <div className="exercise-card mb-3" onClick={() => handleSetDifficulty(item)}>
                    <div className="img-text-part">
                      <div className="img-block">
                        <img src={item?.exercise_id?.exerciseImage} height={70} width={80} alt="ex-1" />
                      </div>
                      <div className="text-part ps-3">
                        <h6>{item?.exercise_id?.title}</h6>
                        <p>{Number(item?.count)} Reps x {Number(item?.set)} Set</p>
                      </div>
                    </div>
                    <div className="checkbox">
                      <input type="checkbox" id="checkbox_12" checked={isDifficultySet} // Set the 'checked' attribute based on the condition
                        disabled // Make the checkbox read-only to prevent user interaction
                      />
                      <label for="checkbox_12"></label>
                    </div>
                  </div>

                </>
              )
            })
          }


        </div>

        <div className="button-part mt-1">
          <Button
            className="theme-button"
            onClick={(e) => {
              e.preventDefault();
              if (workoutData?.workout && workoutData?.workout.length > 0) {
                WorkoutComplete(workoutData); // Call the function for each exercise
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
      <Modal
        show={addModal}
        onHide={() => setAddModal(false)}
        size="md"
        className="main-container difficult-modal"
        centered
      >
        <Modal.Body>
          <div className="top-heading" style={{ border: 'none' }}>
            <p style={{ textAlign: 'center', width: "90%" }}> Exercises Level</p>
            <button
              className="btn btn-transpatent"
              onClick={() => setAddModal(false)}
            >
              X
            </button>
          </div>
          <div className="bottom-block">
            <div className="input-group">
              <label> Exercises Level</label>
              <Select
                options={options}
                value={selectedDifficulty}
                onChange={(selectedOption) => setSelectedDifficulty(selectedOption)}
                styles={customStyle}
              />
            </div>
            <button className="primary-btn" onClick={handleDifficultyDone}>Done</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addNoteModal}
        onHide={() => setAddNoteModal(false)}
        size="md"
        className="main-container difficult-modal"
        centered
      >
        <Modal.Body>
          <div className="top-heading" style={{ border: 'none' }}>
            <p style={{ textAlign: 'center', width: "90%" }}>Note</p>
            <button
              className="btn btn-transpatent"
              onClick={() => setAddNoteModal(false)}
            >
              X
            </button>
          </div>
          <div className="bottom-block">
            <div className="input-group">
              <label>Note</label>
              <textarea name="note" id="note" cols="50" rows="5" placeholder={"Write note here..."}></textarea>
            </div>
            <button className="primary-btn">Done</button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
    </>
  );
};

export default Difficult;
