import React, { useEffect, useState } from "react";

import Layout from "../layout";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import doneImg from "../assets/images/done-icon.svg";
import Modal from "react-bootstrap/Modal";

const App = () => {
  const location = useLocation()
  const user = Auth.getCurrentUser()
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscribeId,setSubscribeId] = useState("")
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    emergencyNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
  });

  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("user");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });

  const [useResidenceAddress, setUseResidenceAddress] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      const isBillingAddressComplete = useResidenceAddress
        ? data?.address?.street &&
          data?.address?.city &&
          data?.address?.state &&
          data?.address?.country &&
          data?.address?.pincode
        : contactInformation.street &&
          contactInformation.city &&
          contactInformation.state &&
          contactInformation.country &&
          contactInformation.pincode;
      setIsFormValid(isBillingAddressComplete);
    };

    checkFormValidity();
  }, [useResidenceAddress, contactInformation, data]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    const usserdata ={}
    // Call your backend to create the checkout session
    const response = await post("/patient/create-checkout-session",usserdata    , getAuthConfig());
    if (response.error) {
      setError(response.error.message);
    } else {
      // Redirect to the checkout page
      window.location.href = response.data.session.url;
    }
  

    setLoading(false);
  };
  return (
    <>
    <Layout>

    <div className="payment">
          <div className="text-center mt-5">
            <h5 className="title"> Payment Information</h5>
            <p>One more step before closing more revenue with RecureMe.</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card shadow m-4 w-75 bg-white border-0 p-3 mb-5">
                <div className="form-data p-2" style={{ maxWidth: "100%" ,width:"100%"}}>
                  {/* <h5 className="text-start  py-3">Billing address</h5> */}

                  <Row className="g-4 w-100">
                    <Col lg="12">
                      <div className="filed">
                        <label>Your Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          disabled
                          value={data?.name}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

      
                {error && (
                  <div className="text-danger text-center mb-5">{error}</div>
                )}

                <div className="buttonPart mt-3 d-flex justify-content-center mb-5">
                {error && <div className="text-danger text-center mb-5">{error}</div>}

                  <button
                    className="theme-button text-white border-0"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
  {loading ? "Processing..." : "Pay with Stripe"}
                  </button>
                </div>
            </div>
          </div>
        </div>
      {/* <div className="container text-center mt-4">
                <button onClick={displayRazorpay} className="btn btn-primary">Pay with Razorpay</button>
      </div> */}
      <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <img src={doneImg} height={350} width={400} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Payment Done</p>

                <span>Thank you for Choosing us</span>

                <button className="btn btn-outline-secondary my-3">
                  Order Id : {subscribeId}
                </button>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </Layout>
    </>
  );
};

export default App;
