import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const RestTimeScreen = ({ nextExercise, onNextClick }) => {
  const [secondsLeft, setSecondsLeft] = useState(Number(nextExercise?.restTime));

  useEffect(() => {
    // Start the countdown when the component mounts
    const timer = setInterval(() => {
      setSecondsLeft((prev) => prev - 1);
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Navigate to the next exercise when the countdown reaches 0
    if (secondsLeft === 0) {
      onNextClick();
    }
  }, [secondsLeft, onNextClick]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  return (
    <div style={{ height: "100vh" }}>
      <div className="text-center" style={{ height: "30vh", background: "#21bee3", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="control" >
          <h3>Rest</h3>
          <h2 className="timmer">{formatTime(secondsLeft)}</h2>

          <button style={{ "all": "unset", "cursor": "pointer", "background-color": "#fff", "font-size": "15px", "font-weight": "400", "padding": "8px 20px", "border-radius": "14px", color: "#21bee3" }} onClick={(e) =>{
            e.preventDefault();
            onNextClick();
          }} >skip</button>

        </div>
      </div>


      <div>
        <div className="top-video-block">
          <ReactPlayer
            url={nextExercise?.exercise_id?.videoLink}
            playing={true} // Autoplay the video
            loop={true} // Loop the video
            controls={false} // Show video controls
            width="100%" // Set video width
          />


        </div>
        <div className=" text-center mt-4">

          <p>next Exercise</p>

          <h2>{nextExercise?.exercise_id?.title}</h2>

        </div>

      </div>


    </div>
  );
};

export default RestTimeScreen;
