import React, { useState } from 'react';
import '../assets/css/header.css';
import { BsSearch } from 'react-icons/bs'
import notificationIcon from '../assets/images/notification.svg'
import Avatar from 'react-avatar';
import profileImg from '../assets/images/Ellipse 179.svg'
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate , useLocation } from 'react-router-dom';
import { UseEffectOnce } from '../Hook/UseEffectOnce';
import Auth from '../libs/auth';
import { get, getAuthConfig } from '../libs/http-hydrate';
import Modal from 'react-bootstrap/Modal';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import moment from "moment"
import DoctorDummy from "../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../assets/images/defaultpatient@3x.png"
import FillStar from "../assets/images/star.svg";
import Patient from "../assets/images/Dr-Henna-S.svg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from '../assets/images/docpy.png'
import home from '../assets/images/home.svg'
import homeActive from '../assets/images/homeActive.svg'
import community from '../assets/images/community.svg';
import communityActive from '../assets/images/community-active.svg';
import excersize from '../assets/images/excersize.svg';
import excersizeActive from '../assets/images/excersize-active.svg';
import appointment from '../assets/images/appointment.svg';
import appointmentActive from '../assets/images/appointment-active.svg';
import { Row, Col } from 'react-bootstrap';
import DoctorCommon from "../assets/images/Docotr.png";
import DoctorMale from "../assets/images/Doctor Male.png";
import DoctorFeMale from "../assets/images/Doctor Female.png";
import PatientCommon from "../assets/images/Patint.png";
import PatientFemale from "../assets/images/Patient Female.png";
import PatientMale from "../assets/images/Patient male.png";
import InternetConnectivity from './InternetConnectivity';
const Header = () => {
  const [isSearchFocused, setSearchFocused] = useState(false);
  const [showInternetModal, setShowInternetModal] = useState(false);

    const location = useLocation();
    const dropdownRef = useRef(null);
    const activePage = location.pathname.replace('/', '');
    const user = Auth.getCurrentUser()
    const [show, setShow] = useState(false);
    const handleClose = () => {
        getHomeData();
        setShow(false)
    };
    const handleShow = () => setShow(true);
    const [headerData, setHeaderData] = useState({});
    const [docpage, setDocPage] = useState(0);
    const [notification, setNotification] = useState({
        items: [],
        has_more: false
    })
    const [unreadCount, setunreadCount] = useState()
    const [searchTerm, setSearchTerm] = useState("")
    const [doctorList, setDoctorList] = useState({
        items: [],
        has_more: false
    })
    const [page, setPage] = useState(0);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const navigate = useNavigate()
    UseEffectOnce(() => {
        getHomeData();
        if (!Auth.getCurrentUser()) {
            navigate("/login")
        }
    }, [])
    useEffect(() => {
        if (page > 0) {
            getNotification()
        }
    }, [page])
  
    useEffect(() => {
        const handleClickOutside = (e) => {
          if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setDocPage(0)
            setDropdownOpen(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

      useEffect(() => {
        if (isSearchFocused || isDropdownOpen) {
          getPhyiolist();
        }
      }, [isSearchFocused, docpage, searchTerm]);
      
    const observer = useRef();
    const lastBookElementRef = useCallback(
        (node) => {
            // if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && notification?.has_more) {
                    setPage((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [notification?.has_more]
    );

    async function getHomeData() {
      if (!navigator.onLine) {
        setShowInternetModal(true);
        return;
      }
        try {
            const data = await get("/patient", getAuthConfig());
            if (data?.status === 200) {
                setHeaderData(data?.data?.patient);

                if(!data?.data?.patient?.dob)
                {
                    Auth.logout()
                    navigate("/login")
                }
                if (data?.data?.unread_notification_count != null) {
                    setunreadCount(data?.data?.unread_notification_count)
                }
            }

        } catch (error) {
            if (error.response?.status === 401) {
                Auth.logout()
                navigate("/login")
            }
        }
    }

    async function getNotification() {
        try {
            const data = await get(`/notification?pageNumber=${page}`, getAuthConfig());
            if (data?.status === 200) {
                let sortedItems = data?.data?.msg;

                // Sort the notifications by 'created_at' timestamp in descending order
                sortedItems.sort((a, b) => {
                    const timestampA = new Date(a.created_at).getTime();
                    const timestampB = new Date(b.created_at).getTime();
                    return timestampB - timestampA;
                });

                if (page > 0) {
                    setNotification((prevNotification) => ({
                        ...prevNotification,
                        items: prevNotification?.items.concat(sortedItems),
                    }));
                } else {
                    setNotification((prevNotification) => ({
                        ...prevNotification,
                        items: sortedItems,
                    }));
                }
                setNotification((e) => ({ ...e, has_more: data?.data?.has_more }))
            }
        } catch (error) {
            if (error?.response?.status === 401) {

                navigate("/login")

            }

        }
    }
    function formatDateTime(timestamp) {
        return moment(timestamp).format('h:mm A z , MMM D');
    }

    async function getPhyiolist() {
        try {
            const data = await get(`/physiotherapistlist?pageNumber=${docpage}&searchTerm=${searchTerm}`, getAuthConfig());
            if (data?.status === 200) {

                if (docpage > 0) {
                    setDoctorList((p) => ({ ...p, items: doctorList?.items.concat(data?.data?.data) }));
                } else {
                    setDoctorList((p) => ({ ...p, items: data?.data?.data }));
                }
                // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
                setDoctorList((e) => ({ ...e, has_more: data?.data?.has_more }))
            }
        } catch (error) {
        }
    }


    // Function to close the dropdown
    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    return (
        <>


<Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="w-100 header-main">
       
<Navbar.Brand onClick={() => navigate('/')} className="cursor-pointer">
<div className='w-100 text-center mobile-logo'>
    <img src={logo} alt="docpy-logo" width={180} height={50}/>
  </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
        <Navbar.Collapse id="responsive-navbar-nav " style={{ backgroundColor: 'white'}} >
          <Nav className="mr-auto w-100" >

          <div className='sidebar-main' style={{height:"fit-content"}}>

          <div className="nav-part">

          <div className="top-navpart">
          <Link
            to='/dashboard'
            style={{ textDecoration: "none", color: "inherit" }} 
            className={` ${activePage === 'dashboard' ? 'nav-btn active' : 'nav-btn'}`}
            >
              <img src={activePage === 'dashboard' ? homeActive : home} alt="" className='icon' />
              <p className='text'>Home</p>
          </Link>
          <Link
            to='/exercises'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'exercises' || activePage === 'excersiceList' || activePage === 'workout' || activePage === 'difficult' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'exercises' || activePage === 'excersiceList' || activePage === 'workout' || activePage === 'difficult' ? excersizeActive : excersize} alt="" className='icon' />
              <p className='text'>Exercises</p>
          </Link>
          <Link
            to='/appointment'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'appointment' || activePage === 'doctorDetails' || activePage === 'bookAppointment'   ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'appointment' || activePage === 'doctorDetails' || activePage === 'bookAppointment'  ? appointmentActive : appointment} alt="" className='icon' />
              <p className='text'>Appointment</p>
          </Link>
          <Link
            to='/community'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'community' || activePage === 'communityDetails' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'community'  || activePage === 'communityDetails' ? communityActive : community} alt="" className='icon' />
              <p className='text'>Community</p>
          </Link>
        </div>
        {/* <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div> */}
      </div>
       </div>
            {/* Place your navigation links here */}
            <div className="search-part w-50">
                    <input type="text" placeholder='search physiotherapist' value={searchTerm} onChange={(e) => {
                        e.preventDefault();
                        setDocPage(0)
                        setSearchTerm(e.target.value);
                        setDropdownOpen(true);
                    }}
                    onFocus={() => setSearchFocused(true)}
                    onBlur={() => setSearchFocused(false)}
                    />
                    <BsSearch size={16} className='icon-search' />
                </div>
        {isDropdownOpen && (
          <div className="dropdowns" style={{width:"25%"}}>
            <div
              className="dropdowns-list"
              style={{ maxHeight: "55vh", overflowX: "hidden",overflowY:"scroll" }}
            >
              <div className="doctors-list" ref={dropdownRef}>
                {/* Your dropdown content goes here */}
                <div
                  className="title-block"
                  style={{ display: "inline-block", width: "100%" }}
                >
                  <Row className='g-2 my-2 m-0'>

                  {doctorList?.items &&
                    doctorList?.items.length > 0 &&
                    doctorList?.items?.map((item, index) => {
                      return (
                        <Col lg="12" className="d-flex justify-content-center align-items-center">

                        <div
                          className="assign-doctor-card my-2"
                          style={{ cursor: "pointer", width: "85%" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setDropdownOpen(false);
                            setDocPage(0)
                            navigate("/doctorDetails", {
                              state: { doctor: item },
                            });
                          }}
                        >
                          <div className="doctor-card-content">
                            <div className="image-part">
                              {item?.profile_image ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={item?.profile_image}
                                    style={{ borderRadius: "55%" }}
                                    height={50}
                                    width={50}
                                    alt="User Profile"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={Patient}
                                    style={{ borderRadius: "55%" }}
                                    height={50}
                                    width={50}
                                    alt="User Profile"
                                  />
                                </div>
                              )}
                              {/* <img src={item?.profile_image ? item?.profile_image  : Patient}  height={100} width={100} style={{borderRadius:"55%"}} alt="Patient" /> */}
                            </div>
                            <div className="text-part">
                              <h5 className="name d-flex justify-content-center">
                                {item?.name}{" "}
                                {item?.associatedEntity?.verified === true && ( // Check if the user is verified
                                  <div
                                    className="px-1"
                                    // style={{
                                    //     position: 'absolute',
                                    //     top: '-2px',
                                    //     right: '-5px',
                                    // }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                    >
                                      <circle
                                        cx="10"
                                        cy="10"
                                        r="10"
                                        fill="green"
                                      />
                                      <path
                                        d="M7 10 L9 12 L14 7"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="transparent"
                                      />
                                    </svg>

                                    {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                  </div>
                                )}
                              </h5>
                              <p className="name">{item?.designation}</p>
                              <div className="rating-part d-flex justify-content-center mb-2">
                              {Array.from({ length: 5 }, (_, index) => (
        <i
          key={index}
          className={`star fa fa-star${index < item?.averageRating ? ' filled' : ''}`}
        ></i>
      ))} 
                              </div>
                            </div>
                          </div>
                        </div>
                        </Col>
                      );
                    })}
                  </Row>
                  {doctorList?.has_more === true && (
                    <div
                      className="text-center p-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setDocPage((prevPageNumber) => prevPageNumber + 1);
                      }}
                    >
                      Load More
                    </div>
                  )}
                  {doctorList?.items && doctorList?.items.length <= 0 && (
                    <div className="text-center p-5">No Physiotherapist Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
            {/* <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer> */}
            {/* ... other navigation links */}
          </Nav>
          <Nav className='w-100'>
            {/* Right-aligned items (e.g., profile, notifications) */}
              {/* <LinkContainer to="/profile">
                <NavDropdown.Item>Profile</NavDropdown.Item>
              </LinkContainer> */}
           
           <div className="profile-part">
                    <div className="notification-block" style={{ "cursor": "pointer" }} onClick={(e) => {
                        e.preventDefault();
                        getNotification()
                        handleShow();
                    }}>
                        <img src={notificationIcon} alt="notification-icon" width='20px' />
                        <div className="noti-no">{Number(unreadCount) > 99 ? "99+" : unreadCount}</div>
                    </div>
                    <div className="profile">
                        <img src={headerData?.profile_image ? headerData?.profile_image : headerData?.gender === "female" ? PatientFemale : headerData?.gender === "male" ? PatientMale : PatientCommon} style={{ borderRadius: "55%" }} height={"40px"} width={"40px"} />
                        <Dropdown style={{ position: "relative", zIndex: "1000" }}>
                            <Dropdown.Toggle id='dropdown-profile' className='btn-custom'>{headerData?.name} </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/accounts")
                                }}>Account</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/appointment")
                                }}>Appointment</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    e.preventDefault();
                                    Auth.logout();
                                    navigate("/login")
                                }}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>        
            {/* ... other dropdown items */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
   

         
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton className='justify-content-start border-0'>
                    <Modal.Title className='text-center'>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='body-content' ref={lastBookElementRef} style={{ maxHeight: "700px", overflowY: "scroll" }}>
                        {notification?.items && notification?.items.length <= 0 && <div className='text-center'> No Records Found </div>}

                        {notification?.items && notification?.items.length > 0 &&
                            notification?.items.map((item, index) => {
                                return (
                                    <>

                                        <div className="card roundedBorder shadow-lg p-3 mt-3" style={{ border: "none", borderRadius: "14px" }}>
                                            <p className=""><strong>{item?.title}</strong></p>
                                            <p className="light-text mt-0">{item?.body}</p>

                                            <p className="light-text mt-1">

                                                {formatDateTime(item?.created_at)}

                                                {/* 5:45 PM, Feb 24 */}



                                            </p>
                                        </div>

                                    </>
                                )
                            })
                        }


                    </div>
                </Modal.Body>

            </Modal>
            
            <InternetConnectivity show={showInternetModal} onHide={() => setShowInternetModal(false)} />
</>
    )
}

export default Header
