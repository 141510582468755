import React, { useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import "../../assets/css/commonComponent.css";
import { Col, Row } from "react-bootstrap";
import womenTranning from "../../assets/images/young-woman-training.svg";
import play from "../../assets/images/play.svg";
import FillStar from "../../assets/images/star.svg";
import Patient from "../../assets/images/Dr-Henna-S.svg";
import AppointmentBg from "../../assets/images/suggestion-card-bg.svg";
import like from "../../assets/images/like.svg";
import comment from "../../assets/images/comment.svg";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../libs/auth";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig, post } from "../../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CommunityImg from "../../assets/images/community.png";
import DoctorimgIcon from "../../assets/images/doctorimgIcon.png";
import Like from "../../assets/images/like.svg";
import Comment from "../../assets/images/CommentCommunity.svg";
import { Modal } from "react-bootstrap";
import doneImg from "../../assets/images/done-icon.svg";
import LikeCommentFill from "../../assets/images/FillLikePatient.svg";
import LikeComment from "../../assets/images/LikeCommunity.svg";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const user = Auth.getCurrentUser();
  const [HomeData, setHomeData] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const closeModal = () => setModalShow(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedPhysioDetail, setSelectedPhysioDetail] = useState({
    physiotherapist: "",
    comment: "",
  });
  const [completeWorkout, setCompleteWorkout] = useState(false);

  UseEffectOnce(() => {
    if (user === null) {
      navigate("/signup");
    } else {
      getHomeData();
    }
  }, []);

  async function getHomeData() {
    try {
      setLoading(true);
      const data = await get("/home?platform=Web", getAuthConfig());
      if (data?.status === 200) {
        setLoading(false);

        setHomeData(data?.data);
      }
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 403) {
        toast.error(
          "Your account is marked for deletion. You can recover it within the recovery period (30 days )."
        );
        setTimeout(() => {
          Auth.logout();
          navigate("/login");
        }, 5000);
      }
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }
  async function likeCommunity(id, unlike) {
    try {
      let url;
      if (unlike) {
        url = `/likeCommunity?communityId=${id}&unlike=true`;
      } else {
        url = `/likeCommunity?communityId=${id}`;
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        // Update HomeData with new likeByYou and likeCount values
        const updatedHomeData = { ...HomeData };
        updatedHomeData.latestCommunity.likeByYou = !unlike;
        updatedHomeData.latestCommunity.likeCount = unlike
          ? updatedHomeData.latestCommunity.likeCount - 1
          : updatedHomeData.latestCommunity.likeCount + 1;

        // Set the updated HomeData
        setHomeData(updatedHomeData);
      }
    } catch (error) {}
  }
  async function addRating() {
    try {
      const formdata = new FormData();
      formdata.append(
        "physiotherapistId",
        selectedPhysioDetail?.physiotherapist
      );
      formdata.append("model_types", "Physiotherapist");
      formdata.append("rating", selectedRating);
      formdata.append("comment", selectedPhysioDetail?.comment);
      let url = `/addReview`;
      const data = await post(url, formdata, getAuthConfig());
      if (data?.status === 200) {
        setSelectedRating(0);
        setSelectedPhysioDetail((p) => ({
          ...p,
          comment: "",
          physiotherapist: "",
        }));
        setModalShow(false);
        setCompleteWorkout(true);
        getHomeData();
      }
    } catch (error) {}
  }
  const handleStarClick = (value) => {
    setSelectedRating(value);
  };

  const isPastTime = (timeSlot) => {
    const currentTime = moment();
    const appointmentTime = moment(timeSlot, "HH:mm:ss");
    return currentTime.isAfter(appointmentTime);
  };

  return (
    <Layout>
      <ToastContainer />
      {loading ? (
        <div className="preloader-whirlpool">
          <div className="whirlpool"></div>
        </div>
      ) : (
        <div className="main-home-part">
          <Row>
            <Col lg="6">
              <div className="left-part">
                <div
                  className="doctor-card w-100 p-3 mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "between",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // if(HomeData?.workout?.totalExerciseForToday > 0){
                    navigate("/exercises");
                    // }
                  }}
                >
                  {HomeData?.workout?.randomWorkoutImage ? (
                    <img
                      className="my-4"
                      src={HomeData?.workout?.randomWorkoutImage.replace(
                        ".svg",
                        "Blue.svg"
                      )}
                      alt="card-img"
                      height={90}
                      width={90}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="my-4"
                      width="78"
                      height="78"
                      viewBox="0 0 78 78"
                    >
                      <g
                        id="Group_3151"
                        data-name="Group 3151"
                        transform="translate(-363 -170)"
                      >
                        <path
                          id="Path_15810"
                          data-name="Path 15810"
                          d="M39,78A39,39,0,1,0,0,39,39,39,0,0,0,39,78Z"
                          transform="translate(363 170)"
                          fill="#23bee3"
                        />
                        <g
                          id="Group_3150"
                          data-name="Group 3150"
                          transform="translate(390.472 -102.878)"
                        >
                          <g
                            id="Group_3149"
                            data-name="Group 3149"
                            transform="translate(-0.472 285.878)"
                          >
                            <path
                              id="Path_15820"
                              data-name="Path 15820"
                              d="M-2494.453,623.243a4.713,4.713,0,0,0-4.714-4.714,4.714,4.714,0,0,0-4.714,4.714,4.714,4.714,0,0,0,4.714,4.714,4.715,4.715,0,0,0,4.715-4.714Z"
                              transform="translate(2511.133 -618.529)"
                              fill="#fff"
                            />
                            <path
                              id="Path_15821"
                              data-name="Path 15821"
                              d="M-2486.377,629.573c-.067.1-.132.194-.2.292a4.812,4.812,0,0,0-.771,2.2,43.1,43.1,0,0,0,.214,9.9l2.653,16.851a2.889,2.889,0,0,1-2.4,3.3,2.888,2.888,0,0,1-3.3-2.4l-2.762-17.534a.6.6,0,0,0-.592-.5.6.6,0,0,0-.593.5l-2.761,17.534a2.889,2.889,0,0,1-3.3,2.4,2.887,2.887,0,0,1-2.4-3.3l2.654-16.85a43.143,43.143,0,0,0,.216-9.9,4.8,4.8,0,0,0-.77-2.2c-.065-.1-.132-.2-.2-.292a.362.362,0,0,0-.387-.149.36.36,0,0,0-.271.313,29.016,29.016,0,0,0,.585,9.714,1.987,1.987,0,0,1-1.485,2.385,1.987,1.987,0,0,1-2.385-1.485,32.968,32.968,0,0,1-.059-14.709,6.019,6.019,0,0,1,5.874-4.7h10.573a6.02,6.02,0,0,1,5.874,4.7,32.994,32.994,0,0,1-.061,14.709,1.984,1.984,0,0,1-2.372,1.435,1.985,1.985,0,0,1-1.495-2.335,29.019,29.019,0,0,0,.584-9.715.357.357,0,0,0-.27-.313.362.362,0,0,0-.387.149Z"
                              transform="translate(2505.502 -610.139)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  )}
                  <div
                    className="info-block"
                    style={{ flexGrow: 1, gap: "0px", marginLeft: "10px" }}
                  >
                    <span>Today's </span>
                    <h3> workout plan</h3>
                    <div className="d-flex justify-content-start">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.129"
                          height="13.721"
                          viewBox="0 0 11.129 13.721"
                        >
                          <g
                            id="Path_15809"
                            data-name="Path 15809"
                            transform="translate(-110 -76)"
                            fill="none"
                          >
                            <path
                              d="M119.871,78.992v-2.23h1.258V76H110v.762h1.372V79a4.247,4.247,0,0,0,2.489,3.865,4.252,4.252,0,0,0-2.489,3.865v2.233H110v.762h11.129v-.762h-1.258V86.725a4.247,4.247,0,0,0-2.489-3.865A4.253,4.253,0,0,0,119.871,78.992Z"
                              stroke="none"
                            />
                            <path
                              d="M 112.3720474243164 76.99999237060547 L 112.3720474243164 78.99568176269531 C 112.3720474243164 80.26499938964844 113.1188583374023 81.42462158203125 114.2746353149414 81.94997406005859 L 115.6255035400391 82.56399536132812 L 116.9667358398438 81.95085906982422 C 118.1237106323242 81.42197418212891 118.8712768554688 80.260498046875 118.8712768554688 78.99185943603516 L 118.8712768554688 76.99999237060547 L 112.3720474243164 76.99999237060547 M 115.6178283691406 83.15666961669922 L 114.276611328125 83.76981353759766 C 113.11962890625 84.29872131347656 112.3720474243164 85.45870971679688 112.3720474243164 86.72502136230469 L 112.3720474243164 88.72071075439453 L 118.8712768554688 88.72071075439453 L 118.8712768554688 86.72502136230469 C 118.8712768554688 85.45570373535156 118.1244659423828 84.29605865478516 116.9686965942383 83.77071380615234 L 115.6178283691406 83.15666961669922 M 109.9999923706055 75.99999237060547 L 121.1290054321289 75.99999237060547 L 121.1290054321289 76.76226043701172 L 119.8712768554688 76.76226043701172 L 119.8712768554688 78.99185943603516 C 119.8712768554688 80.71078491210938 118.8498458862305 82.18955993652344 117.3824996948242 82.86034393310547 C 118.8498458862305 83.52732086181641 119.8712768554688 85.00991821289062 119.8712768554688 86.72502136230469 L 119.8712768554688 88.95845031738281 L 121.1290054321289 88.95845031738281 L 121.1290054321289 89.72071075439453 L 109.9999923706055 89.72071075439453 L 109.9999923706055 88.95845031738281 L 111.3720474243164 88.95845031738281 L 111.3720474243164 86.72502136230469 C 111.3720474243164 85.00991821289062 112.3934860229492 83.53114318847656 113.86083984375 82.86034393310547 C 112.3934860229492 82.19337463378906 111.3720474243164 80.71078491210938 111.3720474243164 78.99568176269531 L 111.3720474243164 76.76226043701172 L 109.9999923706055 76.76226043701172 L 109.9999923706055 75.99999237060547 Z"
                              stroke="none"
                              fill="#777"
                            />
                          </g>
                        </svg>
                        &nbsp;{" "}
                        <span>
                          {HomeData?.workout?.totalWorkoutsForToday +
                            " Workout"}{" "}
                        </span>
                      </div>
                      &nbsp;
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.925"
                          height="13.967"
                          viewBox="0 0 13.925 13.967"
                        >
                          <path
                            id="Path_15818"
                            data-name="Path 15818"
                            d="M18.82,19.764a3.747,3.747,0,0,0,3.259-1.907L23.7,14.962a2.075,2.075,0,0,1,1.109-.939l.308-.113a3.145,3.145,0,0,0,1.943-1.947,4.525,4.525,0,0,0-.781-3.854,3.815,3.815,0,0,1-.543-1.729A.4.4,0,0,0,25.314,6a.407.407,0,0,0-.381.429,4.665,4.665,0,0,0,.656,2.1A3.809,3.809,0,0,1,26.3,11.7a2.391,2.391,0,0,1-1.453,1.445l-.308.113a2.908,2.908,0,0,0-1.543,1.3L21.371,17.46a2.926,2.926,0,0,1-2.551,1.494H14.634a.325.325,0,0,1-.324-.324.321.321,0,0,1,.259-.316l1.069-.227a3.754,3.754,0,0,0,1.838-.98l.146-.142a3.745,3.745,0,0,0,1.134-2.417,23.65,23.65,0,0,1,.98-5.275,7.8,7.8,0,0,0,.344-2.267V6.4a.4.4,0,0,0-.81,0V7a7.011,7.011,0,0,1-.308,2.032,24.509,24.509,0,0,0-1.012,5.454,2.945,2.945,0,0,1-.891,1.895l-.15.142a2.965,2.965,0,0,1-1.441.769l-1.069.227a1.132,1.132,0,0,0-.109,2.186Zm-2.458,0Z"
                            transform="translate(-13.4 -5.897)"
                            fill="#777"
                            stroke="#777"
                            stroke-width="0.2"
                          />
                        </svg>{" "}
                        &nbsp;
                        <span>
                          {HomeData?.workout?.totalExerciseForToday +
                            " Exercise"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-end" style={{ marginRight: "10px" }}>
                    <i
                      class="fa-solid fa-chevron-right fa-2xl"
                      style={{ color: "#23bee3" }}
                    ></i>
                  </div>
                </div>
                <div className="doctors-list">
                  <div className="title-block">
                    {HomeData?.myDoctors && HomeData?.myDoctors.length > 0 && (
                      <h6>Your Doctors</h6>
                    )}
                    {HomeData?.myDoctors && HomeData?.myDoctors.length > 0 && (
                      <Link to={"/MyDoctor"} className="view-all-btn">
                        View all
                      </Link>
                    )}
                  </div>
                  {HomeData?.myDoctors &&
                    HomeData?.myDoctors.length > 0 &&
                    HomeData?.myDoctors?.map((item, index) => {
                      return (
                        <div className="assign-doctor-card">
                          <div className="doctor-card-content">
                            <div className="image-part">
                              {item?.profile_image ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={item?.profile_image}
                                    style={{ borderRadius: "55%" }}
                                    height={100}
                                    width={100}
                                    alt="User Profile"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={
                                      item?.gender === "female"
                                        ? DoctorFeMale
                                        : item?.gender === "male"
                                        ? DoctorMale
                                        : DoctorCommon
                                    }
                                    style={{ borderRadius: "55%" }}
                                    height={100}
                                    width={100}
                                    alt="User Profile"
                                  />
                                </div>
                              )}
                              {/* <img src={item?.profile_image ? item?.profile_image  : Patient}  height={100} width={100} style={{borderRadius:"55%"}} alt="Patient" /> */}
                            </div>
                            <div className="text-part">
                              <h5 className="name d-flex justify-content-center">
                                {item?.name}
                                {item?.clinicVerified === true && ( // Check if the user is verified
                                  <div
                                    className="px-1"
                                    // style={{
                                    //     position: 'absolute',
                                    //     top: '-2px',
                                    //     right: '-5px',
                                    // }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                    >
                                      <circle
                                        cx="10"
                                        cy="10"
                                        r="10"
                                        fill="green"
                                      />
                                      <path
                                        d="M7 10 L9 12 L14 7"
                                        stroke="white"
                                        stroke-width="2"
                                        fill="transparent"
                                      />
                                    </svg>

                                    {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                  </div>
                                )}
                              </h5>
                              <p className="name">{item?.speciality}</p>
                              <div
                                className="rating-part"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedPhysioDetail((p) => ({
                                    ...p,
                                    physiotherapist: item?._id,
                                  }));
                                  setModalShow(true);
                                }}
                              >
                                <div className="stars-container">
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className={`star fa fa-star${
                                        index < item?.averageRating
                                          ? " filled"
                                          : ""
                                      }`}
                                    ></i>
                                  ))}{" "}
                                </div>
                                <span style={{ fontSize: "12px" }}>
                                  &nbsp;
                                  {item?.averageRating.toFixed(1)} (
                                  {item?.totalRatingCount})
                                </span>

                                {/* <img src={FillStar} alt="star" />
                                <img src={FillStar} alt="star" />
                                <img src={FillStar} alt="star" />
                                <img src={FillStar} alt="star" />
                                <img src={FillStar} alt="star" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {HomeData?.myDoctors && HomeData?.myDoctors.length <= 0 && (
                    <div className="text-center">No Doctors Appointmented</div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="right-part">
                {HomeData?.Appointment && HomeData?.Appointment?.length > 0 && (
                  <div className="doctors-list mt-3">
                    <div className="title-block">
                      {HomeData?.Appointment &&
                        HomeData?.Appointment.length > 0 && (
                          <h6>Upcoming Appointment</h6>
                        )}
                      {HomeData?.Appointment &&
                        HomeData?.Appointment.length > 0 && (
                          <button
                            className="view-all-btn"
                            style={{ marginBottom: "8px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/profileApointment");
                            }}
                          >
                            View all
                          </button>
                        )}
                    </div>
                    {HomeData?.Appointment === null && (
                      <div
                        className="text-center d-flex justify-content-center"
                        style={{ alignItems: "baseline", fontSize: "16px" }}
                      >
                        No Appointment Found &nbsp;
                        {/* <div className="recommanded"> */}
                        <div className="title-block">
                          <Link to={"/appointment"} className="view-all-btn">
                            {" "}
                            Book Now
                          </Link>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                    {HomeData?.Appointment &&
                      HomeData?.Appointment.length > 0 &&
                      HomeData?.Appointment?.map((item, index) => {

                        const pastTime = isPastTime(item?.timeSlot);

                        return (
                          <>
                            <div className="assign-doctor-card"                 style={{ opacity: pastTime ? 0.5 : 1 }}
>
                              <div className="doctor-card-content">
                                <div className="image-part">
                                  <img
                                    src={
                                      item?.doctor_id?.profile_image
                                        ? item?.doctor_id?.profile_image
                                        : item?.doctor_id?.gender === "female"
                                        ? DoctorFeMale
                                        : item?.doctor_id?.gender === "male"
                                        ? DoctorMale
                                        : DoctorCommon
                                    }
                                    height={100}
                                    width={100}
                                    style={{ borderRadius: "55%" }}
                                    alt="Patient"
                                  />
                                </div>
                                <div className="text-part dashtext-part">
                                  <div className="info-block">
                                    <h5 className="name">
                                      {" "}
                                      {item?.doctor_id?.name}{" "}
                                      {item?.doctor_id?.clinicVerified ===
                                        true && ( // Check if the user is verified
                                        <div
                                          className="px-1"
                                          // style={{
                                          //     position: 'absolute',
                                          //     top: '-2px',
                                          //     right: '-5px',
                                          // }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                          >
                                            <circle
                                              cx="10"
                                              cy="10"
                                              r="10"
                                              fill="green"
                                            />
                                            <path
                                              d="M7 10 L9 12 L14 7"
                                              stroke="white"
                                              stroke-width="2"
                                              fill="transparent"
                                            />
                                          </svg>

                                          {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                        </div>
                                      )}
                                    </h5>
                                    <p className="name">
                                      {item?.doctor_id?.speciality}
                                    </p>
                                    {/* <button className="join-btn">Join the call</button> */}
                                  </div>
                                  <div className="time-block">
                                    <p className="time">
                                      {moment(
                                        item?.timeSlot,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </p>
                                    <span className="date">
                                      {" "}
                                      &nbsp;{" "}
                                      {moment(item?.date).format("MMM DD")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
                {HomeData?.latestCommunity && (
                  <div className="recommanded">
                    <div className="title-block">
                      <h6>Recommended for you</h6>
                      <Link to={"/community"} className="view-all-btn">
                        View all
                      </Link>
                    </div>
                    <div className="suggestion-card">
                      <div className="community-card">
                        <div className="community-card-content">
                          <div
                            className="image-part d-flex justify-content-center align-content-center"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/communityDetails", {
                                state: { comunity: HomeData?.latestCommunity },
                              });
                            }}
                          >
                            {HomeData?.latestCommunity?.community_image && (
                              <img
                                src={
                                  HomeData?.latestCommunity?.community_image
                                    ? HomeData?.latestCommunity?.community_image
                                    : CommunityImg
                                }
                                height={250}
                                width={100}
                                alt="CommunityImg"
                              />
                            )}
                            {/* <img src={HomeData?.latestCommunity?.community_image ? HomeData?.latestCommunity?.community_image : CommunityImg} height={250} width={100} alt='CommunityImg' /> */}
                          </div>
                          <div className="text-part">
                            <div
                              className="img-date-part"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/communityDetails", {
                                  state: {
                                    comunity: HomeData?.latestCommunity,
                                  },
                                });
                              }}
                            >
                              <div className="prodile-img-name">
                                <img
                                  src={
                                    HomeData?.latestCommunity?.posted_By
                                      ?.profile_image
                                      ? HomeData?.latestCommunity?.posted_By
                                          ?.profile_image
                                      : HomeData?.latestCommunity?.posted_By
                                          ?.gender === "female"
                                      ? DoctorFeMale
                                      : HomeData?.latestCommunity?.posted_By
                                          ?.gender === "male"
                                      ? DoctorMale
                                      : DoctorCommon
                                  }
                                  style={{ borderRadius: "50%", width: "30px" }}
                                  height={30}
                                  width={30}
                                />
                                <p>
                                  {HomeData?.latestCommunity?.posted_By?.name}
                                </p>
                              </div>
                              <p className="gray">
                                {" "}
                                {moment(
                                  HomeData?.latestCommunity?.created_at
                                ).format("MMM DD YYYY")}{" "}
                              </p>
                            </div>
                            <h6
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/communityDetails", {
                                  state: {
                                    comunity: HomeData?.latestCommunity,
                                  },
                                });
                              }}
                            >
                              {HomeData?.latestCommunity?.title}
                            </h6>

                            {!HomeData?.latestCommunity?.community_image && (
                              <>
                                <p
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      HomeData?.latestCommunity?.description
                                        ?.split(/\s+/)
                                        .slice(0, 50)
                                        .join(" ") +
                                      (HomeData?.latestCommunity?.description?.split(
                                        /\s+/
                                      ).length > 50
                                        ? "..."
                                        : ""),
                                  }}
                                />
                                {/* {item?.description?.split(/\s+/).length > 50 && ( */}
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#23bee3",
                                    fontWeight: "500",
                                    "font-size": "12px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/communityDetails", {
                                      state: {
                                        comunity: HomeData?.latestCommunity,
                                      },
                                    });
                                  }}
                                >
                                  Read More
                                </span>
                                {/* )} */}
                              </>
                            )}
                            <div className="like-comment-data">
                              <div className="like-comment-text ms-1">
                                <p className="gray">
                                  {HomeData?.latestCommunity?.commentCount}{" "}
                                  Comments
                                </p>
                                <p
                                  className="gray ms-3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      Number(
                                        HomeData?.latestCommunity?.likeCount
                                      ) > 0
                                    ) {
                                      // setSelectedCommunity(HomeData?.latestCommunity?._id)
                                      // handleShow()
                                      // setselectedCommunityLikeCount(HomeData?.latestCommunity?.likeCount)
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {HomeData?.latestCommunity?.likeCount} Likes
                                </p>
                              </div>
                              <div className="like-commnet-icon">
                                {HomeData?.latestCommunity?.likeByYou ===
                                true ? (
                                  <img
                                    src={LikeCommentFill}
                                    alt="chat"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      likeCommunity(
                                        HomeData?.latestCommunity?._id,
                                        "unlike"
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={LikeComment}
                                    alt="chat"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      likeCommunity(
                                        HomeData?.latestCommunity?._id
                                      );
                                    }}
                                  />
                                )}
                                <img
                                  src={Comment}
                                  alt="chat"
                                  className="ms-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/communityDetails", {
                                      state: {
                                        comunity: HomeData?.latestCommunity,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={closeModal}
        className="modalHeader"
        centered
      >
        <Modal.Header
          closeButton
          className="bg-light py-4 px-2 m-0"
          style={{ display: "inline-grid", textAlign: "left" }}
        >
          <span style={{ fontSize: "14px" }}>
            How was your experience about Physiotherapist?
          </span>
          <span style={{ fontSize: "12px" }}>
            Share Your Exeperience about physiotherapist
          </span>
        </Modal.Header>
        <Modal.Body style={{ border: "none" }}>
          <div className="wrapper px-5">
            <div
              className="px-5"
              style={{ display: "inline-grid", textAlign: "left" }}
            >
              <div className="rating-component">
                <div className="stars-box">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <i
                      key={value}
                      className={`star fa fa-star${
                        value <= selectedRating ? " filled" : ""
                      }`}
                      title={`${value} star`}
                      onClick={() => handleStarClick(value)}
                    ></i>
                  ))}
                </div>
              </div>
              <div className="filed">
                <label style={{ fontSize: "12px" }}>Add Your Review</label>
                <br />
                <textarea
                  className="form-control"
                  value={selectedPhysioDetail?.comment}
                  style={{ borderRadius: "3%", fontSize: "12px" }}
                  cols={45}
                  rows={5}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedPhysioDetail((p) => ({
                      ...p,
                      comment: e.target?.value,
                    }));
                  }}
                />
              </div>

              <div className="action-part mt-2">
                <button
                  className="btn btn-them w-25"
                  style={{ fontSize: "12px" }}
                  disabled={!selectedRating}
                  onClick={(e) => {
                    e.preventDefault();
                    addRating();
                  }}
                >
                  Done{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={completeWorkout}
        onHide={() => setCompleteWorkout(false)}
        size="md"
        className="complete-modal"
      >
        <Modal.Body>
          <div className="top-heading">
            <button
              className="btn btn-transpatent"
              onClick={() => setCompleteWorkout(false)}
            >
              X
            </button>
          </div>
          <div className="content-block">
            <img src={doneImg} alt="modal-video" />
          </div>
          <div className="bottom-block">
            <div className="workout-desc">
              <span className="workout-reps">Well Done!</span>
              <p>Review Submitted </p>
              <span className="modal-text mt-2">
                Thanks for sharing your feedback
              </span>
              <button
                className="primary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setCompleteWorkout(false);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
