import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/profile.css";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import BackIcon from "../../assets/images/left.svg";
import profileUpload from "../../assets/images/pic-upload.svg";
import UploadIcon from "../../assets/images/upload.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deletes,
  get,
  getAuthConfig,
  getAuthConfigImage,
  post,
} from "../../libs/http-hydrate";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Auth from "../../libs/auth";
import axios from "axios";
import FieldValidationError from "../../components/common/FieldValidation";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import GeoLocation from "../../components/common/GeoLocation";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutoComplete from "../AutoComplete";

const Profile = () => {
  const [page , setPage] = useState(0)
  const [selected, setSelected] = useState(null);
  const user = Auth.getCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  let [city, setCity] = useState("");
  const [activeEvent, setActiveEvent] = useState("Personal");
  const [imagePreview, setImagePreview] = useState(null);
  const [locations, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [reportPreview, setReportPreview] = useState(null);

  const [headerData, setHeaderData] = useState({});
  const [formData, setFormData] = useState({
    profile_image: null,
    name: "",
    phone: "",
    email: "",
    dob: "",
    maritalStatus: "",
    height: "",
    weight: "",
    location: "",
    allergies: 0,
    currentMedications: 0,
    pastMedications: 0,
    chronicDesease: 0,
    injuries: 0,
    surgeries: 0,
    surgeryReport: null,
    smokingHabits: 0,
    alcoholConsumption: 0,
    activityLevel: "",
    foodPreference: "",
    occupation: "",
    password: "",
    confirmPassword: "",
    country: "",
    state: "",
    street: "",
    city: "",
    pincode: "",
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDgERv1vmBkeZ0zWVMFRUksVmYao3v5vms',
    libraries: ["places"],
  });
  const [DuplicatePhysio, setDuplicatePhysio] = useState("");
  const [error, setError] = useState({
    name: false,
    phone: false,
    email: false,
    dob: false,
  });
  const name = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);
  const dob = useRef(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (user?.email) {
      setFormData((p) => ({ ...p, email: user?.email }));
    }
    if (user?.mobile_number) {
      setFormData((p) => ({ ...p, phone: user?.mobile_number }));
    }
    if (location?.state?.name) {
      setFormData((p) => ({ ...p, name: location?.state?.name }));
    }
    if (location?.state?.phone) {
      setFormData((p) => ({ ...p, phone: location?.state?.phone }));
    }
  }, []);

  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setFormData((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setFormData((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }

  // Helper function to update form data
  const updateFormData = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Event handler for input changes
  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;

    // Handle image file selection
    if (type === "file" && event.target.files[0] && name === "profile_image") {
      const selectedImage = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result); // Set the image preview URL
      };

      reader.readAsDataURL(selectedImage);
      updateFormData(name, selectedImage); // Update formData with the selected image
    } else if (type === "file" && files[0] && name === "report") {
      const selectedFile = files[0];
      const fileType = selectedFile.type;

      // Check file type and generate preview
      if (fileType.startsWith("image/")) {
        // Preview for image files
        const reader = new FileReader();
        reader.onload = (e) => setReportPreview(e.target.result);
        reader.readAsDataURL(selectedFile);
      } else if (fileType === "application/pdf") {
        // Preview for PDF files
        setReportPreview(URL.createObjectURL(selectedFile));
      } else if (
        fileType === "application/msword" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        // Preview for Word documents (DOC and DOCX)
        setReportPreview(URL.createObjectURL(selectedFile));
      } else {
        // Reset preview for unsupported file types
        setReportPreview(null);
      }

      // Update formData with the selected file
      updateFormData(name, selectedFile);
    } else {
      updateFormData(name, value);
    }
  };
  useEffect(() => {
    return () => {
      if (
        reportPreview &&
        (reportPreview.endsWith(".pdf") ||
          reportPreview.endsWith(".doc") ||
          reportPreview.endsWith(".docx"))
      ) {
        URL.revokeObjectURL(reportPreview);
      }
    };
  }, [reportPreview]);

  async function saveDetails() {
    const formDataToSend = new FormData();
    // Append all the fields from the formData object to formDataToSend
    for (const key in formData) {
      let value = formData[key];
      if (value === null) continue;
      // Skip appending if value is undefined
      if (value === undefined) {
        continue;
      }
      if (key === "phone") {
        if (value === "+") {
          // Skip appending if phone number is just "+"
          continue;
        } else if (value && !value.startsWith("+")) {
          // Add "+" before the phone number if not present and value is not empty
          value = "+" + value;
        }
      }

      formDataToSend.append(key, value);
    }
    if (locations?.longitude) {
      formDataToSend.append("longitude", locations?.longitude);
      formDataToSend.append("latitude", locations?.latitude);
    }
    try {
      setLoading(true);
      const response = await post(
        "/patient",
        formDataToSend,
        getAuthConfigImage()
      );
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: response?.data?.data?.email,
            mobile_number: response?.data?.data?.phone,
            token: response?.data?.data?.token,
            profile: response?.data?.data?.profile_image,
            address: response?.data?.data?.address,
            loc: response?.data?.data?.loc,
            gender: response?.data?.data?.gender,
            name:response?.data?.data?.name
          })
        );
        // Delay navigation by 2 seconds
        setTimeout(() => {
          setLoading(false);
          navigate("/dashboard");
        }, 2000);
        // navigate("/dashboard")
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.err);
    }
  }

  const checkForExistingPatient = async (isEmail) => {
    try {
      const formdata = new FormData();
      if (formData?.phone && formData.phone !== "+") {
        formdata.append(
          "phone",
          formData.phone.startsWith("+") ? formData.phone : "+" + formData.phone
        );
      } else {
        formdata.append("phone", "");
      }
      if (formData?.email) {
        formdata.append("email", formData.email);
      }
      const response = await post(
        "/checkForPatient",
        formdata,
        getAuthConfig()
      );

      if (response?.status === 200) {
        setActiveEvent("Medical");
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        setDuplicatePhysio(error?.response?.data?.msg);
        if (error?.response?.data?.msg === "Email already exists") {
          setError((p) => ({ ...p, email: true }));
          handleFieldError(email);

          return;
        }
        if (error?.response?.data?.msg === "Phone already exists") {
          setError((p) => ({ ...p, phone: true }));
          handleFieldError(phone);

          return;
        }
        // toast.error("Patient Alreaday Exist")
      }
      console.error("Error checking for existing patient:", error);
    }
  };
  // Event handler for clicking the "Continue" button
  const handleContinue = () => {
    if (activeEvent === "Personal") {
      // Add validation for the "Name", "Date of Birth", and "Contact Number" fields
      if (!formData?.name?.trim()) {
        setError((p) => ({ ...p, name: true }));
        handleFieldError(name);
        // toast.error("Name is required.");
        return;
      }
      if (!formData?.dob?.trim()) {
        setError((p) => ({ ...p, dob: true }));
        handleFieldError(dob);

        return;
      }
      // if (!formData?.phone?.trim()) {
      //   setError((p) => ({ ...p, phone: true }))
      //   handleFieldError(phone)

      //   return;
      // }

      if (formData?.email) {
        // Simple email validation using regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(formData?.email)) {
          setError((p) => ({ ...p, email: true }));
          handleFieldError(email);

          return;
        }
      }
      // if (formData?.phone?.trim() === "") {
      //   setError((p) => ({ ...p, phone: true }))
      //   handleFieldError(phone)

      //   return;
      // }
      checkForExistingPatient();

      // else{
      //   setActiveEvent("Medical");
      // }
    } else if (activeEvent === "Medical") {
      setActiveEvent("Lifestyle");
    } else if (activeEvent === "Lifestyle") {
      saveDetails();
    }
  };
  useEffect(() => {
    // Function to get the current location
    const getCurrentLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position,"positionFromtheLOcation")
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            getAddressFromCoordinates(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not available in this browser.");
      }
    };
    if (!typeof formData?.address === "object" || !formData?.address) {
      getCurrentLocation();
    }
  }, []);
  UseEffectOnce(() => {
    getHomeData();
    if (!Auth.getCurrentUser()) {
      navigate("/login");
    }
  }, []);
  const getAddressFromCoordinates = async (latitude, longitude) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;

    try {
      const response = await axios.get(nominatimUrl, {
        headers: {
          "User-Agent": "YourAppName",
        },
      });

      const addressData = response.data;
      if (addressData && addressData.display_name) {
        const { city, state, state_district, country, postcode } =
          addressData.address;
        const displayParts = addressData.display_name.split(",");

        console.log( addressData,"addressDataaddressDataaddressDataaddressDataaddressData")
        // Filter out the parts that contain state, city, and postcode information
        const filteredParts = displayParts.filter((part) => {
          return ![state, city, postcode, state_district, country].includes(
            part.trim()
          );
        });

        const street = filteredParts.join(",").trim();
        setFormData((p) => ({
          ...p,
          city: city || state_district || "",
          state: state || "",
          country: country || "",
          street: street,
          pincode: postcode || "",
        }));
      } else {
        // setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      // inputField.classList.add('field-error'); // Add the error class to the input field
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");

      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  async function getHomeData() {
    try {
      const data = await get("/patient", getAuthConfig());
      if (data?.status === 200) {
        setHeaderData(data?.data?.patient);

        let modifiedData = { ...data?.data?.patient };
        if (modifiedData.address) {
          modifiedData.country = data?.data?.patient?.address?.country;
          modifiedData.state = data?.data?.patient?.address?.state;
          modifiedData.city = data?.data?.patient?.address?.city;
          modifiedData.street = data?.data?.patient?.address?.street;
          modifiedData.pincode = data?.data?.patient?.address?.pincode;
        }

        modifiedData.maritalStatus = data?.data?.patient?.MaritalStatus;
        modifiedData.operationDate = moment(
          data?.data?.patient?.operationDate
        ).format("YYYY-MM-DD");

        setFormData(modifiedData);
        //         if (data?.data?.patient?.name) {
        //           setFormData((p) => ({ ...p, name: data?.data?.patient?.name }))
        //         }

        if (data?.data?.patient?.report) {
          const reportPreviews = data?.data?.patient?.report.map(
            (reportUrl) => ({
              file: null, // Since we're fetching URLs, there's no file object here
              previewUrl: reportUrl, // Directly using the URL from the server
            })
          );
          setReports(reportPreviews);
        }

        if (data?.data?.patient?.dob) {
          const date = new Date(data?.data?.patient?.dob);

          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Note: Months are 0-based, so we add 1.
          const year = date.getFullYear();

          const formattedDate = `${year}-${month}-${day}`;
          setFormData((p) => ({ ...p, dob: formattedDate }));
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  const deleteProfileImage = async () => {
    try {
      // Make API call to delete profile image
      const response = await deletes("/profileImage");

      if (response.status === 200) {
        // Update profile image state or reset it based on your application logic
        setFormData({ ...formData, profile_image: "" });
        setImagePreview("");
      } else {
        console.error("Failed to delete profile image");
      }
    } catch (error) {
      console.error("Error deleting profile image:", error);
    }
  };
  const deleteReport = async (reportUrl) => {
    try {
      setLoading(true); // Optional: show loading state

      // Assuming deletes is a function from your http-hydrate lib that sends DELETE requests
      const response = await deletes(`/deleteReport`, { reportUrl: reportUrl });

      if (response.status === 200) {
        // Update your local state to remove the deleted report
        setReports(reports.filter((report) => report.previewUrl !== reportUrl));
        //toast.success("Report deleted successfully");
      } else {
        toast.error("Failed to delete report");
      }
    } catch (error) {
      console.error("Error deleting report:", error);
      toast.error("An error occurred while deleting the report");
    } finally {
      setLoading(false); // Optional: hide loading state
    }
  };
  // Define the function to delete the preview
const deleteReportPreview = () => {
  setReportPreview(null); // Assuming setReportPreview is your state setter for reportPreview
  // If reportPreview is part of an array or needs to be deleted from a backend,
  // include those operations here.
  setFormData((p) =>({...p,report:null}))
};

  return (
    <>
      <ToastContainer />
      <div className="profile-main">
        <Tab.Container activeKey={activeEvent}>
          <div className="header-part">
            <div className="left-part">
              <button
                className="back btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (activeEvent === "Medical") {
                    setActiveEvent("Personal");
                  } else if (activeEvent === "Lifestyle") {
                    setActiveEvent("Medical");
                  } else {
                    navigate("/dashboard");
                  }
                }}
              >
                <img src={BackIcon} alt="btn-icon" />
              </button>

              <h2>Your Health Profile</h2>
            </div>
            <div className="right-part">
              <Nav
                as="ul"
                className="nav-pills light w-100 justify-content-between"
              >
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="Personal"
                    className="tab-btn"
                    as="button"
                    onClick={() => setActiveEvent("Personal")}
                  >
                    Personal
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="Medical"
                    as="button"
                    onClick={() => setActiveEvent("Medical")}
                  >
                    Medical
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="Lifestyle"
                    as="button"
                    onClick={() => setActiveEvent("Lifestyle")}
                  >
                    Lifestyle
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="Personal">
              <div className="form-data full-width-data">
                <Row className="g-4">
                  <Col lg="6">
                    <div className="filed">
                      <label htmlFor="profile-img" className="upload">
                        <div className="profile-pic">
                          {imagePreview ? (
                            <>
                              <img
                                src={imagePreview}
                                alt="Profile Preview"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "55%",
                                }}
                              />

                              <button
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    profile_image: "",
                                  });
                                  setImagePreview("");
                                }}
                                className="delete-profile-image-button"
                              >
                                <i
                                  class="fa-solid fa-xmark "
                                  style={{ color: "white" }}
                                ></i>{" "}
                              </button>
                            </>
                          ) : formData.profile_image ? (
                            <>
                              <img
                                src={formData.profile_image}
                                alt="Profile Preview"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "55%",
                                }}
                              />
                              <button
                                onClick={deleteProfileImage}
                                className="delete-profile-image-button"
                              >
                                <i
                                  class="fa-solid fa-xmark "
                                  style={{ color: "white" }}
                                ></i>{" "}
                              </button>
                            </>
                          ) : (
                            <img
                              src={profileUpload}
                              alt="Profile Image"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "55%",
                              }}
                            />
                          )}
                          {/* <img src={profileUpload} alt="camera" /> */}
                          <p>Profile Picture</p>
                        </div>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="profile-img"
                        name="profile_image"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="filed" ref={name}>
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`input-field form-control ${
                          error?.name ? "field-error" : ""
                        }`}
                        name="name"
                        value={formData.name}
                        onChange={(e) => {
                          handleInputChange(e);
                          setError((p) => ({ ...p, name: false }));
                        }}
                      />
                      {error?.name ? (
                        <FieldValidationError message="Please enter name" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="filed" ref={phone}>
                      <label>
                        Contact Number{" "}
                        {user?.mobile_number ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ""
                        )}
                      </label>

                      <PhoneInput
                        country={"in"}
                        className={` ${error?.phone ? "field-error" : ""}`}
                        name="contactNumber"
                        value={formData?.phone}
                        onChange={(phone, country) => {
                          setFormData((p) => ({ ...p, phone: phone }));
                          setError((p) => ({ ...p, phone: false }));
                          setDuplicatePhysio("");
                        }}
                        disabled={
                          user?.mobile_number && user?.mobile_number !== "+"
                        }

                        // disabled={!user?.mobile_number && user?.mobile_number === "+"}
                      />
                    </div>
                    {!DuplicatePhysio && error?.phone ? (
                      <FieldValidationError message="Please enter valid contact number" />
                    ) : (
                      ""
                    )}

                    <div className="filed" ref={email}>
                      <label>
                        Email{" "}
                        {user?.email ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        type="email"
                        className={`input-field ${
                          error?.email ? "field-error" : ""
                        }`}
                        name="email"
                        value={formData.email}
                        onChange={(e) => {
                          handleInputChange(e);
                          setError((p) => ({ ...p, email: false }));
                          setDuplicatePhysio("");
                        }}
                        disabled={user?.email}
                      />
                      {!DuplicatePhysio && error?.email ? (
                        <FieldValidationError message="Please enter valid email" />
                      ) : (
                        ""
                      )}
                    </div>
                    {DuplicatePhysio && (
                      <FieldValidationError message={DuplicatePhysio} />
                    )}

<div className="filed">
                <label>Gender  <span className="text-danger"> </span></label>
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={handleInputChange}
                  value={formData?.gender || ""}
                  className={`form-control form-select`}

                >
                  <option value="">Please select Gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="non-binary">Non-Binary</option>
                  <option value="other">Other</option>
                  <option value="Prefer not to answer">Perfer not to Answer</option>
                </Form.Select>

              </div>
                  </Col>
                  <Col lg="6">
                    <div className="filed" ref={dob}>
                      <label>
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]} // Set the max attribute to today's date
                        className={`form-control ${
                          error?.dob ? "field-error" : ""
                        }`}
                        name="dob"
                        value={formData.dob}
                        onClick={(e) =>  {
                          if (e.target.showPicker) {
                            e.target.showPicker();
                          }                  
                        }}
       onChange={(e) => {
                          handleInputChange(e);
                          setError((p) => ({ ...p, dob: false }));
                        }}
                      />
                      {error?.dob ? (
                        <FieldValidationError message="Please enter Date of birth" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="filed">
                      <label>Martial Status</label>

                      <select
                        className="form-control form-control"
                        name="MaritalStatus"
                        value={formData.MaritalStatus}
                        onChange={handleInputChange}
                      >
                        <option value={""}>Select Martial Status</option>
                        <option value={"Unmarried"}>Unmarried</option>
                        <option value={"Married"}>Married</option>
                        <option value={"Widowed"}>Widowed</option>
                        <option value={"Divorced"}>Divorced</option>
                        <option value={"Separated"}>Separated</option>
                        <option value={null}>Don't want to say</option>
                      </select>

                      {/* 
                      <input
                        type="text"
                        className="input-field form-control"
                        name="maritalStatus"
                        value={formData.maritalStatus}
                        onChange={handleInputChange}
                      /> */}
                    </div>
                    <div className="filed">
                      <label>Height</label>
                      <div className="d-flex">
                        {formData.height_unit === "feet/inches" ? (
                          <div className="d-flex w-100">
                            <select
                              className="form-control w-100"
                              name="height_feet"
                              value={formData.height_feet}
                              onChange={handleInputChange}
                            >
                              <option>Feet</option>
                              {/* Generate options for feet */}
                              {Array.from(
                                { length: 8 },
                                (_, index) => index
                              ).map((feet) => (
                                <option key={feet} value={feet}>
                                  {feet}
                                </option>
                              ))}
                            </select>
                            <select
                              className="form-control w-100"
                              name="height_inches"
                              value={formData.height_inches}
                              onChange={handleInputChange}
                            >
                              <option>Inches</option>
                              {/* Generate options for inches */}
                              {Array.from(
                                { length: 12 },
                                (_, index) => index
                              ).map((inch) => (
                                <option key={inch} value={inch}>
                                  {inch}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <select
                            className="form-control form-select"
                            name="height"
                            value={formData.height}
                            onChange={handleInputChange}
                          >
                            <option>Height in centimeters</option>
                            {/* Generate options for centimeters */}
                            {Array.from(
                              { length: 271 },
                              (_, index) => index + 60
                            ).map((cm) => (
                              <option key={cm} value={cm}>
                                {cm}
                              </option>
                            ))}
                          </select>
                        )}

                        <select
                          className="form-control "
                          name="height_unit"
                          value={formData.height_unit}
                          disabled={true}
                          onChange={handleInputChange}
                        >
                          <option value="cm">cm</option>
                          <option value="feet/inches">feet / inches</option>
                        </select>
                      </div>
                    </div>

                    <div className="filed">
                      <label>Weight</label>
                      <div className="d-flex">
                        {formData?.weight_unit === "kg" ? (
                          <select
                            className="form-control"
                            name="weight"
                            value={formData.weight}
                            onChange={handleInputChange}
                          >
                            <option> Select Weight</option>
                            {Array.from(
                              { length: 318 },
                              (_, index) => index + 10
                            ).map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <select
                            className="form-control"
                            name="weight"
                            value={formData.weight}
                            onChange={handleInputChange}
                          >
                            <option> Select Weight</option>
                            {Array.from(
                              { length: 702 },
                              (_, index) => index + 30
                            ).map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        )}

                        {/* <input
                          type="text"
                          className="input-field form-control"
                          name="weight"
                          value={formData.weight}
                          onChange={handleInputChange}
                        /> */}
                        <select
                          className="form-control"
                          name="weight_unit"
                          value={formData.weight_unit}
                          onChange={handleInputChange}
                        >
                          <option value="lb">lb</option>
                          <option value="kg">kg</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="filed">
                      <label>Location</label>
                      <input
                        type="text"
                        className="input-field form-control"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                      />
                    </div> */}

                    <div className="filed">
                      <Row>
                        <Col lg="6" className="">
                          <div className="filed">
                            <label>Address </label>

                            <PlacesAutoComplete
                          data={formData}
                          setFormData={setFormData}
                          selected={selected}
                          setSelected={setSelected}
                          isLoaded={isLoaded}
                        />
                          </div>
                        </Col>
                        <Col lg="6" className="">
                          <div className="filed">
                            <label>Country </label>

                            <select
                              className="form-control form-select"
                              value={country}
                              onChange={(e) => {
                                handleCountryChange(e);
                                const selectedCountry = countries.find(
                                  (c) => c.isoCode === e.target.value
                                );
                                if (selectedCountry) {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    country: selectedCountry.name,
                                  }));
                                }
                              }}
                              onClick={(e) => {
                                getCountrie();
                              }}
                            >
                              <option value="">
                                {formData?.country
                                  ? formData?.country
                                  : "Select Country"}
                              </option>
                              {countryLoading ? (
                                <option disabled>Loading...</option>
                              ) : (
                                countries.map((country) => (
                                  <option
                                    key={country?.code}
                                    value={country?.isoCode}
                                  >
                                    {country?.name}
                                  </option>
                                ))
                              )}
                            </select>
                            {/* <GeoLocation
                        locationTitle="Country"
                        isCountry
                        name="country" value={formData?.country} onChange={(e) => {
                          setFormData((p) => ({ ...p, country: e?.label }))
                       
                          setCountry(e?.value)
                        }}
                      /> */}
                          </div>
                        </Col>
                        <Col lg="6" className="">
                          <div className="filed">
                            <label>State </label>
                            <select
                              className="form-control form-select "
                              value={state}
                              onChange={(e) => {
                                handleStateChange(e);
                                const selectedStates = states.find(
                                  (c) => c.isoCode === e.target.value
                                );
                                if (selectedStates) {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    state: selectedStates.name,
                                  }));
                                }
                              }}
                            >
                              <option value="">
                                {formData?.state
                                  ? formData?.state
                                  : "Select State"}
                              </option>
                              {stateLoading ? (
                                <option disabled>Loading...</option>
                              ) : (
                                states.map((state) => (
                                  <option
                                    key={state?.code}
                                    value={state?.isoCode}
                                  >
                                    {state?.name}
                                  </option>
                                ))
                              )}
                            </select>
                            {/* <GeoLocation
                        locationTitle="State"
                        geoId={country}
                        name="country" value={formData?.country} onChange={(e) => {
                          setFormData((p) => ({ ...p, state: e?.label }))
                     
                          setstate(e?.value)
                        }}
                      /> */}
                          </div>
                        </Col>
                        <Col lg="6" className="">
                          <div className="filed">
                            <label>City </label>

                            <select
                              className="form-control form-select"
                              value={formData?.city}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  city: e?.target?.value,
                                }));
                              }}
                            >
                              <option value="">
                                {formData?.city
                                  ? formData?.city
                                  : "Select City"}
                              </option>

                              {cityLoading ? (
                                <option disabled>Loading...</option>
                              ) : (
                                cities.map((city) => (
                                  <option key={city?.code} value={city?.code}>
                                    {city?.name}
                                  </option>
                                ))
                              )}
                            </select>
                            {/* <GeoLocation
                        locationTitle="City"

                        onChange={(e) => {
                          console.log(e, "wuirueirerei")
                          setFormData((p) => ({ ...p, city: e?.label }))
                        
                          // setData((p) =>({...p,city:e?.lable}))
                        }}
                        geoId={state}
                      /> */}
                          </div>
                        </Col>
                        <Col lg="6" className="">
                          <div className="filed">
                            <label>Postal Code</label>

                            <input
                              type="number"
                              className="input-field form-control"
                              name="pincode"
                              onChange={handleInputChange}
                              value={formData?.pincode || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div className="buttonPart mt-5 d-flex justify-content-center">
                  <Button className="theme-button" onClick={handleContinue}>
                    Continue
                  </Button>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Medical">
              <div className="form-data full-width-data">
                <Row className="g-4">
                  <Col lg="6">
                    <div className="filed">
                      <label>Allergies </label>

                      <select
                        className="input-field form-control form-control"
                        name="allergies"
                        value={formData.allergies}
                        onChange={handleInputChange}
                      >
                        <option>Select allergies</option>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Current Medications</label>

                      <select
                        className="input-field form-control form-control"
                        name="currentMedications"
                        value={formData.currentMedications}
                        onChange={handleInputChange}
                      >
                        <option>Select Medications</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Past Medications</label>

                      <select
                        className="input-field form-control form-control"
                        name="postMedications"
                        value={formData.postMedications}
                        onChange={handleInputChange}
                      >
                        <option>Select Medications</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Chronic Diseases</label>

                      <select
                        className="input-field form-control form-control"
                        name="chronicDesease"
                        value={formData.chronicDesease}
                        onChange={handleInputChange}
                      >
                        <option>Select Chronic Diseases</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>

                    <div className="filed">
                      <label>Injuries</label>

                      <select
                        className="input-field form-control form-control"
                        name="injuries"
                        value={formData.injuries}
                        onChange={handleInputChange}
                      >
                        <option>Select Injuries</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="filed">
                      <label>Injury Info</label>

                      <input
                        type="text"
                        className="input-field form-control"
                        name="injuryInfo"
                        onChange={handleInputChange}
                        value={formData?.injuryInfo || ""}
                      />
                    </div>
                    <div className="filed">
                      <label>Surgeries</label>
                      <select
                        className="input-field form-control form-control"
                        name="surgeries"
                        value={formData.surgeries}
                        onChange={handleInputChange}
                      >
                        <option>Select Surgeries</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Surgery Date</label>
                      <input
                        type="date"
                        name="operationDate"
                        max={new Date().toISOString().split("T")[0]} // Set the max attribute to today's date
                        className="input-field"
                        value={formData?.operationDate}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="filed">
                      <label>Surgery Report</label>
                      <div className="upload-part mt-2">
                        <div className="form-group file-area ">
                          <input
                            type="file"
                            name="report"
                            id="images"
                            required="required"
                            accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf"
                            onChange={handleInputChange}
                          />
                          <div className="file-dummy">
                            <div className="success">
                              Great, your files are selected. Keep on.
                            </div>

                            <div className="default">
                              {/* Display report preview */}
                              <div className="image-block mb-2">
                                <img src={UploadIcon} alt="icon" />
                              </div>
                              Add report here (png, jpg, pdf)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filled">
                      {reportPreview && (
                        <>
                          <div
                            className="report-preview"
                            style={{ position: 'relative', width: '100%', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                            {reportPreview.endsWith(".pdf") ? (
                              <iframe
                                src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                  reportPreview
                                )}&embedded=true`}
                                title="Report Preview"
                                style={{ width: "100%", height: "300px" }}
                                frameBorder="0"
                              ></iframe>
                            ) : reportPreview.startsWith("blob:") ? (
                              <iframe
                                src={reportPreview}
                                title="Report Preview"
                                style={{ width: "100%", height: "300px" }}
                              ></iframe>
                            ) : (
                              <img
                                src={reportPreview}
                                alt="Report Preview"
                                style={{ maxWidth: "100%", maxHeight: "300px" }}
                              />
                            )}
                                 <button
                            className="btn border-0"
                            onClick={deleteReportPreview}
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              backgroundColor: 'red',
                              color: 'white',
                              border: 'none',
                              cursor: 'pointer',
                              padding: '5px 10px',
                              borderRadius: '5px'
                            }}                    
                          >
                            <i className="fa-solid fa-xmark"></i> 
                          </button>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="filled">
                      {reports.map((report, index) => (
                        <div key={index} className="report-preview"
                          style={{ position: 'relative', width: '100%', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {/* Determine how to display based on file type, here assuming images or PDFs */}
                          {report.previewUrl.endsWith(".pdf") ? (
                            <iframe
                              src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                report.previewUrl
                              )}&embedded=true`}
                              title="Report Preview"
                              style={{ width: "100%", height: "300px" }}
                              frameBorder="0"
                            ></iframe>
                          ) : (
                            <img
                              src={report.previewUrl}
                              alt={`Report Preview ${index + 1}`}
                              style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                          )}

                          <button
                            className="btn border-0"
                            onClick={() => deleteReport(report.previewUrl)}
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              backgroundColor: 'red',
                              color: 'white',
                              border: 'none',
                              cursor: 'pointer',
                              padding: '5px 10px',
                              borderRadius: '5px'
                            }}                    
                          >
                            <i className="fa-solid fa-xmark"></i> 
                          </button>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className="buttonPart mt-5 d-flex justify-content-center">
                  <Button className="theme-button" onClick={handleContinue}>
                    Continue
                  </Button>
                </div>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="Lifestyle">
              <div className="form-data full-width-data">
                <Row className="g-4">
                  <Col lg="6">
                    <div className="filed">
                      <label>Smoking Habits</label>

                      <select
                        className="input-field form-control form-control"
                        name="smoke"
                        value={formData.smoke}
                        onChange={handleInputChange}
                      >
                        <option selected>Select Smoking Habits</option>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Alcohol consumption</label>

                      <select
                        className="input-field form-control form-control"
                        name="alcoholConsumption"
                        value={formData.alcoholConsumption}
                        onChange={handleInputChange}
                      >
                        <option selected>Select alcohol consumption</option>

                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Activity Level</label>

                      <select
                        className="input-field form-control form-control"
                        name="activeLevel"
                        value={formData.activeLevel}
                        onChange={handleInputChange}
                      >
                        <option selected>Select activity level</option>

                        <option value={"Active"}>active</option>
                        <option value={"Sedentary"}>sedentary</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Food Preference</label>

                      <select
                        className="input-field form-control form-control"
                        name="foodPrefer"
                        value={formData.foodPrefer}
                        onChange={handleInputChange}
                      >
                        <option selected>Select food preference</option>

                        <option value={"Standard"}>standard</option>
                        <option value={"Vegetarian"}>vegetarian</option>
                        <option value={"Vegan"}>vegan</option>
                        <option value={"Non Vegetarian"}>non vegetarian</option>
                      </select>
                    </div>
                    <div className="filed">
                      <label>Occupation</label>
                      <input
                        type="text"
                        className="input-field form-control"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="buttonPart mt-5 d-flex justify-content-center">
                  <button
                    disabled={loading}
                    className="theme-button btn"
                    style={{ color: "white" }}
                    onClick={handleContinue}
                  >
                    {"Continue"}
                  </button>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default Profile;
