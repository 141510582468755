import React from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import axios from "axios";

export default function Places({ data,setPage, setFormData, handleCloseSidebar, setSelected, selected, isLoaded, country }) {
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete dataFrom={data} setPage={setPage} setFormData={setFormData} handleCloseSidebar={handleCloseSidebar} setSelected={setSelected} country={country} />
      </div>
    </>
  );
}

const PlacesAutocomplete = ({ dataFrom, setPage, setFormData, handleCloseSidebar, setSelected, country }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: country },
    },
    debounce: 300,
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    console.log(address, "address");
  
    try {
      const results = await getGeocode({ address });
      const addressComponents = results[0].address_components;
      let lat = null;
      let lng = null;
  
      // Check if the address has street-level details
      const hasStreetDetails = addressComponents.some(component =>
        ["street_number", "route"].includes(component.types[0])
      );
  
      if (hasStreetDetails) {
        const location = await getLatLng(results[0]);
        lat = location.lat;
        lng = location.lng;
      }
  
      let street = "", city = "", state = "", country = "", postalCode = "";
  
      for (const component of addressComponents) {
        const componentType = component.types[0];
  
        switch (componentType) {
          case "street_number":
            street = `${component.long_name} ${street}`;
            break;
          case "route":
            street += component.long_name;
            break;
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            state = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
          case "postal_code":
            postalCode = component.long_name;
            break;
        }
      }
  
      setFormData((p) => ({
        ...p,
        city: city || "",
        state: state || "",
        country: country || "",
        pincode: postalCode || "",
        street: address || ""
      }));
  
      if (lat && lng) {
        setSelected({ lat, lng, address });
      } else {
        setSelected({ address });
      }
  
      handleCloseSidebar();
      setPage(0);
    } catch (error) {
      console.error("Error: ", error);
      // Handle the error appropriately in your application
    }
  };
  

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input form-control"
        placeholder={dataFrom?.street ? dataFrom?.street : "Search an address"}
      />
      <ComboboxPopover style={{ zIndex: "9999" }} portal={false}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => <ComboboxOption key={place_id} value={description} />)}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
