import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/login.css";
import doctorsImg from "../../assets/images/login-page-banner.svg";
import { AiOutlineClose } from "react-icons/ai";
import { Modal, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TruecalloerLogo from "../../assets/images/Truecaller_Logo.png";
import ReactFlagsSelect from "react-flags-select";
import Auth from "../../libs/auth";
import FieldValidationError from "../../components/common/FieldValidation";
import { post } from "../../libs/http-hydrate";
import firebase from "../../components/common/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logo from "../../assets/images/docpy.png";
import doneImg from "../../assets/images/Opps.svg"; // Import the image

const Login = ({ mobile, setMobile }) => {
  const [isLoading, setLoading] = useState(false); // Add a loading state
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state

  const navigate = useNavigate();
  const mobileInputRef = useRef(null);
  const [show, setShowModal] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [selected, setSelected] = useState("IN");
  const [verificationId, setVerificationId] = useState("");
  const otpInputsRef = useRef([]);
  const [timerSeconds, setTimerSeconds] = useState(15 * 60); // 30 minutes in seconds
  const [isTimerRunning, setTimerRunning] = useState(false);
  const otpInputRefs = useRef([]);
  const closeModal = () => setModalShow(false);
  const [otpError, setOTPError] = useState("");

  const [loginData, setLoginData] = useState({
    mobile_number: "",
  });

  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);

  const [error, setError] = useState(false);
  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef.current.querySelector("input");
    if (inputField) {
      // inputField.classList.add('field-error'); // Add the error class to the input field
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef.current.querySelector("select");
      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  // Function to handle changes in OTP input fields
  const handleOtpInputChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }
    setOTPError("");
  };

  // Function to check if all fields have data and call verifyOTP function
  const handleVerifyOTPs = () => {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (isAllFieldsFilled) {
      const otp = otpDigits.join(""); // Convert the array of digits to a single OTP string
      handleVerifyOTP(otp); // Call your verifyOTP function with the OTP as a parameter
    } else {
      // Handle the case when all fields are not filled
      // Maybe show an error message or take appropriate action
      setOTPError("Please Add Correct OTP");

      // toast.error("Please Add Correct OTP")
    }
  };
  // Function to handle backspace key for removing the digits
  const handleOtpInputKeyDown = (index, event) => {
    if (event.key === "Backspace" && otpDigits[index] === "") {
      // Move focus to the previous input field when backspace is pressed and the current field is empty
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const newOtpDigits = [...otpDigits];
        newOtpDigits[prevIndex] = "";
        setOtpDigits(newOtpDigits);
        const prevInput = document.getElementById(`otp-input-${prevIndex}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const resetTimer = () => {
    setTimerSeconds(15 * 60); // Reset timer to 15 minutes
    setTimerRunning(true); // Start the timer
  };

  const submitOTP = () => {
    navigate("/profile");
  };

  useEffect(() => {
    if (Auth?.getCurrentUser()) {
      navigate("/dashboard");
    }
  }, []);
  useEffect(() => {
    let interval;

    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            // Timer has reached 0, do something here
            setTimerRunning(false);
            // Maybe close the modal or take any necessary action
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [isTimerRunning]);

  const handleSendOTP = () => {
    if (!loginData?.mobile_number) {
      setError(true);
      handleFieldError(mobileInputRef);
      // toast.error("Please provide a mobile number")
      return false;
    } else {
      setError(false);
    }

    // Set loading to true when starting the API call
    setLoading(true);

    // Initialize reCAPTCHA only if it hasn't been initialized yet
    let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible", // This property is important; otherwise, the captcha will be displayed on the screen
        callback: (response) => {
          // reCAPTCHA verification successful
        },
        "expired-callback": (response) => {},
      }
    );
    firebase
      .auth()
      .signInWithPhoneNumber("+" + loginData?.mobile_number, recaptchaVerifier)
      .then((confirmationResult) => {
        localStorage.setItem("MOBILE", loginData?.mobile_number);
        setVerificationId(confirmationResult.verificationId);
        setTimerRunning(true);
        setModalShow(true);
      })
      .catch((error) => {
        setShowModal(true);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completion
      });

    resetTimer();
  };

  const handleVerifyOTP = (otp) => {
    // Set loading to true when starting the API call
    setLoadingVer(true);

    const credential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      otp
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((result) => {
        if (result) {
          verifyOTP(verificationId, result?.user?.uid);
        } else {
        }
      })
      .catch((error) => {
        setOtpDigits(["", "", "", "", "", ""]); // Reset OTP digits
        setOTPError("Please Add a correct otp");
        // error?.response?.message
        // toast.error(error?.t?.message)
      })
      .finally(() => {
        // setOtpDigits(['', '', '', '', '', '']); // Reset OTP digits
        // setLoadingVer(false); // Set loading to false after API call completion
      });
  };

  async function verifyOTP(verificationId, userID) {
    const formdata = new FormData();
    formdata.append("type", "patient");
    formdata.append("mobileNumber", "+" + loginData?.mobile_number);
    formdata.append("device", "Web")

    try {
      const response = await post("/getToken", formdata);
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            _id: response?.data?.patient?._id,
            email: response?.data?.patient?.email,
            mobile_number: response?.data?.patient?.phone,
            token: response?.data?.patient?.token,
            profile: response?.data?.patient?.profile_image,
            address: response?.data?.patient?.address,
            loc: response?.data?.patient?.loc,
            gender: response?.data?.patient?.gender,
            name:response?.data?.patient?.name
          })
        );

        if (response?.data?.is_verified === false) {
          navigate("/profile");
        } else {
          navigate("/dashboard");
        }
      } else {
      }
    } catch (error) {
      toast.error(error?.response?.message);
    } finally {
      setOtpDigits(["", "", "", "", "", ""]); // Reset OTP digits
      setLoadingVer(false); // Set loading to false after API call completion
    }
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpInputChange(index, character);
      }
    });
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    } else if (e.key === "Enter") {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
      if (isAllFieldsFilled) {
        handleVerifyOTPs();
      } else {
        // Handle the case when all fields are not filled
        setOTPError("Please fill all the OTP digits");
      }
    }
  };

  useEffect(() => {
    // Ensure the ref current element is available
    if (mobileInputRef.current) {
      // Find the input element within the PhoneInput component
      const inputElement = mobileInputRef.current.querySelector("input");
      if (inputElement) {
        // Define the function to handle Enter key press
        const handleEnterKeyPress = (event) => {
          if (event.key === "Enter") {
            resetTimer(); // If you want to reset the timer on sending OTP
            handleSendOTP();
          }
        };

        // Attach the event listener for key down
        inputElement.addEventListener("keydown", handleEnterKeyPress);

        // Cleanup function to remove the event listener
        return () => {
          inputElement.removeEventListener("keydown", handleEnterKeyPress);
        };
      }
    }
  }, []); // You might need to adjust the dependency array based on your needs

  function onHide() {
    setShowModal(false);
    window.location.reload();
  }
  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>

      {/* <button className='close-btn'>
              <AiOutlineClose size={28} />
            </button> */}
      <div className="content">
        <div className="w-100 text-center mobile-logo">
          <img src={logo} alt="docpy-logo" width={180} height={60} />
        </div>
        <div className="header-part">
          <h3>Enter your mobile number</h3>
          <p>We will send you a confirmation code</p>
          <div className="d-flex">
            <div className="px-2">
              <label style={{ color: "#23BEE3", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOption"
                  value="email"
                  checked={!mobile}
                  onChange={() => setMobile(false)}
                />
                &nbsp; Email ?
              </label>
            </div>
            <div className="px-2">
              <label style={{ color: "#23BEE3", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOptionMo"
                  value="email"
                  checked={mobile}
                  onChange={() => setMobile(true)}
                  style={{ color: "#23BEE3" }}
                />
                &nbsp; Mobile ?
              </label>
            </div>
          </div>

          {/* <p><a style={{color:"#23BEE3",cursor:"pointer"}} onClick={(e) =>{setMobile(false)}}>Want to login with Email ?</a></p> */}
        </div>
        <div className="middle-form  m-0 form-data full-width-data">
          <div className=" filed w-100 " ref={mobileInputRef}>
            <PhoneInput
              country={"in"}
              className={`  ${error ? "field-error" : ""}`}
              onEnterKeyPress={() => {
                if (loginData?.mobile_number) {
                  resetTimer();
                  handleSendOTP();
                }
              }} // <-- here
              onChange={(phone, country) => {
                setLoginData((p) => ({ ...p, mobile_number: phone }));
                setError(false);
              }}
            />

            {/* <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  countries={['US', 'GB', 'IN']}
                  selectedSize={16}
                  optionsSize={16}
                  className="menu-flags"
                  selectButtonClassName="menu-flags-button"
                  customLabels={{
                    "US": { primary: "+1" },
                    "GB": { primary: "+44" },
                    "IN": { primary: "+91" }
                  }}
                />
                <input type="text" className='w-100' placeholder={9912348485} onChange={(e) =>{
                  e.preventDefault()
                  setLoginData((p) =>({...p, mobile_number:e?.target?.value}))
                }} /> */}
          </div>
          {error && (
            <FieldValidationError message="Please enter a valid contact number" />
          )}

          <p className="info-text">
            By signing up you are agree to our{" "}
            <a
              href="https://recureme.com/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://recureme.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>

        <div className="action-part">
          <button
            className="btn btn-orange"
            disabled={isLoading}
            onClick={() => {
              resetTimer();
              handleSendOTP();
            }}
          >
            {isLoading ? "Sending..." : "Send Authentication Code"}{" "}
          </button>
          {/* <div className="divider">
                  <span className='line'></span>
                  <p className='text'>OR</p>
                </div> */}

          {/* <button className="btn btn-orange outline" onClick={() => {
                  navigate("/emailLogin")
                }}>
                  Login With Email
                </button> */}
          {/* <button className="btn btn-orange outline" onClick={() => {
                  navigate("/signup")
                }}>
                  Login  With Email
                </button> */}
        </div>
      </div>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        className="complete-modal"
        centered
      >
        <Modal.Header>
          <div className="top-heading w-100 d-flex justify-content-end text-end">
            <button className="btn btn-transpatent" onClick={onHide}>
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="content-block text-center">
            <img src={doneImg} height={60} width={60} alt="modal-video" />
          </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "25px",
              }}
            >
              <p>Opps!</p>
              <span className="text-secondary">
                {" "}
                If you're experiencing issues with the website, please try
                refreshing your browser.
              </span>

              <button
                className="theme-button btn btn-primary mt-3"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={onHide}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body style={{ border: "none" }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className="btn close-btn" onClick={closeModal}>
                <AiOutlineClose size={20} color="#000" />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className="heading">Authentication Code Sent to your number</h5>
              <p className="desc-text">
                6 digit Authentication Code has sent to your mobile number
              </p>
              <p className="mobileno">
                {"+" + loginData?.mobile_number}{" "}
                <Badge
                  pill
                  className="edit-badge"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(false);
                  }}
                >
                  Edit
                </Badge>
              </p>
              <div className="otp-input-group" onPaste={handlePaste}>
                {otpDigits.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) =>
                      handleOtpInputChange(index, e.target.value)
                    }
                    onKeyDown={(e) => handleBackspace(index, e)}
                    autoFocus={index === 0}
                    ref={(el) => (otpInputRefs.current[index] = el)} // Save input element references to the ref array
                  />
                ))}
              </div>
              {otpError && <FieldValidationError message={otpError} />}
              <button
                className="btn-continue"
                disabled={isLoadingVer}
                onClick={handleVerifyOTPs}
              >
                {" "}
                {isLoadingVer ? "Verifiying OTP..." : "Continue"}
              </button>
              <span className="notreceive-text">
                Haven't you received the SMS?
              </span>
              <button
                className="resend-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleSendOTP();
                  resetTimer(); // Call resetTimer to restart the 15-minute countdown
                }}
                disabled={timerSeconds !== 0} // Disable button until timer reaches 0
                style={{
                  // Prevents hover and click
                  cursor: timerSeconds !== 0 ? "not-allowed" : "pointer",
                }}
              >
                Resend Code
              </button>
              <p className="timer">
                {timerSeconds > 0
                  ? `${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
                      .toString()
                      .padStart(2, "0")}`
                  : "You can now resend the code"}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
