import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col, Button } from "react-bootstrap";
import "../../assets/css/doctorInfo.css";
import UpcomingPatientsCard from "../../components/UpcomingPatientsCard";
import Calendar from "../../components/Calendar";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FieldValidationError from "../../components/common/FieldValidation";
const BookAppointment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [SlotsDetail, setSlotsDetail] = useState();
  const [BlockedList, setBlockedList] = useState();
  const [selectedSlot, setSelectedSlot] = useState({
    appoinmentFor: "",
    slot: ""
  });
  const [loading, setLoading] = useState(false)

  const [Slotloading, setSlotLoading] = useState(true)

  useEffect(() => {

    getSlotDetails()

  }, [selectedDate])


  const handleDateChange = (newValue) => {
    setSelectedDate(moment(newValue?.$d).format("YYYY-MM-DD"));
  };


  async function getSlotDetails() {
    setSlotLoading(true)

    try {
      const data = await get(`/Slots?doctor_id=${location?.state?.doctor_id}&date=${selectedDate}`, getAuthConfig());
      if (data?.status === 200) {
        setSlotsDetail(data?.data?.msg[0]);
        setBlockedList(data?.data?.bookedList)
      }
      else {
      }

    } catch (error) {
      if (error?.status === 401) {
        Auth.logout()
        navigate("/login")
      }

      if (error?.response?.status === 400) {
        setSlotsDetail()
      }
    }
    finally{
      setTimeout(() =>{
        setSlotLoading(false)
      },1000)
    }
  }
  // Separate slots into morning, evening, and night arrays
  const [morningSlots, setMorningSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [nightSlots, setNightSlots] = useState([]);
  const [error, setError] = useState({
    selectedDate: false,
    appoinmentFor: false,
    timeSlot: false,
  })
  const appoinmentForref = useRef(null);
  const timeSlotref = useRef(null);
  const dateref = useRef(null)
  // useEffect(() => {
  //   if (SlotsDetail?.Time && selectedDate) {
  //     const allSlots = [];
  //     const now = moment();

  //     // Generate slots based on startTime, endTime, and duration
  //     SlotsDetail?.Time?.forEach((slot) => {
  //       const { startTime, endTime, duration } = slot;
  //       const startMoment = moment(startTime, 'HH:mm:ss');
  //       const endMoment = moment(endTime, 'HH:mm:ss');

  //       while (startMoment.add(duration, 'minutes').isSameOrBefore(endMoment)) {
  //         allSlots.push({
  //           start: startMoment.clone().subtract(duration, 'minutes'),
  //           end: startMoment.clone(),
  //         });
  //       }
  //     });

  //     // ...

  //     // Check if the selected date is today
  //     const isToday = moment(selectedDate).isSame(moment(), 'day');

  //     // Filter slots for the selected date and future dates, excluding past dates
  //     const filteredSlots = allSlots.filter((slot) => {
  //       if (isToday) {
  //         // If it's today, only include slots that have not started yet (start time is after current time)
  //         const isBooked = BlockedList.some((bookedSlot) => {
  //           return (
  //             moment(bookedSlot.date).isSame(selectedDate, "day") &&
  //             bookedSlot.timeSlot === slot.start.format("HH:mm:ss")
  //           );
  //         });

  //         return !isBooked && slot.start.isAfter(now);

  //         return slot.start.isAfter(now);
  //       } else {
  //         // For other dates, include all slots for that date and future dates
  //         return true;
  //       }
  //     });
  //     // Classify filtered slots into morning, evening, and night
  //     const morning = [];
  //     const evening = [];
  //     const night = [];
  //     filteredSlots.forEach((slot) => {
  //       const startHour = slot.start.hour();
  //       if (startHour >= 6 && startHour < 12) {
  //         morning.push(slot);
  //       } else if (startHour >= 12 && startHour < 18) {
  //         evening.push(slot);
  //       } else {
  //         night.push(slot);
  //       }
  //     });

  //     setMorningSlots(morning);
  //     setEveningSlots(evening);
  //     setNightSlots(night);
  //   } else {
  //     // If there are no slots for the selected date, set the slot states to empty arrays
  //     setMorningSlots([]);
  //     setEveningSlots([]);
  //     setNightSlots([]);
  //   }
  // }, [SlotsDetail, selectedDate, BlockedList]);


  useEffect(() => {
    if (SlotsDetail?.Time && selectedDate) {
      const allSlots = [];
      const now = moment();

      // Generate slots based on startTime, endTime, and duration
      SlotsDetail?.Time?.forEach((slot) => {
        const { startTime, endTime, duration } = slot;
        const startMoment = moment(startTime, 'HH:mm:ss');
        const endMoment = moment(endTime, 'HH:mm:ss');

        while (startMoment.add(duration, 'minutes').isSameOrBefore(endMoment)) {
          allSlots.push({
            start: startMoment.clone().subtract(duration, 'minutes'),
            end: startMoment.clone(),
          });
        }
      });

      // Filter slots for the selected date and future dates
      const filteredSlots = allSlots.filter((slot) => {
        const isSameOrAfterSelectedDate = moment(slot.start).isSameOrAfter(selectedDate, 'day');
  const isBooked = BlockedList.some((bookedSlot) => {
    return (
      moment(bookedSlot.date).isSame(selectedDate, 'day') &&
      bookedSlot.timeSlot === slot.start.format('HH:mm:ss')
    );
  });

        // Check if the slot is before the current time
        const isBeforeCurrentTime = slot.start.isBefore(now);

        // Include slots for the selected date and future dates
        // Disable slots that are booked or before the current time
        return isSameOrAfterSelectedDate || (!isBooked || !isBeforeCurrentTime);
      });

      // Classify filtered slots into morning, evening, and night
      const morning = [];
      const evening = [];
      const night = [];
      filteredSlots.forEach((slot) => {
        const startHour = slot.start.hour();
        if (startHour >= 6 && startHour < 12) {
          morning.push(slot);
        } else if (startHour >= 12 && startHour < 18) {
          evening.push(slot);
        } else {
          night.push(slot);
        }
      });

      setMorningSlots(morning);
      setEveningSlots(evening);
      setNightSlots(night);
    } else {
      // If there are no slots for the selected date, set the slot states to empty arrays
      setMorningSlots([]);
      setEveningSlots([]);
      setNightSlots([]);
    }
  }, [SlotsDetail, selectedDate, BlockedList]);

  useEffect(() =>{
    if(location?.state?.selectedSlot){
      setSelectedSlot((p) =>({...p,slot:location?.state?.selectedSlot}))
    }
  },[location?.state?.selectedSlot])
  async function bookAppointmentDoctor() {
    const appoinmentId = SlotsDetail?._id;
    const timeSlot = selectedSlot?.slot;
    const selectedDates = selectedDate;
    const appoinmentFor = selectedSlot?.appoinmentFor;

    // // Perform validation for mandatory fields
    // if (!appoinmentId || !timeSlot || !selectedDates || !appoinmentFor) {
    //   // Show an error message to the user indicating the missing fields
    //   toast.error("Please fill in all mandatory fields.");
    //   return;
    // }

    if (!appoinmentFor) {
      setError((p) => ({ ...p, appoinmentFor: true }))

      handleFieldError(appoinmentForref)

      return;
    }


    if (!timeSlot) {
      setError((p) => ({ ...p, timeSlot: true }))

      handleFieldError(timeSlotref)

      return;
    }
    const formData = new FormData();

    formData.append("appoinment_id", SlotsDetail?._id)
    formData.append("timeSlot", selectedSlot?.slot)
    formData.append("date", selectedDate)
    formData.append("appoinmentFor", selectedSlot?.appoinmentFor)


    try {
      setLoading(true)
      const response = await post("/BookAppoinment", formData, getAuthConfig());
      if (response?.status === 200) {
        setLoading(false)

        setSelectedSlot({
          appoinmentFor: "",
          slot: "",
        });
        getSlotDetails()
        navigate("/")
      } else {
        setLoading(false)

      }
    } catch (error) {
      setLoading(false)

      toast.error(error?.response?.data?.err)

    }

  }

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector('input')
    if (inputField) {
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector('select');
      if (selectField) {
        selectField.classList.add('field-error'); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleSlotSelect = (slot) => {
    const selectedTime = slot.start.format("HH:mm:ss");
    setSelectedSlot({
      appoinmentFor: selectedSlot.appoinmentFor,
      slot: selectedTime,
    });
    setError((p) => ({ ...p, timeSlot: false }))
  };

  const isSlotDisabled = (slot) => {
    const startMoment = moment(slot.start);
    const isBooked = BlockedList.some((bookedSlot) => {
      return (
        moment(bookedSlot.date).isSame(selectedDate, "day") &&
        bookedSlot.timeSlot === startMoment.format("HH:mm:ss")
      );
    });
    const isBeforeCurrentTime = startMoment.isBefore(moment()) &&  moment().isSame(selectedDate, "day") 
    return isBooked || isBeforeCurrentTime;
  };
  return (
    <>
      <Layout>
        <ToastContainer />
        <TopBar backlink={"/doctorDetails"} title={"Book Appointment"} tab={"Appointment"} tabChild={"Book Appointment"} />

       
        <div className="main-content-part topBox">
          <div className="innerInfo withoutOrange">
            <Row>
              <Col lg={5}>
              <div className="doctorDetailsCard" style={{paddingLeft:"10px",paddingRight:"10px",flexDirection:"row"}}>
                  <div className="doctorContent" style={{flexDirection:"row"}}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        autoFocus={true}
                        onChange={(newValue) => {
                          setSelectedDate(moment(newValue?.$d).format("YYYY-MM-DD"));
                        }}
                        disablePast
                      />
                    </LocalizationProvider>


                  </div>

                  {/* <div className="bottom-left-content" style={{ width: "100%" }}>
                    <div className="patientListpartTitle">
                      <h6>Book Appointment</h6>
                    </div>
                    <div className="mb-4 w-100">
                      <UpcomingPatientsCard />
                    </div>
                  </div> */}
                </div>
              </Col>
              {Slotloading ?  <div className="preloader-whirlpool">
          <div className="whirlpool"></div>
        </div> : 
              <Col lg={7}>
                <div className="doctorDetailsCard" style={{ height: '100%' }}>

                  <div className="filter-box" ref={appoinmentForref}>
                    <label htmlFor="appointment-input">Appointment For</label>
                    <input type="text" className={`input-field ${error?.appoinmentFor ? 'field-error' : ''}`} placeholder={"Ex. Back pain"} value={selectedSlot?.appoinmentFor} onChange={(e) => {
                      e.preventDefault();
                      setSelectedSlot((p) => ({ ...p, appoinmentFor: e.target.value }));
                      setError((p) => ({ ...p, appoinmentFor: false }))

                    }} />

                    {error?.appoinmentFor ? <FieldValidationError message="Please enter the appointment booking reason" /> : ""}

                  </div>


            
                  {morningSlots.length === 0 && eveningSlots.length === 0 && nightSlots.length === 0 && <div className="text-center">Physiotherapist   is not available</div>}
                  {morningSlots.length > 0 && (
                    <div className="filter-box">
                      <label htmlFor="appointment-input">Morning</label>
                      <div className="radio-badge-part mt-0">
                        <form>


                          {morningSlots?.map((slot, index) => (
                            <label key={index}>
                              <input type="radio" name="radio"
                              checked={selectedSlot.slot === slot.start.format("HH:mm:ss")}
                              disabled={isSlotDisabled(slot)} // Disable slots using the function
                                onClick={() => handleSlotSelect(slot)}
                              />
                              <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                            </label>
                          ))}

                        </form>
                      </div>
                    </div>

                  )}
                  {eveningSlots.length > 0 && (

                    <div className="filter-box">
                      <label htmlFor="appointment-input">Evening</label>
                      <div className="radio-badge-part mt-0">
                        <form>
                          {eveningSlots?.map((slot, index) => (
                            <label key={index}>
                              <input type="radio" name="radio" 
                                                            checked={selectedSlot.slot === slot.start.format("HH:mm:ss")}
                              disabled={isSlotDisabled(slot)}
                                onClick={() => handleSlotSelect(slot)} />
                              <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                            </label>
                          ))}
                        </form>
                      </div>
                    </div>)}

                  {nightSlots.length > 0 && (

                    <div className="filter-box">
                      <label htmlFor="appointment-input">Night</label>
                      <div className="radio-badge-part mt-0">
                        <form>
                          {nightSlots?.map((slot, index) => (
                            <label key={index}>
                              <input type="radio" name="radio" disabled={isSlotDisabled(slot)}
                                                            checked={selectedSlot.slot === slot.start.format("HH:mm:ss")}

                                onClick={() => handleSlotSelect(slot)} />
                              <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                            </label>
                          ))}
                        </form>
                      </div>
                    </div>
                  )}

                  {error?.timeSlot ? <FieldValidationError message="Please select time slot" /> : ""}

                </div>
              </Col>}
            </Row>
            {!Slotloading  &&
            <div className="d-flex justify-content-center mt-3">
              <Button className="theme-button"
                disabled={loading || (morningSlots?.length <= 0 && eveningSlots?.length <= 0 && nightSlots?.length <= 0)}
                onClick={(e) => {
                  e.preventDefault();
                  bookAppointmentDoctor()
                }}>Book Appointment</Button>
            </div>
}
          </div>
        </div>

      </Layout>
    </>
  );
};

export default BookAppointment;
