import firebase from 'firebase/app';
import 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6UJ61fcSS1xQ8AhM4iVposfO6ZF0Jlro",
  authDomain: "virtuelife-d426f.firebaseapp.com",
  projectId: "virtuelife-d426f",
  storageBucket: "virtuelife-d426f.appspot.com",
  messagingSenderId: "261316492154",
  appId: "1:261316492154:web:f69d65554bde87740d34c3",
  measurementId: "G-EMZ7PBYL3Y"
};
firebase.initializeApp(firebaseConfig);

export default firebase;