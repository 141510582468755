import React, { useEffect, useState } from 'react';
import '../../assets/css/login.css';
import doctorsImg from '../../assets/images/login-page-banner.svg';
import { AiOutlineClose } from 'react-icons/ai';
import { Modal, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TruecalloerLogo from '../../assets/images/Truecaller_Logo.png'
import ReactFlagsSelect from 'react-flags-select';
import Auth from '../../libs/auth';
import FieldValidationError from '../../components/common/FieldValidation';
import { post } from '../../libs/http-hydrate';
import firebase from "../../components/common/firebase";

const LoginEmail = () => {
  const navigate = useNavigate();
  const [showModal, setModalShow] = useState(false);
  const [selected, setSelected] = useState("IN");
  const [verificationId, setVerificationId] = useState('');

  const closeModal = () => setModalShow(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState({
    email: false,
    password: false
  });

  const [mobile,setMobile] = useState()


  const [err, setErr] = useState(false)
  const submitOTP = () => {
    navigate('/profile');
  }

  useEffect(() =>{
    if(Auth?.getCurrentUser()){
      navigate("/dashboard")
    }
  },[])



  async function Login() {
    const formdata = new FormData();
    formdata.append("email", loginData?.email);
    formdata.append("password", loginData?.password);
    formdata.append("type", "patient");

    try {
      const response = await Auth.login(formdata)
      if (response?.status === 200) {
        navigate("/dashboard")
      }
      else {
        setErr(true)
      }
    } catch (error) {
    }
  };



  const handleSendOTP = () => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    firebase
      .auth()
      .signInWithPhoneNumber("+"+loginData?.mobile, recaptchaVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
      })
      .catch((error) => {
      });
  };

  const handleVerifyOTP = (otp) => {
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId,otp);

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((result) => {
        if (result) {
            
            verifyOTP(verificationId,result?.user?.uid)

        } else {

        }
      })
      .catch((error) => {
      });
  };


  async function verifyOTP(verificationId,userID) {
    const formdata = new FormData();
    if(loginData?.mobile){
        formdata.append("type","patient");
        formdata.append("token",verificationId);
        formdata.append("mobileNumber","+"+loginData?.mobile);
        formdata.append("userID",userID);
        formdata.append("email","pvmaradiya482000@gmail.com")
    }
  
    try {
        const response = await post("/verifiUser",formdata);
        if (response?.status === 200) {
            localStorage.setItem(
                "user",
                JSON.stringify({
                  email:response?.data?.patient?.email,
                  mobile_number:response?.data?.patient?.phone,
                  token:response?.data?.patient?.token
                })
              );
                
              navigate("/healthprofile")

        } else {

        }
    } catch (error) {

        
    }
};

 

  return (
    <div className='login-main'>
      <div className="content-wrapper">
        <div className="banner-part">
          <div className="banner-content">
            <img src={doctorsImg} alt="doctors-logo" />
            <div className="text-part">
              <h4>Over 500+ Doctors & Physiotherapists</h4>
                <p>Experience the future of physiotherapy with RecureMe. Access personalized exercise programs, track your progress, and engage with your physiotherapist remotely. It's convenient, effective, and tailored to your needs.</p>
            </div>
          </div>
        </div>
        <div className="form-part">
          <button className='close-btn'>
            <AiOutlineClose size={28} />
          </button>
          <div className="content">
            <div className="header-part">
              <h3>Enter your Email</h3>
              <p>We will send you a confirmation code</p>
            </div>
           

            <div className="form-group mt-4 middle-form">

              <input type="text" className="form-control w-75" value={loginData?.email} placeholder="Enter Your Email" onChange={(e) => {
                e.preventDefault();
                setLoginData((p) => ({ ...p, email: e.target.value }));
                setErr(false);
              }} />
              {loginError?.email === true ? <FieldValidationError name="email" /> : ""}
              <input type="password" className="form-control w-75 mt-4" value={loginData?.password} placeholder="Enter Your password" onChange={(e) => {
                e.preventDefault();
                setLoginData((p) => ({ ...p, password: e.target.value }));
                setErr(false)
              }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    Login()
                  }
                }}

              />

              {err === true ? <FieldValidationError message="Invalid Email or Password" /> : ""}

              <p className='info-text'>By signing up you are agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></p>

            </div>
            <div className="action-part">
              <button className="btn btn-orange" onClick={() => {Login()}}>
                Login
              </button>
              
            
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body style={{ border: 'none' }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className='btn close-btn' onClick={closeModal}>
                <AiOutlineClose size={20} color='#000' />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className='heading'>Authentication Code Sent to your number</h5>
              <p className='desc-text'>4 digit OTP has sent you your mobile number</p>
              <p className='mobileno'>9912348485 <Badge pill className='edit-badge'>Edit</Badge></p>
              <div className="otp-input-group">
                <input
                  type="text"
                  maxLength={1}
                  defaultValue={5}
                  autoFocus
                />
                <input
                  type="text"
                  maxLength={1}
                  defaultValue={5}
                  autoFocus
                />
                <input
                  type="text"
                  maxLength={1}
                  defaultValue={5}
                  autoFocus
                />
                <input
                  type="text"
                  maxLength={1}
                  defaultValue={5}
                  autoFocus
                />
              </div>
              <button className='btn-continue' onClick={submitOTP}>Continue</button>
              <span className='notreceive-text'>Haven't you received the SMS?</span>
              <button className='resend-btn'>Resend Code</button>
              <p className="timer">00:30</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LoginEmail