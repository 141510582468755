import { React, useEffect, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import "../../assets/css/doctorInfo.css";
import Ex1 from "../../assets/images/ex-1.png";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../assets/images/info.png";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { getAuthConfig, post } from "../../libs/http-hydrate";
import doneImg from "../../assets/images/done-icon.svg";

const ExcersiceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [workoutData, setWorkdata] = useState({});
  const [workoutModel, setWorkoutModel] = useState(false);
  const [currentExercise, setCurrentExercise] = useState();
  const [reportmodal, setReportmodal] = useState(false);
  const startWorkoutSession = () => {
    navigate("/splash", {
      state: {
        workout: location?.state?.workout,
        session: location?.state?.session,
        note: location?.state?.note,
      },
    });
  };
  useEffect(() => {
    if (location?.state?.workout) {
      setWorkdata(location?.state?.workout);
    }
  }, []);

  const description = currentExercise?.description ? currentExercise?.description :  currentExercise?.exercise_id?.description;
  // Check if the description is valid and not empty
  const sentences = description ? description.split('.').filter(sentence => sentence.trim() !== '') : [];


  async function ReportExercise() {
    const formdata = new FormData();
    formdata.append("timeOfDay",location?.state?.session.toLowerCase())
    formdata.append("exerciseId",currentExercise?.exercise_id?._id)
    try {
      const data = await post("/reportExercise", formdata, getAuthConfig());
      if (data?.status === 200) {
        // navigate("/exercises")
        setWorkoutModel(false)
        setReportmodal(true)
      }
    }
    catch (err) {
    }
  }
   // Format dates to compare
 const formatDate = (date) => {
  const d = new Date(date);
  return d.toISOString().split('T')[0];
};

const today = new Date();
const isToday = formatDate(today) === formatDate(location?.state?.todayDate);

  return (
    <Layout>
      <TopBar
        backlink={true}
        title={location?.state?.session + " Workout Session"}
        tab={"Exercises"}
        tabChild={location?.state?.session + " Workout Session"}
      />
      <div className="main-content-part excersiceList">
        <div className="exercise-content">
          {workoutData?.workout &&
            workoutData?.workout.length > 0 &&
            workoutData?.workout.map((item, index) => {
              return (
                <>
                  <div className="exercise-card mb-3">
                    <div className="img-text-part">
                      <div className="img-block">
                        <img
                          src={item?.exercise_id?.exerciseImage}
                          height={70}
                          width={80}
                          alt="ex-1"
                        />
                      </div>
                      <div className="text-part ps-3 mt-3">
                        <h6>{item?.exercise_id?.title}</h6>
                        <p>{Number(item?.count)} Reps x {Number(item?.set)} Set </p>
                      </div>
                    </div>
                    <div
                      className="checkbox img-text-part"
                      style={{ width: "unset" }}
                    >
                      <img
                        src={Info}
                        alt="info"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setCurrentExercise(item)
                          setWorkoutModel(true)
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })}

          {/* <div className="exercise-card mb-3">
            <div className="img-text-part">
              <div className="img-block">
                <img src={Ex1} alt="ex-1" />
              </div>
              <div className="text-part ps-3">
                <h6>squatting with rubber</h6>
                <p>15 Reps</p>
              </div>
            </div>
            <div className="checkbox me-2">
              <input type="checkbox" id="checkbox_11" />
              <label for="checkbox_11"></label>
            </div>
          </div>
          <div className="exercise-card mb-3">
            <div className="img-text-part">
              <div className="img-block">
                <img src={Ex1} alt="ex-1" />
              </div>
              <div className="text-part ps-3">
                <h6>Bhujapidasana pose</h6>
                <p>3 Times</p>
              </div>
            </div>
            <div className="icon">
              {" "}
              <div className="checkbox me-2">
                <input type="checkbox" id="checkbox_9" />
                <label for="checkbox_9"></label>
              </div>
            </div>
          </div>
          <div className="exercise-card mb-3">
            <div className="img-text-part">
              <div className="img-block">
                <img src={Ex1} alt="ex-1" />
              </div>
              <div className="text-part ps-3">
                <h6>Bhujapidasana pose</h6>
                <p>3 Times</p>
              </div>
            </div>
            <div className="icon">
              {" "}
              <div className="checkbox me-2">
                <input type="checkbox" id="checkbox_9" />
                <label for="checkbox_9"></label>
              </div>
            </div>
          </div>
          <div className="exercise-card mb-3">
            <div className="img-text-part">
              <div className="img-block">
                <img src={Ex1} alt="ex-1" />
              </div>
              <div className="text-part ps-3">
                <h6>Bhujapidasana pose</h6>
                <p>3 Times</p>
              </div>
            </div>
            <div className="icon">
              {" "}
              <div className="checkbox me-2">
                <input type="checkbox" id="checkbox_9" />
                <label for="checkbox_9"></label>
              </div>
            </div>
          </div> */}
        </div>

        {isToday && (
          <div className="button-part mt-1">
            <Button
              className="theme-button"
              onClick={startWorkoutSession}
            >
              Start Workout
            </Button>
          </div>
        )}
      </div>


      <Modal
          show={workoutModel}
          onHide={() => setWorkoutModel(false)}
          size="lg"
          className="main-container"
        >
          <Modal.Body>
            <div className="top-heading">
              <p>{currentExercise?.exercise_id?.title}</p>
              <button
                className="btn btn-transpatent"
                onClick={() => setWorkoutModel(false)}
              >
                X
              </button>
            </div>
            <div className="content-block">
              <ReactPlayer
                url={currentExercise?.exercise_id?.videoLink}
                playing={false} // Autoplay the video
                loop={true} // Loop the video
                controls={false} // Show video controls
                width="100%" // Set video width

              />

            </div>
            <div className="bottom-block">
                
               

              {sentences.map((sentence, index) => (
                <div className="review" key={index}>
                  <span className="no-block">{index + 1}</span>
                  <span className="rv-text">{sentence.trim()}</span>
                </div>
              ))}
                {location?.state?.note && 
                <div className="review">
                <span className="">Special Notes:</span>
                <span className="rv-text">{location?.state?.note}</span>
              </div>}

                {currentExercise?.notes && 
                <div className="review">
                  <span className="">Special Notes:</span>
                  <span className="rv-text">{currentExercise?.notes}</span>
                </div>}
              
              <div className="workout-desc">
                <button className="primary-btn" onClick={(e) =>{
                  e.preventDefault();
                  ReportExercise()
                }}>Report to Doctor</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={reportmodal}
          onHide={() => setReportmodal(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading">
              <button
                className="btn btn-transpatent"
                onClick={() => setReportmodal(false)}
              >
                X
              </button>
            </div>
            <div className="content-block">
              <img src={doneImg} alt="modal-video" />
            </div>
            <div className="bottom-block">
              <div className="workout-desc">
                {/* <span className="workout-reps">Well Done!</span> */}
                <p>Your Exercise is reported to the concern Doctor </p>
                {/* <span className="modal-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry.
                </span> */}
                <button className="primary-btn" onClick={() => setReportmodal(false)} >Continue</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </Layout>
  );
};

export default ExcersiceList;
