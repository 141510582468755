
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { BrowserRouter, Routes, Route , Navigate } from 'react-router-dom';
import Home from './components/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Patient from './pages/Patient'
import AppointmentList from './pages/Appointment';
import DoctorDetails from './pages/DoctorDetails'
import History from './pages/History'
import ExcersiceList from './pages/ExcersiceList'
import DoctorDetailsForm from './pages/DoctorDetailsForm'
import BookAppointment from './pages/BookAppointment'
import Settings from './pages/Settings'
import Chat from './pages/Chat'
import HealthProfile from './pages/HealthProfile';
import Community from './pages/Community';
import SplashScreen from './pages/SplashScreen';
import WorkoutScreen from './pages/WorkoutScreen';
import CommunityCardDetails from './pages/CommunityCardDetails';
import Difficult from './pages/Difficult';
import AppointmentToHospital from './pages/BookAppointment/AppointmentToHospital';
import ProfileDoctor from './pages/ProfileDoctor';
import ProfileApointment from './pages/ProfileApointment';
import Workout from './pages/Exe/Workout';
import LoginEmail from './pages/Login/LoginEmail';
import Email from './pages/Login/SignUp';
import ClinicPhysio from './pages/Appointment/ClinicPhysio';
import { useEffect } from 'react';
import Profile from './pages/HealthProfile/Profile';
import PaymentForm from './pages/PaymentForm';
import PaymentFormStripe from './pages/PaymentFormStripe';
import PaymentCompleted from './pages/PaymentCompleted';

function App() {
  // useEffect(() => {
  //   // Define the function to handle the context menu
  //   const handleRightClick = (event) => {
  //     event.preventDefault(); // Prevent the context menu from appearing
  //   };

  //   // Add event listener for right-click (contextmenu)
  //   document.addEventListener('contextmenu', handleRightClick);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //   };
  // }, []); // Empty dependency array means this effect runs once on mount

  return (
    <BrowserRouter>
      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Home />} />
        <Route path='/signup' element={<Home />} />
        <Route path='/emailLogin' element={<LoginEmail/>}/>
        <Route path='/profile' element={<HealthProfile />} />
        <Route path='/profileUpdate' element={<Profile />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/exercises' element={<Workout/>}/>
        <Route path='/excersiceList' element={<ExcersiceList />}/>
        <Route path='/patient' element={<Patient />} />
        <Route path='/splash' element={<SplashScreen />} />
        <Route path='/workout' element={<WorkoutScreen />} />
        <Route path='/difficult' element={<Difficult />}/>
        <Route path='/community' element={<Community />}/>
        <Route path='/communityDetails' element={<CommunityCardDetails />} />
        {/* <Route path='/point' element={<Points />} /> */}
        <Route path='/appointment' element={<AppointmentList />} />
        <Route path='/clinicPhysiotherapist' element={<ClinicPhysio />} />
        <Route path='/bookAppointment' element={<BookAppointment/>}/>
        <Route path='/appointmentHospital' element={<AppointmentToHospital/>}/>
        <Route path='/doctorDetails' element={<DoctorDetails/>} />
        <Route path='/MyDoctor' element={<ProfileDoctor />} />
        <Route path='/profileApointment' element={<ProfileApointment/>}/>
        {/* <Route path='/doctorAllSlot' element={<DoctorAllSlot/>} /> */}
        {/* <Route path='/patientList' element={<PatientList/>}/> */}
        {/* <Route path='/upcomingAppointment' element={<UpcomingAppointment/>}/> */}
        {/* <Route path='/patientDetails' element={<PatientDetails/>} /> */}
        <Route path='/history' element={<History/>}/>
        {/* <Route path='/allPatient' element={<AllPatient/>}/> */}
        {/* <Route path='/personalInformation' element={<PersonalInformation/>}/> */}
        {/* <Route path='/operationalInformation' element={<OperationalInformation/>}/> */}
        {/* <Route path='/contactInformation' element={<ContactInformation/>}/> */}
        <Route path='/accounts' element={<DoctorDetailsForm/>}/>
        <Route path='/settings' element={<Settings />}/>
        <Route path='/paymentForm' element={<PaymentForm />}/>
        <Route path='/paymentFormStripe' element={<PaymentFormStripe />}/>
        <Route path='/paymentCompleted'  element={<PaymentCompleted/>} />
        {/* <Route path='/patientContactDetails' element={<PatientContactDetails />}/> */}
        <Route path='/chat' element={<Chat />}/>
        {/* <Route path='/appointSetting' element={<AppointSetting />}/> */}
        {/* <Route path='/patientContactInformation' element={<PatientContactInformation />}/> */}
        {/* <Route path='/foot' element={<Foot />} /> */}
        {/* <Route path='/applicationSettingCalendar' element={<ApplicationSettingCalendar />} /> */}
        <Route path="*" element={<Navigate to="/dashboard" />}  />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
