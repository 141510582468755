import { useState, useEffect, React } from "react";
// import '../../assets/css/home.css'
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col, Nav } from "react-bootstrap";
import "../../assets/css/doctorInfo.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Ex1 from "../../assets/images/ex-1.png";
import defaultSVG from "../../assets/images/defaultWorkout.jpg";
import Modal from "react-bootstrap/Modal";
import Calendar from "../../components/Calendar";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

const History = () => {
  const user = Auth.getCurrentUser();
  const sessionClassMap = {
    Heart: "heart",
    Arm: "arm",
    Shoulder: "shoulder",
    // Add more session names and their class names as needed
  };
  const uniqueExercises = new Set();
  const [transactionData, setTransactionData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cart, setCart] = useState([]);
  const [key, setKey] = useState("morning");
  const location = useLocation();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState(null);
  const [workoutHistory, setWorkoutHistory] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [displayExercises, setDisplayExercises] = useState(false); // State to track whether to display exercises
  const [selectedWorkout, setSelectedWorkout] = useState(null); // State to track the selected workout

  const defaultActiveTab = () => {
    if (workout?.morning?.length > 0) {
      return "morning";
    } else if (workout?.afternoon?.length > 0) {
      return "afternoon";
    } else if (workout?.evening?.length > 0) {
      return "evening";
    } else if (workout?.night?.length > 0) {
      return "night";
    } else {
      // If none of the sessions have data, set a default tab
      return "morning"; // or "afternoon", "evening", "night" as desired
    }
  };

  const [activeTab, setActiveTab] = useState(defaultActiveTab());
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setDisplayExercises(false);
    setSelectedWorkout(null);
  };

  const handleWorkoutClick = (workout) => {
    setSelectedWorkout(workout);
    setDisplayExercises(true);
  };

  const handleBackClick = () => {
    setSelectedWorkout(null);
    setDisplayExercises(false);
  };

  useEffect(() => {
    getWorkoutDetails();
  }, [location?.state?.patient_id]);
  async function getWorkoutDetails() {
    try {
      setLoading(true);
      const data = await get(`/PatientWorkout`, getAuthConfig());
      if (data?.status === 200) {
        setWorkout(data?.data?.msg);
        setTransactionData(data?.data)
        setWorkoutHistory(data?.data?.workoutHistories);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }
  useEffect(() => {
    setActiveTab(defaultActiveTab());
  }, [workout]);
  const doesWorkoutMeetDateConditions = (session, selectedDate) => {
    const sessionCreatedAt = moment(session?.startDate);
    const sessionEndDate = moment(session?.startDate).add(session.daysToComplete - 1, 'days');
  
    return moment(selectedDate?.$d).isSameOrBefore(sessionEndDate, "day") && 
           (moment(selectedDate?.$d).isSame(sessionCreatedAt, "day") || moment(selectedDate?.$d).isAfter(sessionCreatedAt));
  };

  const isWorkoutPaid = (session) => {
    console.log(session?.created_at, " ,,", transactionData?.createdAt,session?.name);
  
    // If transactionType is not DirectPayment, display all workouts
    if (workout?.transactionType !== "DirectPayment") {
      return true;
    }
  
    // If transactionType is DirectPayment and paymentCompleted is "Completed", display all workouts
    if (transactionData?.paymentCompleted === "Completed") {
      return true;
    }
  
    // If transactionType is DirectPayment, payment is not completed, and transaction date and session's created date are the same
    if (moment(transactionData?.createdAt).isSame(moment(session?.created_at), "day") && transactionData?.paymentCompleted !== "Completed") {
      return false;
    }
  
    // Otherwise, display the workout
    return true;
  };
  
  const filteredWorkoutsMorning = workout?.morning.filter(session => doesWorkoutMeetDateConditions(session, date) && isWorkoutPaid(session));
  const filteredWorkoutsAfterNoon = workout?.afternoon.filter(session => doesWorkoutMeetDateConditions(session, date) && isWorkoutPaid(session));
  const filteredWorkoutsEvening = workout?.evening.filter(session => doesWorkoutMeetDateConditions(session, date) && isWorkoutPaid(session));
  const filteredWorkoutsNight = workout?.night.filter(session => doesWorkoutMeetDateConditions(session, date) && isWorkoutPaid(session));

  return (
    <Layout>
      <TopBar title={"History"} tab={"Profile"} tabChild={"History"} />
      <div className="main-content-part topBox">
        {loading ? (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
          </div>
        ) : (
          <div className="innerInfo withoutOrange">
            <Row>
              <Col lg={5}>
                <div className="doctorDetailsCard">
                  <div className="doctorContent">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        autoFocus={true}
                        onChange={(newValue) => {
                          setDate(newValue);

                          // setDate(moment(newValue?.$d).format("YYYY-MM-DD"));
                          setDisplayExercises(false);
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <div className="doctorDetailsCard startdata mb-3">
                  <div className="rightPartData">
                    <div className="filed pb-3">
                      <div className="exercise-tab-part">
                        <h6 className="title">
                        {moment(date?.$d).format("Do MMM")} Exercises
                        </h6>

                        <div>
                          {!filteredWorkoutsMorning &&
                            !filteredWorkoutsAfterNoon &&
                            !filteredWorkoutsEvening &&
                            !filteredWorkoutsNight && <div> No Workout Found</div>}

                          {filteredWorkoutsMorning?.length <= 0 &&
                            filteredWorkoutsAfterNoon?.length <= 0 &&
                            filteredWorkoutsEvening?.length <= 0 &&
                            filteredWorkoutsNight?.length <= 0 && (
                              <div> No Workout Found</div>
                            )}
                        </div>
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => {setKey(k)
                            setDisplayExercises(false);
                            setSelectedWorkout(null);
                          }}
                          className="mb-3"
                        >
                          {filteredWorkoutsMorning && filteredWorkoutsMorning.length > 0 && (
                            <Tab eventKey="morning" title="Morning">
                              <div className="exercise-content">
                                {workout?.morning &&
                                  workout?.morning.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                {!displayExercises ? (
                                  <div className="session-card-content">
                                    
                                    { filteredWorkoutsMorning.length > 0 ? (
      filteredWorkoutsMorning.map((session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                          
                                        // Compare the selected date with session's created_at
                                        const sessionCreatedAt = moment(
                                          session?.created_at
                                        ); // Assuming 'created_at' is a date string
                                        const sessionEndDate = moment(session?.startDate).add(session.daysToComplete-1, 'days');
                                        if (moment(date?.$d).isSameOrBefore(sessionEndDate,"day")) {
                                        if (
                                          moment(date).isSame(
                                            sessionCreatedAt,
                                            "day"
                                          ) ||
                                          moment(date).isAfter(sessionCreatedAt)
                                        ) {
                                          return (
                                            <>
                                              <div className="d-flex align-items-center justify-content-start">
                                                <div
                                                  className={`session-card justify-content-start py-2 ${sessionClassMap[altClass]}`}
                                                  key={index}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleWorkoutClick(session)
                                                  }
                                                >
                                                  <div
                                                    className="image-block"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {session?.image ? (
                                                      <img
                                                        src={session?.image?.replace(
                                                          ".svg",
                                                          "Blue.svg"
                                                        )}
                                                        height={70}
                                                        width={70}
                                                        style={{
                                                          borderRadius: "55%",
                                                        }}
                                                        alt="session-img"
                                                      />
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="my-4"
                                                        width="78"
                                                        height="78"
                                                        viewBox="0 0 78 78"
                                                      >
                                                        <g
                                                          id="Group_3151"
                                                          data-name="Group 3151"
                                                          transform="translate(-363 -170)"
                                                        >
                                                          <path
                                                            id="Path_15810"
                                                            data-name="Path 15810"
                                                            d="M39,78A39,39,0,1,0,0,39,39,39,0,0,0,39,78Z"
                                                            transform="translate(363 170)"
                                                            fill="#23bee3"
                                                          />
                                                          <g
                                                            id="Group_3150"
                                                            data-name="Group 3150"
                                                            transform="translate(390.472 -102.878)"
                                                          >
                                                            <g
                                                              id="Group_3149"
                                                              data-name="Group 3149"
                                                              transform="translate(-0.472 285.878)"
                                                            >
                                                              <path
                                                                id="Path_15820"
                                                                data-name="Path 15820"
                                                                d="M-2494.453,623.243a4.713,4.713,0,0,0-4.714-4.714,4.714,4.714,0,0,0-4.714,4.714,4.714,4.714,0,0,0,4.714,4.714,4.715,4.715,0,0,0,4.715-4.714Z"
                                                                transform="translate(2511.133 -618.529)"
                                                                fill="#fff"
                                                              />
                                                              <path
                                                                id="Path_15821"
                                                                data-name="Path 15821"
                                                                d="M-2486.377,629.573c-.067.1-.132.194-.2.292a4.812,4.812,0,0,0-.771,2.2,43.1,43.1,0,0,0,.214,9.9l2.653,16.851a2.889,2.889,0,0,1-2.4,3.3,2.888,2.888,0,0,1-3.3-2.4l-2.762-17.534a.6.6,0,0,0-.592-.5.6.6,0,0,0-.593.5l-2.761,17.534a2.889,2.889,0,0,1-3.3,2.4,2.887,2.887,0,0,1-2.4-3.3l2.654-16.85a43.143,43.143,0,0,0,.216-9.9,4.8,4.8,0,0,0-.77-2.2c-.065-.1-.132-.2-.2-.292a.362.362,0,0,0-.387-.149.36.36,0,0,0-.271.313,29.016,29.016,0,0,0,.585,9.714,1.987,1.987,0,0,1-1.485,2.385,1.987,1.987,0,0,1-2.385-1.485,32.968,32.968,0,0,1-.059-14.709,6.019,6.019,0,0,1,5.874-4.7h10.573a6.02,6.02,0,0,1,5.874,4.7,32.994,32.994,0,0,1-.061,14.709,1.984,1.984,0,0,1-2.372,1.435,1.985,1.985,0,0,1-1.495-2.335,29.019,29.019,0,0,0,.584-9.715.357.357,0,0,0-.27-.313.362.362,0,0,0-.387.149Z"
                                                                transform="translate(2505.502 -610.139)"
                                                                fill="#fff"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </div>
                                                  <div className="details">
                                                    <h6 className="session-title">
                                                      {session?.name?.replace(
                                                        "Workout Session",
                                                        ""
                                                      )}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      }
                                    })
                                    ) : (
                                      <div className="text-center">No Workout Found</div>
                                    )}
                                  </div>
                                ) : (
                                  // Display exercises
                                  <div className="">
                                    <div className="text-end">
                                      <button
                                        className="btn"
                                        onClick={handleBackClick}
                                      >
                                        Back to Workouts
                                      </button>
                                    </div>
                                    {
                                      // selectedWorkout?.map((session) => (

                                      selectedWorkout?.workout?.map(
                                        (exercise, index) => {
                                          let dt = new Date(date);
                                          const isCompleted =
                                            exercise.date_Complted.includes(
                                              moment(
                                                dt?.setUTCHours(0, 0, 0, 0)
                                              ).toISOString()
                                            ); // Check if selected date is in the date_completed array

                                          return (
                                            <div
                                              className="exercise-card mb-3"
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                // navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "morning", bodyPart: selectedWorkout?.name, workout: selectedWorkout, workoutItemId: selectedWorkout?._id, workoutId: workout } })
                                              }}
                                            >
                                              <div className="img-text-part">
                                                <div className="img-block">
                                                  <img
                                                    src={
                                                      exercise?.exercise_id
                                                        ?.exerciseImage
                                                    }
                                                    height={60}
                                                    width={70}
                                                    alt="ex-1"
                                                  />
                                                </div>
                                                <div className="text-part ps-3">
                                                  <h6>
                                                    {
                                                      exercise?.exercise_id
                                                        ?.title
                                                    }
                                                  </h6>
                                                  <p>

                                                  {Number(exercise?.count)} Reps x {Number(exercise?.set)} Set{" "}
                                                    {exercise?.difficultyLevel
                                                      ? ", Difficulaty Level : " +
                                                        exercise?.difficultyLevel
                                                      : ""}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="checkbox me-2">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox_${index}`}
                                                  checked={isCompleted} // Set the checked state based on whether the selected date is in the date_completed array
                                                />
                                                <label
                                                  htmlFor={`checkbox_${index}`}
                                                ></label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )

                                      // ))
                                    }
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsAfterNoon && filteredWorkoutsAfterNoon.length > 0 && (
                            <Tab eventKey="afternoon" title="Afternoon">
                              <div className="exercise-content">
                                {workout?.afternoon &&
                                  workout?.afternoon.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                {!displayExercises ? (
                                  <div className="session-card-content">
                                     {filteredWorkoutsAfterNoon.length > 0 ? (
      filteredWorkoutsAfterNoon.map((session, index) => {
                                          const morningIndex =
                                            index %
                                            Object.keys(sessionClassMap).length;
                                          const altClass =
                                            Object.keys(sessionClassMap)[
                                              morningIndex
                                            ];
                                          const sessionCreatedAt = moment(
                                            session?.created_at
                                          ); // Assuming 'created_at' is a date string
                                          const sessionEndDate = moment(session?.startDate).add(session.daysToComplete-1, 'days');
                                          if (moment(date?.$d).isSameOrBefore(sessionEndDate,"day")) {
                                          if (
                                            moment(date).isSame(
                                              sessionCreatedAt,
                                              "day"
                                            ) ||
                                            moment(date).isAfter(
                                              sessionCreatedAt
                                            )
                                          ) {
                                            return (
                                              <>
                                                <div className="d-flex align-items-center justify-content-start">
                                                  <div
                                                    className={`session-card justify-content-start py-2 ${sessionClassMap[altClass]}`}
                                                    key={index}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleWorkoutClick(
                                                        session
                                                      )
                                                    }
                                                  >
                                                    <div
                                                      className="image-block"
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      {session?.image ? (
                                                        <img
                                                          src={session?.image?.replace(
                                                            ".svg",
                                                            "Blue.svg"
                                                          )}
                                                          height={70}
                                                          width={70}
                                                          style={{
                                                            borderRadius: "55%",
                                                          }}
                                                          alt="session-img"
                                                        />
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          className="my-4"
                                                          width="78"
                                                          height="78"
                                                          viewBox="0 0 78 78"
                                                        >
                                                          <g
                                                            id="Group_3151"
                                                            data-name="Group 3151"
                                                            transform="translate(-363 -170)"
                                                          >
                                                            <path
                                                              id="Path_15810"
                                                              data-name="Path 15810"
                                                              d="M39,78A39,39,0,1,0,0,39,39,39,0,0,0,39,78Z"
                                                              transform="translate(363 170)"
                                                              fill="#23bee3"
                                                            />
                                                            <g
                                                              id="Group_3150"
                                                              data-name="Group 3150"
                                                              transform="translate(390.472 -102.878)"
                                                            >
                                                              <g
                                                                id="Group_3149"
                                                                data-name="Group 3149"
                                                                transform="translate(-0.472 285.878)"
                                                              >
                                                                <path
                                                                  id="Path_15820"
                                                                  data-name="Path 15820"
                                                                  d="M-2494.453,623.243a4.713,4.713,0,0,0-4.714-4.714,4.714,4.714,0,0,0-4.714,4.714,4.714,4.714,0,0,0,4.714,4.714,4.715,4.715,0,0,0,4.715-4.714Z"
                                                                  transform="translate(2511.133 -618.529)"
                                                                  fill="#fff"
                                                                />
                                                                <path
                                                                  id="Path_15821"
                                                                  data-name="Path 15821"
                                                                  d="M-2486.377,629.573c-.067.1-.132.194-.2.292a4.812,4.812,0,0,0-.771,2.2,43.1,43.1,0,0,0,.214,9.9l2.653,16.851a2.889,2.889,0,0,1-2.4,3.3,2.888,2.888,0,0,1-3.3-2.4l-2.762-17.534a.6.6,0,0,0-.592-.5.6.6,0,0,0-.593.5l-2.761,17.534a2.889,2.889,0,0,1-3.3,2.4,2.887,2.887,0,0,1-2.4-3.3l2.654-16.85a43.143,43.143,0,0,0,.216-9.9,4.8,4.8,0,0,0-.77-2.2c-.065-.1-.132-.2-.2-.292a.362.362,0,0,0-.387-.149.36.36,0,0,0-.271.313,29.016,29.016,0,0,0,.585,9.714,1.987,1.987,0,0,1-1.485,2.385,1.987,1.987,0,0,1-2.385-1.485,32.968,32.968,0,0,1-.059-14.709,6.019,6.019,0,0,1,5.874-4.7h10.573a6.02,6.02,0,0,1,5.874,4.7,32.994,32.994,0,0,1-.061,14.709,1.984,1.984,0,0,1-2.372,1.435,1.985,1.985,0,0,1-1.495-2.335,29.019,29.019,0,0,0,.584-9.715.357.357,0,0,0-.27-.313.362.362,0,0,0-.387.149Z"
                                                                  transform="translate(2505.502 -610.139)"
                                                                  fill="#fff"
                                                                />
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </svg>
                                                      )}
                                                    </div>
                                                    <div className="details">
                                                      <h6 className="session-title">
                                                        {session?.name?.replace(
                                                          "Workout Session",
                                                          ""
                                                        )}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                        }
                                        )
                                      
                                        ) : (
                                          <div className="text-center">No Workout Found</div>
                                        ) }
                                  </div>
                                ) : (
                                  // Display exercises
                                  <div className="">
                                    <div className="text-end">
                                      <button
                                        className="btn"
                                        onClick={handleBackClick}
                                      >
                                        Back to Workouts
                                      </button>
                                    </div>
                                    {
                                      // workout?.afternoon.map((session) => (

                                      selectedWorkout?.workout?.map(
                                        (exercise, index) => {
                                          let dt = new Date(date);
                                          const isCompleted =
                                            exercise.date_Complted.includes(
                                              moment(
                                                dt?.setUTCHours(0, 0, 0, 0)
                                              ).toISOString()
                                            ); // Check if selected date is in the date_completed array

                                          return (
                                            <div
                                              className="exercise-card mb-3"
                                              style={{ cursor: "pointer" }}
                                              key={index}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                // navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "afternoon", bodyPart: selectedWorkout?.name, workout: selectedWorkout, workoutItemId: selectedWorkout?._id, workoutId: workout } })
                                              }}
                                            >
                                              <div className="img-text-part">
                                                <div className="img-block">
                                                  <img
                                                    src={
                                                      exercise?.exercise_id
                                                        ?.exerciseImage
                                                    }
                                                    height={60}
                                                    width={70}
                                                    alt="ex-1"
                                                  />
                                                </div>
                                                <div className="text-part ps-3">
                                                  <h6>
                                                    {
                                                      exercise?.exercise_id
                                                        ?.title
                                                    }
                                                  </h6>
                                                  <p>
                                                  {Number(exercise?.count)} Reps x {Number(exercise?.set)} Set{" "}

                                                  </p>
                                                </div>
                                              </div>
                                              <div className="checkbox me-2">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox_${index}`}
                                                  checked={isCompleted} // Set the checked state based on whether the selected date is in the date_completed array
                                                />
                                                <label
                                                  htmlFor={`checkbox_${index}`}
                                                ></label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )

                                      // ))
                                    }
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsEvening && filteredWorkoutsEvening.length > 0 && (
                            <Tab eventKey="evening" title="Evening">
                              <div className="exercise-content">
                                {workout?.evening &&
                                  workout?.evening.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                {!displayExercises ? (
                                  <div className="session-card-content">
                                     {filteredWorkoutsEvening.length > 0 ? (
      filteredWorkoutsEvening.map((session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                        const sessionCreatedAt = moment(
                                          session?.created_at
                                        ); // Assuming 'created_at' is a date string
                                        const sessionEndDate = moment(session?.startDate).add(session.daysToComplete-1, 'days');
                                        if (moment(date?.$d).isSameOrBefore(sessionEndDate,"day")) {
                                        if (
                                          moment(date).isSame(
                                            sessionCreatedAt,
                                            "day"
                                          ) ||
                                          moment(date).isAfter(sessionCreatedAt)
                                        ) {
                                          return (
                                            <>
                                              <div className="d-flex align-items-center justify-content-start">
                                                <div
                                                  className={`session-card justify-content-start py-2 ${sessionClassMap[altClass]}`}
                                                  key={index}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleWorkoutClick(session)
                                                  }
                                                >
                                                  <div
                                                    className="image-block"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {session?.image ? (
                                                      <img
                                                        src={session?.image?.replace(
                                                          ".svg",
                                                          "Blue.svg"
                                                        )}
                                                        height={70}
                                                        width={70}
                                                        style={{
                                                          borderRadius: "55%",
                                                        }}
                                                        alt="session-img"
                                                      />
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="my-4"
                                                        width="78"
                                                        height="78"
                                                        viewBox="0 0 78 78"
                                                      >
                                                        <g
                                                          id="Group_3151"
                                                          data-name="Group 3151"
                                                          transform="translate(-363 -170)"
                                                        >
                                                          <path
                                                            id="Path_15810"
                                                            data-name="Path 15810"
                                                            d="M39,78A39,39,0,1,0,0,39,39,39,0,0,0,39,78Z"
                                                            transform="translate(363 170)"
                                                            fill="#23bee3"
                                                          />
                                                          <g
                                                            id="Group_3150"
                                                            data-name="Group 3150"
                                                            transform="translate(390.472 -102.878)"
                                                          >
                                                            <g
                                                              id="Group_3149"
                                                              data-name="Group 3149"
                                                              transform="translate(-0.472 285.878)"
                                                            >
                                                              <path
                                                                id="Path_15820"
                                                                data-name="Path 15820"
                                                                d="M-2494.453,623.243a4.713,4.713,0,0,0-4.714-4.714,4.714,4.714,0,0,0-4.714,4.714,4.714,4.714,0,0,0,4.714,4.714,4.715,4.715,0,0,0,4.715-4.714Z"
                                                                transform="translate(2511.133 -618.529)"
                                                                fill="#fff"
                                                              />
                                                              <path
                                                                id="Path_15821"
                                                                data-name="Path 15821"
                                                                d="M-2486.377,629.573c-.067.1-.132.194-.2.292a4.812,4.812,0,0,0-.771,2.2,43.1,43.1,0,0,0,.214,9.9l2.653,16.851a2.889,2.889,0,0,1-2.4,3.3,2.888,2.888,0,0,1-3.3-2.4l-2.762-17.534a.6.6,0,0,0-.592-.5.6.6,0,0,0-.593.5l-2.761,17.534a2.889,2.889,0,0,1-3.3,2.4,2.887,2.887,0,0,1-2.4-3.3l2.654-16.85a43.143,43.143,0,0,0,.216-9.9,4.8,4.8,0,0,0-.77-2.2c-.065-.1-.132-.2-.2-.292a.362.362,0,0,0-.387-.149.36.36,0,0,0-.271.313,29.016,29.016,0,0,0,.585,9.714,1.987,1.987,0,0,1-1.485,2.385,1.987,1.987,0,0,1-2.385-1.485,32.968,32.968,0,0,1-.059-14.709,6.019,6.019,0,0,1,5.874-4.7h10.573a6.02,6.02,0,0,1,5.874,4.7,32.994,32.994,0,0,1-.061,14.709,1.984,1.984,0,0,1-2.372,1.435,1.985,1.985,0,0,1-1.495-2.335,29.019,29.019,0,0,0,.584-9.715.357.357,0,0,0-.27-.313.362.362,0,0,0-.387.149Z"
                                                                transform="translate(2505.502 -610.139)"
                                                                fill="#fff"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </div>
                                                  <div className="details">
                                                    <h6 className="session-title">
                                                      {session?.name?.replace(
                                                        "Workout Session",
                                                        ""
                                                      )}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }}
                                      })
                                      
                                      ) : (
                                        <div className="text-center">No Workout Found</div>
                                      )}
                                  </div>
                                ) : (
                                  // Display exercises
                                  <div className="">
                                    <div className="text-end">
                                      <button
                                        className="btn"
                                        onClick={handleBackClick}
                                      >
                                        Back to Workouts
                                      </button>
                                    </div>
                                    {
                                      // workout?.evening.map((session) => (

                                      selectedWorkout?.workout?.map(
                                        (exercise, index) => {
                                          let dt = new Date(date);
                                          const isCompleted =
                                            exercise.date_Complted.includes(
                                              moment(
                                                dt?.setUTCHours(0, 0, 0, 0)
                                              ).toISOString()
                                            ); // Check if selected date is in the date_completed array

                                          return (
                                            <div
                                              className="exercise-card mb-3"
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                // navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "evening", bodyPart: selectedWorkout?.name, workout: selectedWorkout, workoutItemId: selectedWorkout?._id, workoutId: workout } })
                                              }}
                                            >
                                              <div className="img-text-part">
                                                <div className="img-block">
                                                  <img
                                                    src={
                                                      exercise?.exercise_id
                                                        ?.exerciseImage
                                                    }
                                                    height={60}
                                                    width={70}
                                                    alt="ex-1"
                                                  />
                                                </div>
                                                <div className="text-part ps-3">
                                                  <h6>
                                                    {
                                                      exercise?.exercise_id
                                                        ?.title
                                                    }
                                                  </h6>
                                                  <p>
                                                  {Number(exercise?.count)} Reps x {Number(exercise?.set)} Set{" "}

                                                  </p>
                                                </div>
                                              </div>
                                              <div className="checkbox me-2">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox_${index}`}
                                                  checked={isCompleted} // Set the checked state based on whether the selected date is in the date_completed array
                                                />
                                                <label
                                                  htmlFor={`checkbox_${index}`}
                                                ></label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )

                                      // ))
                                    }
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsNight && filteredWorkoutsNight.length > 0 && (
                            <Tab eventKey="night" title="Night">
                              <div className="exercise-content">
                                {workout?.night &&
                                  workout?.night.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                {!displayExercises ? (
                                  <div className="session-card-content">
                                    {filteredWorkoutsNight.length > 0 ? (
      filteredWorkoutsNight.map((session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                        const sessionCreatedAt = moment(
                                          session?.created_at
                                        ); // Assuming 'created_at' is a date string
                                        const sessionEndDate = moment(session?.startDate).add(session.daysToComplete-1, 'days');
                                        if (moment(date?.$d).isSameOrBefore(sessionEndDate,"day")) {
                                        if (
                                          moment(date).isSame(
                                            sessionCreatedAt,
                                            "day"
                                          ) ||
                                          moment(date).isAfter(sessionCreatedAt)
                                        ) {
                                          return (
                                            <>
                                              <div className="d-flex align-items-center justify-content-start">
                                                <div
                                                  className={`session-card justify-content-start py-2 ${sessionClassMap[altClass]}`}
                                                  key={index}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleWorkoutClick(session)
                                                  }
                                                >
                                                  <div
                                                    className="image-block"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {session?.image ? (
                                                      <img
                                                        src={session?.image?.replace(
                                                          ".svg",
                                                          "Blue.svg"
                                                        )}
                                                        height={70}
                                                        width={70}
                                                        style={{
                                                          borderRadius: "55%",
                                                        }}
                                                        alt="session-img"
                                                      />
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="my-4"
                                                        width="78"
                                                        height="78"
                                                        viewBox="0 0 78 78"
                                                      >
                                                        <g
                                                          id="Group_3151"
                                                          data-name="Group 3151"
                                                          transform="translate(-363 -170)"
                                                        >
                                                          <path
                                                            id="Path_15810"
                                                            data-name="Path 15810"
                                                            d="M39,78A39,39,0,1,0,0,39,39,39,0,0,0,39,78Z"
                                                            transform="translate(363 170)"
                                                            fill="#23bee3"
                                                          />
                                                          <g
                                                            id="Group_3150"
                                                            data-name="Group 3150"
                                                            transform="translate(390.472 -102.878)"
                                                          >
                                                            <g
                                                              id="Group_3149"
                                                              data-name="Group 3149"
                                                              transform="translate(-0.472 285.878)"
                                                            >
                                                              <path
                                                                id="Path_15820"
                                                                data-name="Path 15820"
                                                                d="M-2494.453,623.243a4.713,4.713,0,0,0-4.714-4.714,4.714,4.714,0,0,0-4.714,4.714,4.714,4.714,0,0,0,4.714,4.714,4.715,4.715,0,0,0,4.715-4.714Z"
                                                                transform="translate(2511.133 -618.529)"
                                                                fill="#fff"
                                                              />
                                                              <path
                                                                id="Path_15821"
                                                                data-name="Path 15821"
                                                                d="M-2486.377,629.573c-.067.1-.132.194-.2.292a4.812,4.812,0,0,0-.771,2.2,43.1,43.1,0,0,0,.214,9.9l2.653,16.851a2.889,2.889,0,0,1-2.4,3.3,2.888,2.888,0,0,1-3.3-2.4l-2.762-17.534a.6.6,0,0,0-.592-.5.6.6,0,0,0-.593.5l-2.761,17.534a2.889,2.889,0,0,1-3.3,2.4,2.887,2.887,0,0,1-2.4-3.3l2.654-16.85a43.143,43.143,0,0,0,.216-9.9,4.8,4.8,0,0,0-.77-2.2c-.065-.1-.132-.2-.2-.292a.362.362,0,0,0-.387-.149.36.36,0,0,0-.271.313,29.016,29.016,0,0,0,.585,9.714,1.987,1.987,0,0,1-1.485,2.385,1.987,1.987,0,0,1-2.385-1.485,32.968,32.968,0,0,1-.059-14.709,6.019,6.019,0,0,1,5.874-4.7h10.573a6.02,6.02,0,0,1,5.874,4.7,32.994,32.994,0,0,1-.061,14.709,1.984,1.984,0,0,1-2.372,1.435,1.985,1.985,0,0,1-1.495-2.335,29.019,29.019,0,0,0,.584-9.715.357.357,0,0,0-.27-.313.362.362,0,0,0-.387.149Z"
                                                                transform="translate(2505.502 -610.139)"
                                                                fill="#fff"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </div>
                                                  <div className="details">
                                                    <h6 className="session-title">
                                                      {session?.name?.replace(
                                                        "Workout Session",
                                                        ""
                                                      )}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      }
                                    })
                                    ) : (
                                      <div className="text-center">No Workout Found</div>
                                    ) }
                                  </div>
                                ) : (
                                  // Display exercises
                                  <div className="">
                                    <div className="text-end">
                                      <button
                                        className="btn"
                                        onClick={handleBackClick}
                                      >
                                        Back to Workouts
                                      </button>
                                    </div>
                                    {
                                      // workout?.night.map((session) => (

                                      selectedWorkout.workout.map(
                                        (exercise, index) => {
                                          let dt = new Date(date);
                                          const isCompleted =
                                            exercise.date_Complted.includes(
                                              moment(
                                                dt?.setUTCHours(0, 0, 0, 0)
                                              ).toISOString()
                                            ); // Check if selected date is in the date_completed array

                                          return (
                                            <div
                                              className="exercise-card mb-3"
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                // navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "night", bodyPart: selectedWorkout?.name, workout: selectedWorkout, workoutItemId: selectedWorkout?._id, workoutId: workout } })
                                              }}
                                            >
                                              <div className="img-text-part">
                                                <div className="img-block">
                                                  <img
                                                    src={
                                                      exercise?.exercise_id
                                                        ?.exerciseImage
                                                    }
                                                    height={60}
                                                    width={70}
                                                    alt="ex-1"
                                                  />
                                                </div>
                                                <div className="text-part ps-3">
                                                  <h6>
                                                    {
                                                      exercise?.exercise_id
                                                        ?.title
                                                    }
                                                  </h6>
                                                  <p>
                                                  {Number(exercise?.count)} Reps x {Number(exercise?.set)} Set{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="checkbox me-2">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox_${index}`}
                                                  checked={isCompleted} // Set the checked state based on whether the selected date is in the date_completed array
                                                />
                                                <label
                                                  htmlFor={`checkbox_${index}`}
                                                ></label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )

                                      // ))
                                    }
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>

                {workoutHistory?.length > 0 && (
                  <div className="doctorDetailsCard startdata mb-3">
                    <div className="rightPartData">
                      <div className="infoField filed d-flex justify-content-between">
                        <div className="data">
                          <h5>Workout History</h5>
                        </div>
                        <div className="data d-flex justify-content-end">
                          {/* <Link
                            to="/history"
                            state={{ patient_id: patient }}
                            className="nextLink"
                          >
                            <img src={NextIcon} alt="next-icon" />
                          </Link> */}
                        </div>
                      </div>

                      <div className="filed">
                        <div
                          className="session-card-content"
                          style={{ gap: "20px" }}
                        >
                          {workoutHistory?.length > 0 &&
                            workoutHistory.map((session, index) => {
                              const isLastSession =
                                index === workoutHistory.length - 1;
                              const sessionCreatedAt = moment(
                                session?.created_at
                              ); // Assuming 'created_at' is a date string
                              const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                              let currentDate = new Date(date);
                              let totalExercises =
                                session?.removedExercises?.length;
                              let completedExercises = 0;

                              // Calculate completion ratio
                              const completionRatio =
                                totalExercises > 0
                                  ? completedExercises / totalExercises
                                  : 0;

                              // Determine background color based on completion ratio
                              let backgroundColor;
                              if (completionRatio === 1) {
                                backgroundColor = "#90ee906e"; // Light green for fully completed
                              } else if (completionRatio > 0) {
                                backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                              } else {
                                backgroundColor = "transparent"; // Default background for not completed
                              }
                              if (
                                moment(date?.$d).isSame(
                                  sessionCreatedAt,
                                  "day"
                                ) ||
                                moment(date?.$d).isAfter(sessionCreatedAt)
                              ) {
                                return (
                                  <>
                                    <div
                                      className={`exercise-card pb-2`}
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        maxHeight: "15vh",
                                        backgroundColor: backgroundColor,
                                        boxShadow: "none",
                                        borderBottom: isLastSession
                                          ? "none"
                                          : "1px solid #EAEAEA", // Conditionally apply borderBottom
                                        borderRadius: "1px",
                                        padding: "0px",
                                      }}
                                      onClick={() => {
                                        setCart(session?.removedExercises)
                                       // handleShow()
                                      }}
                                    >
                                      <div className="img-text-part">
                                        <div className="image-block">
                                          <img
                                            src={
                                              "https://api.recureme.com/images/ElbowBlue.svg"
                                            }
                                            height={70}
                                            width={70}
                                            style={{
                                              borderRadius: "55%",
                                            }}
                                            alt="session-img"
                                          />
                                        </div>
                                        <div className="text-part ps-3">
                                          <h6>
                                            {session?.name?.replace(
                                              "Workout Session",
                                              ""
                                            )}{" "}
                                            - {session?.session}
                                          </h6>
                                          <p>{`${totalExercises} exercises`}</p>
                                        </div>
                                      </div>
                                      <div className="details">
                                        <div
                                          className="text-part ps-3"
                                          style={{ textAlign: "end" }}
                                        >
                                          <h6
                                            className="session-title"
                                            style={{ fontWeight: "400" }}
                                          >
                                            {moment(
                                              session?.startedWorkoutDate
                                            ).format("DD MMM YYYY")}
                                            &nbsp; to &nbsp;
                                            {moment(
                                              session?.dateRemoved
                                            ).format("DD MMM YYYY")}
                                          </h6>
                                          <p>
                                            Assigned by :{" "}
                                            {user?._id ===
                                            session?.assigned_by?._id
                                              ? "You"
                                              : session?.assigned_by?.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className="justify-content-start">
          <Modal.Title className="text-center">Exercise List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {/* Create a Set to keep track of unique exercise titles */}

            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => {
                // Check if the exercise title is unique
                if (!uniqueExercises.has(item?.exercise_id?.title)) {
                  uniqueExercises.add(item?.exercise_id?.title);

                  return (
                    <>
                      <div
                        className="exercise-card mb-3"
                        style={{ boxShadow: "none" }}
                      >
                        <div className="img-text-part">
                          <div className="img-block">
                            <img
                              src={item?.exercise_id?.exerciseImage}
                              height={130}
                              width={130}
                              style={{ borderRadius: "10%" }}
                              alt="ex-1"
                            />
                          </div>
                          <div className="text-part ps-3">
                            <h6>{item?.exercise_id?.title}</h6>
                            <p style={{ cursor: "pointer" }}>
                              {item?.exercise_id?.body_part_id &&
                                item?.exercise_id?.body_part_id?.length > 0 &&
                                item?.exercise_id?.body_part_id.map(
                                  (itemEq, ind) => (
                                    <React.Fragment key={ind}>
                                      {ind > 0 && " - "}
                                      {itemEq?.title}
                                    </React.Fragment>
                                  )
                                )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="checkbox me-2"
                          style={{ cursor: "pointer" }}
                        >
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item className="d-flex justify-content-start align-items-center">
                              <div className="checkbox me-2 sidebarCheckbox">
                                <input
                                  type="checkbox"
                                  id={`checkbox_${"morning"}`}
                                  checked={true}
                                />
                                <label
                                  htmlFor={`checkbox_${"morning"}`}
                                ></label>
                              </div>
                              <Nav.Link eventKey="first"></Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                }
                return null; // Skip duplicate exercises
              })}
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default History;